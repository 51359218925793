import React, { useState } from "react";
import PropTypes from "prop-types";
import GridViewActionImage from "assets/images/general/grid-view-action.svg";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";
import OutsideClickHandler from "react-outside-click-handler";
import moment from "moment";

function ScheduleActions({ data, props }) {
  const now = moment();
  const scheduleTime = moment.unix(data.schedule_date);
  const thirtyMinutesBefore = scheduleTime.clone().subtract(30, "minutes");
  const thirtyMinutesAfter = scheduleTime.clone().add(30, "minutes");
  const showStartClass = now.isBetween(thirtyMinutesBefore, thirtyMinutesAfter);

  const userRole = useRecoilValue(getUserRole);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const editSchedule = () => {
    if (data.is_active)
      navigate("/edit_schedule", { state: { update_id: data.id } });
    else props.actionOnDeactivatedSchedule();
  };
  const viewScheduleDetail = () => {
    if (data.is_active)
      navigate("/schedule_detail", { state: { record_id: data.id } });
    else props.actionOnDeactivatedSchedule();
  };
  const deactivateSchedule = async () => {
    props.deactivateScheduleConfirmation(data.id, data.is_active);
  };
  const viewAttendance = () => {
      navigate("/view_attendance");
  };
  const trackStudents = () => {
    navigate("/track_students");
};

  return (
    <div className="d-flex gap-3 table-actions">
      <OutsideClickHandler
        onOutsideClick={() => {
          setShowMenu(false);
        }}
      >
        <div className="position-relative">
          {userRole !== "STUDENT" && (
            <img
              src={GridViewActionImage}
              alt="action"
              className="cursor-pointer"
              onClick={() => setShowMenu(!showMenu)}
            />
          )}
          {showMenu ? (
            <div className="actions-list">
              {userRole === "TEACHER" ? (
                <>
                  {showStartClass && (
                    <span onClick={() => {}}>Start Class</span>
                  )}
                  <span onClick={viewAttendance}>View Attendance</span>
                  <span onClick={trackStudents}>Track Student</span>
                  <span onClick={viewScheduleDetail}>View Details</span>
                </>
              ) : userRole === "BUSINESS_ADMIN" ? (
                <>
                  <span onClick={editSchedule}>Edit</span>
                  {data.is_complete && (
                    <>
                      <span onClick={deactivateSchedule}>
                        {data.is_active ? "Deactivate" : "Activate"}
                      </span>
                      <span onClick={viewAttendance}>View Attendance</span>
                      <span onClick={trackStudents}>Track Student</span>
                      <span onClick={viewScheduleDetail}>View Details</span>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </OutsideClickHandler>
    </div>

  );
}
ScheduleActions.propTypes = {
  data: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};
export default ScheduleActions;
