import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import moment from "moment";
function DateRangePickerComponent({
  name,
  label,
  placeholderText,
  classes,
  selectedDate,
  onChange,
  error,
  options,
  required,
  disabled,
}) {
  const { t } = useTranslation();

  return (
    <div className={`fv-row mb-5 ${classes}`}>
      {label && (
        <label className="form-label">
          <span className={`${required ? "required" : ""}`}>
            {t("fields." + label)}
          </span>
        </label>
      )}
      <div className="customDatePickerWidth">
        <Flatpickr
          disabled={disabled}
          value={[selectedDate[0] * 1000, selectedDate[1] * 1000]}
          onChange={(dates) => {
            // Update the parent component's state with the selected range
            const [start, end] = dates;
            if (start && end) {
              onChange(name, [moment(start).unix(), moment(end).unix()]);
            }
          }}
          className="form-control form-control-lg form-control-solid"
          placeholder={placeholderText || "Start Date       -       End Date"}
          options={options}
        />
      </div>
      {error && <div className="text-danger mt-2">{error}</div>}
    </div>
  );
}

DateRangePickerComponent.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholderText: PropTypes.string,
  classes: PropTypes.string,
  selectedDate: PropTypes.arrayOf(PropTypes.instanceOf(Date)), // Expecting an array of Date objects
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  options: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DateRangePickerComponent;
