import SelectComponent from "components/SelectComponent";
import TextComponent from "components/TextComponent";
import React, { useEffect, useState } from "react";
import { status, isFree, filterSelectionOptions, paymentType } from "./utils";
import DateRangePickerComponent from "components/DateRangePicker";
import moment from "moment";
import addRound from "assets/images/general/carbon_add-filled.svg";
import remove from "assets/images/general/mdi_delete-circle.svg";
import ButtonComponent from "components/ButtonComponent";
function Filter({ type, values, setValues, updateValues, props }) {
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const [advancedFilter, setAdvancedFilters] = useState({
    filterType: "",
    published_between: [moment().unix(), moment().add(1, "day").unix()],
    subscription: "",
    paymentType: "",
    category: "",
    tag: "",
  });

  const [removedFilters, setRemovedFilters] = useState([]);

  const [appliedFilters, setAppliedFilters] = useState([]);

  const updateAdvancedFilters = (name, value) => {
    setAdvancedFilters({ ...advancedFilter, [name]: value });
  };

  // useEffect(() => {
  //   console.log("removedFilters");
  //   console.log("-------------------");
  //   console.log(removedFilters);
  //   console.log("-------------------");
  // }, [removedFilters]);



  useEffect(() => {
    console.log("applied filters");
    console.log("-------------------");
    console.log(appliedFilters);
    console.log("-------------------");
  }, [appliedFilters]);

  const handleApply = () => {
    let appliedFiltersList = appliedFilters
    if (
      advancedFilter.filterType &&
      advancedFilter[advancedFilter.filterType]
    ) {
      const type = advancedFilter.filterType;
      const value = advancedFilter[type];
      appliedFiltersList = [...appliedFilters, { type, value }];
      setAppliedFilters(appliedFiltersList);
      setAdvancedFilters({
        filterType: "",
        published_between: [moment().unix(), moment().add(1, "day").unix()],
      });
    }
    const transformedFilters = appliedFiltersList.reduce((acc, filter) => {
      acc[filter.type] = filter.value;
      return acc;
    }, {});
    console.log("transformedFilters", transformedFilters);
    const newValues = { ...values };
    removedFilters.forEach((filter) => {
      if (filter in newValues) {
        delete newValues[filter];
      }
    });
    const updatedValues = { ...newValues, ...transformedFilters };
    setValues(updatedValues);
    setRemovedFilters([]);
  };

  const handleClear = () => {
    setValues([]);
    setRemovedFilters([]);
    setAppliedFilters([]);
    setAdvancedFilters({
      filterType: "",
      published_between: [moment().unix(), moment().add(1, "day").unix()],
    });
  };

  const handleAdd = () => {
    const type = advancedFilter.filterType;
    const value = advancedFilter[type];

    if (type && value !== undefined) {
      if (removedFilters.includes(type)) {
        console.log("removedFilters", removedFilters);
        setRemovedFilters((prevRemovedFilters) =>
          prevRemovedFilters.filter((filter) => filter !== type)
        );
      }
      setAppliedFilters((prevFilters) => [...prevFilters, { type, value }]);
    }
    setAdvancedFilters({
      filterType: "",
      published_between: [moment().unix(), moment().add(1, "day").unix()],
    });
  };

  return (
    <div className="card-body">
      <div className=" d-flex flex-wrap justify-content-between align-items-center theme-font-inter-medium">
        <div className="d-flex flex-wrap gap-2 align-items-start ">
          <TextComponent
            name="search"
            placeholder="search"
            label=""
            value={values.search ?? ""}
            setValue={updateValues}
            classes={"w-250px"}
          />

          {(type === "program" || type === "course") && (
            <SelectComponent
              name={"isFree"}
              label={""}
              value={values.isFree}
              onChange={(name, value) => {
                const parsedValue =
                  value === "true"
                    ? true
                    : value === "false"
                    ? false
                    : undefined;
                updateValues(name, parsedValue);
              }}
              classes={"w-150px"}
              optionsList={isFree}
              required={true}
            />
          )}

          {(type === "unit" ||
            type === "module" ||
            type === "program" ||
            type === "course" ||
            type === "enrollment" ||
            type === "event" ||
            type === "schedule") && (
            <SelectComponent
              name={"is_active"}
              label={""}
              value={values.is_active}
              onChange={(name, value) => {
                const parsedValue =
                  value === "true"
                    ? true
                    : value === "false"
                    ? false
                    : undefined;
                updateValues(name, parsedValue);
              }}
              classes={"w-150px"}
              optionsList={status}
              required={true}
            />
          )}
        </div>

        {type !== "schedule" && (
          <div className="cursor-pointer">
            <u
              className="text-primary"
              onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
            >
              {showAdvancedFilters && "Close "}Advanced Filters
            </u>
          </div>
        )}
      </div>

      <div className="mt-5">
        {showAdvancedFilters &&
          appliedFilters.map((val, key) => {
            return (
              <div className="d-flex flex-wrap align-items-start gap-2">
                <SelectComponent
                  name={val.type}
                  label={""}
                  value={val.type}
                  onChange={() => {}}
                  disabled={true}
                  classes={"w-250px"}
                  optionsList={filterSelectionOptions}
                />

                {val.type === "published_between" ? (
                  <DateRangePickerComponent
                    name="published_between"
                    onChange={() => {}}
                    selectedDate={val.value}
                    // label=""
                    classes="w-300px"
                    disabled={true}
                    options={{
                      mode: "range",
                    }}
                  />
                ) : val.type === "paymentType" ? (
                  <SelectComponent
                    name={val.type}
                    label=""
                    value={val.value}
                    onChange={() => {}}
                    classes={"w-300px"}
                    disabled={true}
                    optionsList={paymentType}
                  />
                ) : (
                  <SelectComponent
                    name={val.type}
                    label={""}
                    value={val.value}
                    onChange={() => {}}
                    classes={"w-300px"}
                    disabled={true}
                    optionsList={props[val.type]}
                    required={true}
                  />
                )}

                <img
                  className="mt-2 cursor-pointer"
                  src={remove}
                  onClick={() => {
                    const newFilters = appliedFilters.filter(
                      (obj) => obj !== val
                    );
                    setAppliedFilters(newFilters);
                    setRemovedFilters((prev) => [...prev, val.type]);
                  }}
                  alt="remove-icon"
                />
              </div>
            );
          })}
      </div>

      {showAdvancedFilters && (
        <>
          <div className="d-flex flex-wrap gap-2 mt-5">
            <SelectComponent
              name={"filterType"}
              label={""}
              value={advancedFilter.filterType}
              onChange={updateAdvancedFilters}
              classes={"w-250px"}
              optionsList={filterSelectionOptions.filter(
                (option) =>
                  !appliedFilters.some(
                    (filter) => filter.type === option.value
                  ) &&
                  ((option.type && option.type.includes(type)) ||
                    option.type.includes("all"))
              )}
              required={true}
            />

            {advancedFilter.filterType === "published_between" ? (
              <DateRangePickerComponent
                name="published_between"
                onChange={(name, value) => {
                  console.log("value", value);
                  updateAdvancedFilters(name, value);
                }}
                selectedDate={advancedFilter.published_between}
                required={true}
                classes="w-400px"
                options={{
                  mode: "range",
                }}
              />
            ) : advancedFilter.filterType == "paymentType" ? (
              <SelectComponent
                name={advancedFilter.filterType}
                label={""}
                value={advancedFilter[advancedFilter.filterType]}
                onChange={updateAdvancedFilters}
                classes={"w-250px"}
                optionsList={paymentType}
                required={true}
              />
            ) : (
              advancedFilter.filterType !== "" && (
                <SelectComponent
                  name={advancedFilter.filterType}
                  label={""}
                  value={advancedFilter[advancedFilter.filterType]}
                  onChange={updateAdvancedFilters}
                  classes={"w-300px"}
                  optionsList={props[advancedFilter?.filterType]}
                  required={true}
                />
              )
            )}

            {advancedFilter.filterType !== "" && (
              <img
                style={{
                  opacity:
                    advancedFilter.filterType &&
                    advancedFilter[advancedFilter.filterType]
                      ? 1
                      : 0.5,
                }}
                className={"w-40px h-40px cursor-pointer"}
                onClick={() => {
                  if (
                    advancedFilter.filterType &&
                    advancedFilter[advancedFilter.filterType]
                  ) {
                    handleAdd();
                  }
                }}
                src={addRound}
              />
            )}
          </div>

          <div className="d-flex gap-2 mt-2">
            <ButtonComponent
              text="apply"
              onClick={handleApply}
              disabled={
                advancedFilter.filterType &&
                !advancedFilter[advancedFilter.filterType]
              }
              variant="blue-1"
              className=""
            />
            <ButtonComponent
              text="clear"
              onClick={handleClear}
              disabled={false}
              variant="grey-1"
              className=""
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Filter;
