import React, { useState } from "react";
import PropTypes from "prop-types";
import EditIcon from "assets/images/form/edit-icon.svg";
import DeactivateIcon from "assets/images/form/deactivate-icon.svg";
import ActivateIcon from "assets/images/form/activate-icon.png";
import DetailIcon from "assets/images/form/detail-view.svg";
import { useNavigate } from "react-router-dom";
import GridViewActionImage from "assets/images/general/grid-view-action.svg";
import { getUserRole } from "recoil/Auth";
import OutsideClickHandler from "react-outside-click-handler";
import { useRecoilValue } from "recoil";

function ModuleActions({ data, props, viewType }) {
  const navigate = useNavigate();
  const userRole = useRecoilValue(getUserRole);
  const [showMenu, setShowMenu] = useState(false);

  const editModule = () => {
    if (data.is_active)
      navigate("/edit_module", { state: { update_id: data.id } });
    else props.actionOnDeactivatedModule();
  };
  const viewModuleDetail = () => {
    if (data.is_active)
      navigate("/module_detail", { state: { record_id: data.id } });
    else props.actionOnDeactivatedModule();
  };
  const viewModuleUnitsList = () => {
    if (data.is_active)
      navigate("/module_units", { state: { record_id: data.id } });
    else props.actionOnDeactivatedCourse();
  };
  const deleteModule = async () => {
    props.deactivateModuleConfirmation(data.id, data.is_active);
  };

  return (
    <div className="d-flex gap-3 table-actions">
      {viewType === "grid" ? (
        <>
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowMenu(false);
            }}
          >
            <div className="position-relative">
              <img
                src={GridViewActionImage}
                alt="action"
                className="cursor-pointer"
                onClick={() => setShowMenu(!showMenu)}
              />
              {showMenu ? (
                <div className="actions-list">
                  {userRole === "STUDENT" || userRole === "TEACHER" ? (
                    <span onClick={viewModuleDetail}>View Details</span>
                  ) : userRole === "BUSINESS_ADMIN" ? (
                    <>
                      <span onClick={viewModuleDetail}>View Details</span>
                      <span onClick={editModule}>Edit</span>
                      <span onClick={deleteModule}>
                        {data.is_active ? "Deactivate" : "Activate"}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </OutsideClickHandler>
        </>
      ) : viewType === "list" ? (
        userRole === "SUPER_ADMIN" ? (
          <span onClick={viewModuleUnitsList}>View Units</span>
        ) : userRole === "STUDENT" || userRole === "TEACHER" ? (
          <img onClick={viewModuleDetail} src={DetailIcon} alt="edit-icon" />
        ) : userRole === "BUSINESS_ADMIN" ? (
          <>
            <img onClick={viewModuleDetail} src={DetailIcon} alt="edit-icon" />
            <img onClick={editModule} src={EditIcon} alt="edit-icon" />
            <img
              onClick={deleteModule}
              src={data.is_active ? DeactivateIcon : ActivateIcon}
              alt="edit-icon"
            />
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  );
}
ModuleActions.propTypes = {
  data: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};
export default ModuleActions;
