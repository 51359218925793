import { CallAPI } from "actions/General";
import ErrorComponent from "components/ErrorComponent";
import ListingHeaderComponent from "components/ListingHeaderComponent";
import LoaderComponent from "components/LoaderComponent";
import PaginationComponent from "components/PaginationComponent";
import TableComponent from "components/TableComponent";
import { DefaultPaginationSize } from "config/config";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModalButtons, ScheduleTableHeader, ScheduleGridHeader } from "./utils";
import GridView from "components/GridView";
import CustomModal from "components/CustomModal";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";
import CalendarView from "./CalendarView";
import Filter from "views/filters/Filter";

function Schedule() {
  const navigate = useNavigate();
  const userRole = useRecoilValue(getUserRole);
  const [totalRecords, setTotalRecords] = useState(0);
  const [schedule, setSchedules] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(DefaultPaginationSize);
  const [displayType, setDisplayType] = useState("list");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVariant, setModalVariant] = useState("");
  const [modalType, setModalType] = useState("");
  const [deactivatedId, setDeactivatedId] = useState("");
  const [deactivatedType, setDeactivatedType] = useState("");

  const [optionValues, setOptionValues] = useState({
  });

  const [filterValues, setFilterValues] = useState({});

  const getSchedulesList = async () => {
    setLoaderType("get_listing");
    let result = await CallAPI(
      "FETCH_SCHEDULE_LIST",
      {
        relations: ["season", "teacher"],
        page,
        pageSize: limit,
        ...filterValues
      },
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setSchedules(result.data.schedule);
      setTotalRecords(result.data.total);
    } else if (result.error === 403) {
      setIsModalOpen(true);
      setModalVariant("error");
      setModalType("listing403");
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        getSchedulesList()
      },
      filterValues.search ? 500 : 0
    )
    return () => clearTimeout(delayDebounceFn)
  }, [page, limit, filterValues]);

  const addSchedule = () => {
    navigate("/add_schedule");
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const updateValues = (name, value) => {
    setFilterValues({ ...filterValues, [name]: value });
  };

  const deactivateScheduleConfirmation = async (id, is_active) => {
    setDeactivatedId(id);
    setDeactivatedType(!is_active);
    setIsModalOpen(true);
    setModalVariant("confirm");
    setModalType(is_active ? "confirmDelete" : "confirmActive");
  };

  const deactivateSchedule = async () => {
    setLoaderType("delete");
    let result = await CallAPI(
      "DE_ACTIVATE_SCHEDULE",
      { is_active: deactivatedType },
      deactivatedId,
      setLoader,
      setApiError,
      null
    );
    setLoaderType("");
    if (result.status) {
      getSchedulesList();
      setModalVariant("success");
      setModalType(!deactivatedType ? "recordDelete" : "recordActivated");
    } else {
      setModalVariant("error");
      setModalType("deletionError");
    }
  };
  const actionOnDeactivatedSchedule = () => {
    setIsModalOpen(true);
    setModalVariant("error");
    setModalType("deactivateActionError");
  };
  return (
    <>
      {displayType === "calendar" ? (
        <CalendarView setDisplayType={setDisplayType} />
      ) : (
        <>
          {loader && loaderType === "get_listing" && (
            <LoaderComponent classes={"mx-4"} />
          )}
          <div className="card mb-4">
            {apiError && <ErrorComponent text={apiError} variant="danger" />}
            <ListingHeaderComponent
              type={"schedule_listing"}
              setDisplayType={setDisplayType}
              displayType={displayType}
              addItem={userRole === "BUSINESS_ADMIN" ? addSchedule : ""}
            />
            <Filter
              type={"schedule"}
              values={filterValues}
              setValues={setFilterValues}
              updateValues={updateValues}
              props={optionValues}
            />
            <div className={"card-body py-4"}>
              {displayType === "list" && (
                <TableComponent
                  data={schedule}
                  header={ScheduleTableHeader}
                  type="schedule"
                  props={{
                    deactivateScheduleConfirmation,
                    actionOnDeactivatedSchedule,
                  }}
                />
              )}
            </div>
          </div>
          {displayType === "grid" && (
            <GridView
              data={schedule}
              columns={ScheduleGridHeader}
              type="schedule"
              props={{
                deactivateScheduleConfirmation,
                actionOnDeactivatedSchedule,
              }}
            />
          )}

          <PaginationComponent
            totalPages={
              totalRecords ? Math.ceil(totalRecords / limit) : totalRecords
            }
            pageLimit={limit}
            setPageLimit={setLimit}
            setPage={setPage}
            currentPage={page}
          />
          {isModalOpen && (
            <CustomModal
              header={""}
              text={ModalButtons[modalType].text}
              buttons={ModalButtons[modalType].buttons.map((button) => {
                return {
                  text: button.text,
                  variant: button.variant,
                  onClick: button.onClick,
                  loading:
                    button["loading"] !== undefined ? button.loading : loader,
                  disabled:
                    button["loading"] !== undefined ? button.disabled : loader,
                };
              })}
              onClose={closeModal}
              variant={modalVariant}
              functionsList={{ closeModal, deactivateSchedule }}
            />
          )}
        </>
      )}
    </>
  );
}

export default Schedule;
