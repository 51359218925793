export const UnitsTableHeader = [
  { key: "slug", displayValue: "code", sort: true },
  { key: "title", displayValue: "title", sort: true },
  {
    key: "competency_level",
    displayValue: "competency_level",
    sort: true,
    callbackFunction: "displayCompetencyLevel",
  },
  {
    key: "tags",
    displayValue: "tags",
    sort: true,
    callbackFunction: "displayTags",
  },
  {
    key: "status",
    displayValue: "status",
    sort: true,
    callbackFunction: "displayUnitStatus",
  },
];
export const UnitsGridHeader = {
  header: [{ callbackFunction: "displayTitleWithSlug" }],
  body: [
    {
      key: "competency_level",
      displayValue: "competency_level",
      callbackFunction: "displayCompetencyLevel",
    },
    {
      key: "tags",
      displayValue: "tags",
      callbackFunction: "displayTags",
    },
    {
      key: "status",
      displayValue: "status",
      callbackFunction: "displayUnitStatus",
    },
  ],
};
export const AddUnitTabs = [
  {
    displayValue: "basic_details",
  },
  {
    displayValue: "tags_highlights",
  },
  {
    displayValue: "notes_units",
  },
  {
    displayValue: "preview",
  },
];
export const ViewUnitDetailTabs = [
  {
    displayValue: "detail_view_description",
  },
  {
    displayValue: "detail_view_learn_section",
  },
  {
    displayValue: "detail_view_other_detail",
  },
];
export const ExportUnitHeaders = [
  { key: "unit_id", label: "Id" },
  { key: "title", label: "Title" },
  { key: "credits", label: "Reward Credits" },
  {
    key: "competency_level",
    label: "Competency Level",
  },
  { key: "description", label: "Detail" },
  { key: "tags", label: "Tags List" },
  {
    key: "learning_points",
    label: "Learning Points",
  },
  { key: "urls", label: "Urls" },
];
export const ExportUnitTableHeaders = [
  { key: "no", displayValue: "#" },
  { key: "units_id", displayValue: "id" },
  { key: "title", displayValue: "title" },
  { key: "credits", displayValue: "reward_credits" },
  {
    key: "competency_level",
    displayValue: "competency_level",
  },
  { key: "description", displayValue: "details" },
  { key: "tags", displayValue: "tags" },
  {
    key: "learning_points",
    displayValue: "learning_points",
  },
  { key: "urls", displayValue: "urls" },
];
export const ModalButtons = {
  exportSuccess: {
    text: "export_success",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  exportError: {
    text: "",
    buttons: [
      {
        text: "ok",
        variant: "red-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  confirmDelete: {
    text: "delete_record",
    buttons: [
      {
        text: "deactivate",
        variant: "red-1",
        onClick: "deactivateUnit",
      },
      {
        text: "cancel",
        variant: "white-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  confirmActive: {
    text: "active_record",
    buttons: [
      {
        text: "activate",
        variant: "blue-1",
        onClick: "deactivateUnit",
      },
      {
        text: "cancel",
        variant: "white-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordDelete: {
    text: "record_deleted",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordUpdate: {
    text: "record_update",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordCreated: {
    text: "record_created",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordActivated: {
    text: "record_activate",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  deactivateActionError: {
    text: "action_on_deactivated_error",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  listing403: {
    text: "403_error_msg",
    buttons: [
      {
        text: "ok",
        variant: "red-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
};
