import axios from "axios";

axios.interceptors.request.use(
  function (config) {
    let apiPath = process.env.REACT_APP_API_PATH;
    let url = config.url;

    let isRetry = config._retry;
    let tokenType = config.headers.token_type;
    if (!isRetry) {
      config.url = apiPath + url;
    } else {
      tokenType = config.headers["token-type"];
    }
    const tokenState = JSON.parse(
      window.localStorage.getItem("LMS-" + process.env.REACT_APP_ENV)
    );
    let authToken =
      tokenType === "default"
        ? process.env.REACT_APP_STATIC_TOKEN
        : tokenType === "cognito"
        ? tokenState.authState.cognitoToken
        : tokenType === "external"
        ? ""
        : tokenState.authState.authToken;
    let headers = {
      "content-type": "application/json",
      Authorization: authToken ? "Bearer " + authToken : "",
      "token-type": tokenType,
    };
    config.headers = headers;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // RETURN SUCCESS RESPONSE
    if (response.status === 200 || response.status === 201) {
      return { data: response.data.data, error: "" };
    } else {
      // RETURN ERROR RESPONSE
      return { data: {}, error: response.error.msg };
    }
  },
  async function (error) {
    if (error.response && error.response.status === 401) {
      const originalRequest = error.config;
      if (
        originalRequest.url.indexOf("refresh-token") < 1 &&
        originalRequest._retry !== true
      ) {
        originalRequest._retry = true;
        return new Promise((resolve) => {
          resolve(axios(originalRequest));
        });
      } else {
        const tokenState = JSON.parse(
          window.localStorage.getItem("LMS-" + process.env.REACT_APP_ENV)
        );
        tokenState.authState = {
          authToken: "",
          email: "",
          id: "",
          role: "",
          businessesList: [],
          details: {},
        };
        window.localStorage.setItem(
          "LMS-" + process.env.REACT_APP_ENV,
          JSON.stringify(tokenState)
        );

        window.location.href = "/login";
        return {
          data: {},
          error: "Something Went Wrong",
        };
      }
    } else if (error.response && error.response.status === 403) {
      return {
        data: {},
        error: 403,
      };
    } else if (error.response) {
      return {
        data: {},
        error:
          error.response.message ?? error.response.data.message ?? "no message",
      };
    } else {
      return {
        data: {},
        error: "Something Went Wrong",
      };
    }
  }
);
