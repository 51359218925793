import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TableUtils from "actions/TableUtils";
import { useTranslation } from "react-i18next";
import EnrollmentModuleUnitActions from "views/enrollments/EnrollmentModuleUnitActions";
import EnrollmentScheduleAction from "views/enrollments/EnrollmentScheduleAction";

function GridView({
  data,
  columns,
  classes = "",
  type = "",
  options = null,
  props = null,
}) {
  const { t } = useTranslation();

  const [rowItems, setRowItems] = useState(0);
  function handleResize() {
    const html = document.documentElement; // Get the root element
    const baseFontSize = parseInt(window.getComputedStyle(html).fontSize);

    const parentWidth = Math.floor(
      document.getElementById("grid-view").offsetWidth
    );
    let maxItemWidth = 0;

    var style = getComputedStyle(document.body);
    const singleItemWidth = parseInt(
      style.getPropertyValue("--single-grid-item-width")
    );
    maxItemWidth = singleItemWidth * baseFontSize;

    const gap = baseFontSize * 2;
    if (maxItemWidth > 0) {
      let coveredWidth = 0;
      let itemsInSingleRow = 0;
      do {
        coveredWidth +=
          itemsInSingleRow === 0 ? maxItemWidth : maxItemWidth + gap;
        if (coveredWidth < parentWidth) itemsInSingleRow++;
      } while (coveredWidth < parentWidth);
      if (itemsInSingleRow > data.length) itemsInSingleRow = data.length;
      setRowItems(itemsInSingleRow);
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data]);
  return (
    <div
      id="grid-view"
      className={classes}
      style={{ "--rowItems": rowItems > 0 ? rowItems : 1 }}
    >
      <div className="grid-view">
        {data.length > 0 ? (
          data.map((singleRecord, index) => {
            return (
              <div key={index} className={`custom-card ${type}`}>
                <div className="card-body">
                  <div className="grid place-items-center gap-4 grid-header pe-15">
                    <div className="grid place-items-center">
                      {columns.header.map((singleHeaderItem, index1) => {
                        return (
                          <div key={index1} className="grid place-items-center">
                            {singleHeaderItem.callbackFunction
                              ? singleRecord[singleHeaderItem.key]
                                ? TableUtils[singleHeaderItem.callbackFunction](
                                    singleRecord[singleHeaderItem.key]
                                  )
                                : TableUtils[singleHeaderItem.callbackFunction](
                                    singleRecord
                                  )
                              : singleRecord[singleHeaderItem.key]}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="grid grid-body">
                    {columns.body.map((singleBodyItem, index2) => {
                      let value = singleBodyItem.key
                        .split(".")
                        .reduce((acc, curr) => acc[curr], singleRecord);

                      return (
                        <div key={index2} className="single-option">
                          <div className="key text-gray-800 fw-bold">
                            {t("tableHeaders." + singleBodyItem.displayValue)}:
                          </div>
                          <div className="value text-gray-700 fw-bold">
                            {singleBodyItem.callbackFunction
                              ? singleRecord[singleBodyItem.key]
                                ? TableUtils[singleBodyItem.callbackFunction](
                                    value
                                  )
                                : TableUtils[singleBodyItem.callbackFunction](
                                    singleBodyItem.key,
                                    singleRecord
                                  )
                              : value}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {TableUtils.displayActions(type, singleRecord, props, "grid")}
                {options ? (
                  options.type === "enrollment" ? (
                    <EnrollmentModuleUnitActions
                      data={singleRecord}
                      otherDetails={options.dataToSend}
                    />
                  ) : options.type === "schedule" ? (
                    <EnrollmentScheduleAction
                      data={singleRecord}
                      otherDetails={options.dataToSend}
                    />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </div>
            );
          })
        ) : (
          <p className="theme-font-inter-medium theme-font-size-16 mx-auto">
            No Records Found
          </p>
        )}
      </div>
    </div>
  );
}
GridView.propTypes = {
  options: PropTypes.object,
  props: PropTypes.object,
  type: PropTypes.string,
  classes: PropTypes.string,
  data: PropTypes.array.isRequired,
  columns: PropTypes.object.isRequired,
};
export default GridView;
