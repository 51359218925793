import React, { useState } from "react";
import { TrackStudentsHeader } from "./utils";
import TableComponent from "components/TableComponent";
import ListingHeaderComponent from "components/ListingHeaderComponent";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";
import TrackStudentActions from "./TrackStudentActions";
function TrackStudents() {
  const userRole = useRecoilValue(getUserRole);
  const [trackStudents, setTracStudents] = useState([
    {
      student_name: "User 1",
      student_email: "user1@emil.com",
      enrolled_date_time: "15/08/2025",
      attendance_time: "9:00 AM",
    },
    {
      student_name: "User 2",
      student_email: "user2@emil.com",
      enrolled_date_time: "15/08/2025",
      attendance_time: "9:00 AM",
    },
    {
      student_name: "User 3",
      student_email: "user3@emil.com",
      enrolled_date_time: "15/08/2025",
      attendance_time: "9:00 AM",
    },
  ]);
  return (
    <div className="card mb-4">
      <ListingHeaderComponent
        type={"track_students"}
        setDisplayType={TrackStudentActions}
        displayType={""}
      />
      
      <div className={"card-body py-4"}>
        <TableComponent
          data={trackStudents}
          header={TrackStudentsHeader}
          type="track_students"
        />
      </div>
    </div>
  );
}

export default TrackStudents;
