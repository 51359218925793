export const AddScheduleTabs = [
  {
    displayValue: "basic_details",
  },
  {
    displayValue: "access",
  },
  {
    displayValue: "schedule",
  },
  {
    displayValue: "preview",
  },
];
export const ViewProgramDetailTabs = [
  {
    displayValue: "detail_view_description",
  },
  {
    displayValue: "detail_view_learn_section",
  },
  {
    displayValue: "detail_view_courses",
  },
];
export const ScheduleTableHeader = [
  { key: "title", displayValue: "title", sort: true },
  {
    key: "schedule_date",
    displayValue: "start_date",
    sort: true,
    callbackFunction: "populateDateTime",
  },
  {
    key: "enrollment_end_date",
    displayValue: "enrollment_end_date",
    sort: true,
    callbackFunction: "populateDate",
  },
  {
    key: "",
    displayValue: "status",
    sort: true,
    callbackFunction: "displayScheduleStatus",
  },
  {
    key: "",
    displayValue: "duration",
    sort: true,
    callbackFunction: "displayDuration",
  },
  { key: "type", displayValue: "type", sort: true },
  { key: "teacher.name", displayValue: "teacher", sort: true },
  {
    key: "season.title",
    displayValue: "season",
    sort: true,
  },
];

export const ViewAttendanceHeader = [
  {
    key: "student_name",
    displayValue: "student_name",
    sort: true,
    callbackFunction: "",
  },
  {
    key: "student_email",
    displayValue: "student_email",
    sort: true,
    callbackFunction: "",
  },
  {
    key: "is_active",
    displayValue: "status",
    sort: true,
    callbackFunction: "",
  }
];

export const TrackStudentsHeader = [
  {
    key: "student_name",
    displayValue: "student_name",
    sort: true,
    callbackFunction: "",
  },
  {
    key: "student_email",
    displayValue: "student_email",
    sort: true,
    callbackFunction: "",
  },
  {
    key: "enrolled_date_time",
    displayValue: "enrolled_date_time",
    sort: true,
    callbackFunction: "",
  },
  {
    key: "attendance_time",
    displayValue: "attendance_time",
    sort: true,
    callbackFunction: "",
  }
];

export const ScheduleGridHeader = {
  header: [{ callbackFunction: "displayTitleWithSlug" }],
  body: [
    {
      key: "season.title",
      displayValue: "season",
    },
    {
      key: "teacher.name",
      displayValue: "teacher",
    },
    {
      key: "",
      displayValue: "duration",
      callbackFunction: "displayDuration",
    },
    {
      key: "type",
      displayValue: "type",
    },
    {
      key: "schedule_date",
      displayValue: "start_date",
      callbackFunction: "populateDateTime",
    },
    {
      key: "enrollment_end_date",
      displayValue: "enrollment_end_date",
      callbackFunction: "populateDate",
    },
  ],
};
export const ModalButtons = {
  confirmDelete: {
    text: "delete_record",
    buttons: [
      {
        text: "deactivate",
        variant: "red-1",
        onClick: "deactivateSchedule",
      },
      {
        text: "cancel",
        variant: "white-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  confirmActive: {
    text: "active_record",
    buttons: [
      {
        text: "activate",
        variant: "blue-1",
        onClick: "deactivateSchedule"
      },
      {
        text: "cancel",
        variant: "white-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  confirmDraft:{
    text: "save_draft",
    buttons: [
      {
        text: "save",
        variant: "red-1",
        onClick: "saveDraft"
      },
      {
        text: "cancel",
        variant: "white-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  draftSaved:{
    text: "draft_saved",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "draftSaved",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordDelete: {
    text: "record_deleted",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordUpdate: {
    text: "record_update",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordCreated: {
    text: "record_created",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordActivated: {
    text: "record_activate",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  deactivateActionError: {
    text: "action_on_deactivated_error",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  listing403: {
    text: "403_error_msg",
    buttons: [
      {
        text: "ok",
        variant: "red-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
};
