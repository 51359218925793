import React, { useEffect, useState } from "react";
import ButtonComponent from "components/ButtonComponent";
import SelectComponent from "components/SelectComponent";
import TextComponent from "components/TextComponent";
import TextRadioComponent from "components/TextRadioComponent";
import { CallAPI } from "actions/General";
import CustomModal from "components/CustomModal";
import LoaderComponent from "components/LoaderComponent";

function AuthSettings() {
  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState("");
  const [isDetailFetched, setIsDetailFetched] = useState(false);
  const [fetchedDetails, setFetchedDetails] = useState({
    roles: [],
    profiles: [],
    departments: [],
  });
  const [apiError, setApiError] = useState("");
  const [recordId, setRecordId] = useState("");
  const [values, setValues] = useState({});
  const updateSettings = async () => {
    setLoadingType("save");
    await CallAPI(
      "SAVE_DEFAULT_SYSTEM_SETTINGS",
      {
        ...values,
        default_business: parseInt(values.default_business),
        default_role: parseInt(values.default_role),
        default_profile: parseInt(values.default_profile),
        default_department: parseInt(values.default_department),
      },
      recordId,
      null,
      null,
      null
    );
  };
  const getBusinessDetails = async (data = null) => {
    setLoadingType("fetch_details");
    const result = await CallAPI(
      "GET_OWNER_BUSINESS_DETAILS",
      {
        default_business: parseInt(
          data.default_business
            ? data.default_business
            : values.default_business
        ),
        public_key: data.public_key ? data.public_key : values.public_key,
        private_key: data.private_key ? data.private_key : values.private_key,
      },
      null,
      setLoading,
      setApiError,
      null
    );
    if (result.status) {
      const roles = result.data.roles;
      const profiles = result.data.profiles;
      const departments = result.data.departments;
      let rolesList = roles.map((singleRole) => {
        return { value: singleRole.id, displayValue: singleRole.name };
      });
      let profilesList = profiles.map((singleProfile) => {
        return { value: singleProfile.id, displayValue: singleProfile.name };
      });
      let departmentsList = departments.map((singleDepartment) => {
        return {
          value: singleDepartment.id,
          displayValue: singleDepartment.name,
        };
      });
      setFetchedDetails({
        roles: rolesList,
        profiles: profilesList,
        departments: departmentsList,
      });
      setIsDetailFetched(true);
    }
  };
  const updateFormValues = (name, value) => {
    if (
      name === "default_business" ||
      name === "public_key" ||
      name === "private_key"
    ) {
      setIsDetailFetched(false);
    }
    setValues({ ...values, [name]: value });
  };
  const getDefaultSettings = async () => {
    setLoadingType("get_details");
    const result = await CallAPI(
      "GET_DEFAULT_SYSTEM_SETTINGS",
      null,
      "business_details",
      setLoading,
      null,
      null
    );
    if (result.status) {
      setRecordId(result.data.id);
      setValues({
        sso_enabled: result.data.sso_enabled,
        default_business: result.data.default_business
          ? result.data.default_business
          : "",
        public_key: result.data.public_key ? result.data.public_key : "",
        private_key: result.data.private_key ? result.data.private_key : "",
        default_role: result.data.default_role,
        default_profile: result.data.default_profile,
        default_department: result.data.default_department,
        subscription_management_email:
          result.data.subscription_management_email,
      });
      if (result.data.private_key) getBusinessDetails(result.data);
    }
  };
  useEffect(() => {
    getDefaultSettings();
  }, []);
  const closeModel = () => {
    setApiError("");
  };
  return (
    <div className="mb-4 box-width-flex">
      {loading && loadingType === "get_details" ? (
        <LoaderComponent classes="mx-3" />
      ) : (
        <></>
      )}
      {Object.keys(values).length > 0 && (
        <div>
          <TextRadioComponent
            name={"sso_enabled"}
            label={"sso_enable"}
            placeholder={"enable_disable_sso"}
            value={values.sso_enabled ? values.sso_enabled : false}
            setValue={updateFormValues}
            classes={""}
            required={false}
          />

          <TextComponent
            name={"default_business"}
            label={"default_business"}
            placeholder={"default_business"}
            value={values.default_business}
            setValue={updateFormValues}
            classes={""}
            type="number"
            required={false}
          />

          <TextComponent
            name={"public_key"}
            label={"public_key"}
            placeholder={"public_key"}
            value={values.public_key}
            setValue={updateFormValues}
            classes={""}
            required={false}
          />

          <TextComponent
            name={"private_key"}
            label={"private_key"}
            placeholder={"private_key"}
            value={values.private_key}
            setValue={updateFormValues}
            classes={""}
            required={false}
          />
          {isDetailFetched && (
            <>
              <SelectComponent
                name={"default_profile"}
                label={"default_profile"}
                value={values.default_profile}
                onChange={updateFormValues}
                classes={{}}
                optionsList={fetchedDetails.profiles}
              />
              <SelectComponent
                name={"default_department"}
                label={"default_department"}
                value={values.default_department}
                onChange={updateFormValues}
                classes={{}}
                optionsList={fetchedDetails.departments}
              />
              <SelectComponent
                name={"default_role"}
                label={"default_role"}
                value={values.default_role}
                onChange={updateFormValues}
                classes={{}}
                optionsList={fetchedDetails.roles}
              />
              <TextComponent
                name={"subscription_management_email"}
                label={"subscription_email"}
                placeholder={"subscription_email"}
                value={values.subscription_management_email}
                setValue={updateFormValues}
                classes={""}
                required={false}
              />
            </>
          )}
        </div>
      )}
      {!isDetailFetched ? (
        <div>
          <ButtonComponent
            text="fetch_details"
            onClick={getBusinessDetails}
            variant="blue-1 ms-auto"
            loading={loading && loadingType === "fetch_details"}
            disabled={loading && loadingType === "fetch_details"}
          />
        </div>
      ) : (
        <div>
          <ButtonComponent
            text="update_settings"
            onClick={updateSettings}
            variant="blue-1 ms-auto"
            loading={loading && loadingType === "save"}
            disabled={loading && loadingType === "save"}
          />
        </div>
      )}
      {apiError && (
        <CustomModal
          header={""}
          text={apiError}
          buttons={[
            {
              text: "ok",
              variant: "blue-1",
              onClick: "closeModel",
              loading: false,
              disabled: false,
            },
          ]}
          onClose={closeModel}
          variant={"error"}
          functionsList={{ closeModel }}
        />
      )}
    </div>
  );
}

export default AuthSettings;
