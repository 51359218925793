import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ButtonComponent from "components/ButtonComponent";
import { CallAPI } from "actions/General";
import { loadStripe } from "@stripe/stripe-js";
import CustomModal from "components/CustomModal";
import DiscountModal from "./DiscountModal";

function Payment({ price, recordId, type, period, currency, otherDetails }) {
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [giftCard, setGiftCard] = useState("");
  const [discountType, setDiscountType] = useState("none");

  useEffect(() => {
    setCouponCode("");
    setGiftCard("");
  }, [discountType]);

  const getPaymentIntent = async () => {
    const dataToSend = {
      payment_method: currency === "ngn" ? "pay_stack" : "stripe",
      coupon_code: discountType === "coupon_code" ? couponCode : "",
      gift_card: discountType === "gift_code" ? giftCard : "",
      payment_duration: period,
      purchase_type: type,
      currency: currency,
      record_id: recordId,
      other_details: JSON.stringify(otherDetails),
    };
    const result = await CallAPI(
      "GET_SUBSCRIPTION_INTENT",
      dataToSend,
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      if (currency === "ngn")
        window.open(result.data.session_obj.authorization_url, "_self");
      else {
        const stripePromise = await loadStripe(result.data.stripe_public_key);
        stripePromise.redirectToCheckout({
          sessionId: result.data.session_obj,
        });
      }
    }
  };
  const openDiscountModal = () => {
    setShowDiscountModal(true);
  };
  const closeModal = () => {
    setApiError("");
  };
  return (
    <div>
      <ButtonComponent
        text="pay"
        variant="orange-1"
        langVal={`${
          currency === "ngn" ? "NGN" : currency === "cad" ? "CAD" : "USD "
        } ${price}`}
        langKey={"price"}
        loading={loader}
        disabled={loader}
        onClick={currency === "ngn" ? getPaymentIntent : openDiscountModal}
      />

      {showDiscountModal && (
        <DiscountModal
          price={price}
          couponCode={couponCode}
          giftCard={giftCard}
          setCouponCode={setCouponCode}
          setGiftCard={setGiftCard}
          discountType={discountType}
          setDiscountType={setDiscountType}
          onClose={setShowDiscountModal}
          getPaymentIntent={getPaymentIntent}
          setApiError={setApiError}
          loader={loader}
          type={type}
        />
      )}

      {apiError && (
        <CustomModal
          header={""}
          text={apiError}
          buttons={[
            {
              text: "ok",
              variant: "blue-1",
              onClick: "closeModal",
              loading: false,
              disabled: false,
            },
          ]}
          onClose={closeModal}
          variant={"error"}
          functionsList={{ closeModal }}
        />
      )}
    </div>
  );
}
Payment.propTypes = {
  otherDetails: PropTypes.object.isRequired,
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["subscription", "enrollment", "points", "gift_card"])
    .isRequired,
  period: PropTypes.oneOf(["monthly", "yearly", "one_time"]).isRequired,
};
export default Payment;
