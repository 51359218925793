import { CallAPI } from "actions/General";
import TableComponent from "components/TableComponent";
import React, { useEffect, useState } from "react";
import ErrorComponent from "components/ErrorComponent";
import LoaderComponent from "components/LoaderComponent";
import { useNavigate } from "react-router-dom";
import ListingHeaderComponent from "components/ListingHeaderComponent";
import {
  ExportUnitHeaders,
  ModalButtons,
  UnitsGridHeader,
  UnitsTableHeader,
} from "./utils";
import PaginationComponent from "components/PaginationComponent";
import { DefaultPaginationSize, exportCSV } from "config/config";
import GridView from "../../components/GridView";
import CustomModal from "components/CustomModal";
import { getUserRole } from "recoil/Auth";
import { useRecoilValue } from "recoil";
import ImportData from "views/ImportData";
import Filter from "views/filters/Filter";

function Units() {
  const navigate = useNavigate();
  const userRole = useRecoilValue(getUserRole);

  const [allowImport, setAllowImport] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [units, setUnits] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [customError, setCustomError] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(DefaultPaginationSize);
  const [displayType, setDisplayType] = useState("list");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVariant, setModalVariant] = useState("");
  const [modalType, setModalType] = useState("");
  const [deactivatedId, setDeactivatedId] = useState("");
  const [deactivatedType, setDeactivatedType] = useState("");

  const [optionValues, setOptionValues] = useState({
    tag: [],
  });

  const [filterValues, setFilterValues] = useState({});

  const getUnitsList = async () => {
    setLoaderType("get_listing");
    let result = await CallAPI(
      "FETCH_UNITS_LIST",
      {
        relations: ["tags"],
        page,
        pageSize: limit,
        ...filterValues
      },
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setUnits(result.data.units);
      setTotalRecords(result.data.total);
    } else if (result.error === 403) {
      setIsModalOpen(true);
      setModalVariant("error");
      setModalType("listing403");
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        getUnitsList();
      },
      filterValues.search ? 500 : 0
    );
    return () => clearTimeout(delayDebounceFn);
  }, [page, limit, filterValues]);
  const addUnit = () => {
    navigate("/add_unit");
  };

    const getOptionValues = async () => {
      const tagsList = await CallAPI(
        "FETCH_ALL_TAGS",
        {},
        null,
        null,
        null,
        null
      );
      if (tagsList.status) {
        const allTags = tagsList.data.tags.map((singleTag) => {
          return {
            value: singleTag.id,
            displayValue: singleTag.title,
          };
        });
        setOptionValues((prevValues) => ({
          ...prevValues,
          tag: [{ value: "", displayValue: "Tags" }, ...allTags],
        }));
      }
  
    };
  

  const updateValues = (name, value) => {
    setFilterValues({ ...filterValues, [name]: value });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const deactivateUnitConfirmation = async (id, is_active) => {
    setDeactivatedId(id);
    setDeactivatedType(!is_active);
    setIsModalOpen(true);
    setModalVariant("confirm");
    setModalType(is_active ? "confirmDelete" : "confirmActive");
  };

  const deactivateUnit = async () => {
    setLoaderType("delete");
    let result = await CallAPI(
      "DE_ACTIVATE_UNIT",
      { is_active: deactivatedType },
      deactivatedId,
      setLoader,
      setApiError,
      null
    );
    setLoaderType("");
    if (result.status) {
      getUnitsList();
      setModalVariant("success");
      setModalType(!deactivatedType ? "recordDelete" : "recordActivated");
    } else {
      setModalVariant("error");
      setModalType("deletionError");
    }
  };
  const actionOnDeactivatedUnit = () => {
    setIsModalOpen(true);
    setModalVariant("error");
    setModalType("deactivateActionError");
  };
  const importUnits = () => {
    setAllowImport(true);
  };
  const exportUnits = async () => {
    setLoaderType("export");
    setLoader(true);
    const result = await exportCSV(
      "units",
      ExportUnitHeaders,
      "Units_List.csv"
    );
    setLoader(false);
    if (result.status) {
      setIsModalOpen(true);
      setModalVariant("success");
      setModalType("exportSuccess");
    } else {
      setIsModalOpen(true);
      setModalVariant("error");
      setModalType("exportError");
      setCustomError(result.message);
    }
  };


  useEffect(() => {
    getOptionValues();
  },[]);


  return (
    <>
      {loader && loaderType === "get_listing" && (
        <LoaderComponent classes={"mx-4"} />
      )}
      <div className="card mb-4">
        {apiError && <ErrorComponent text={apiError} variant="danger" />}
        <ListingHeaderComponent
          type={"units_listing"}
          setDisplayType={setDisplayType}
          displayType={displayType}
          addItem={userRole === "BUSINESS_ADMIN" ? addUnit : ""}
          importExportDetail={{
            allow: true,
            importFunc: importUnits,
            exportFunc: exportUnits,
            loaderType: loaderType,
            loader: loader,
          }}
        />

        <Filter
          type={"unit"}
          values={filterValues}
          setValues={setFilterValues}
          updateValues={updateValues}
          props={optionValues}
        />

        <div className={"card-body py-4"}>
          {displayType === "list" && (
            <TableComponent
              data={units}
              header={UnitsTableHeader}
              type="units"
              props={{ deactivateUnitConfirmation, actionOnDeactivatedUnit }}
            />
          )}
        </div>
      </div>
      {displayType === "grid" && (
        <GridView
          data={units}
          columns={UnitsGridHeader}
          type="units"
          props={{ deactivateUnitConfirmation, actionOnDeactivatedUnit }}
        />
      )}
      <PaginationComponent
        totalPages={
          totalRecords ? Math.ceil(totalRecords / limit) : totalRecords
        }
        pageLimit={limit}
        setPageLimit={setLimit}
        setPage={setPage}
        currentPage={page}
      />
      {isModalOpen && (
        <CustomModal
          header={""}
          text={
            ModalButtons[modalType].text !== ""
              ? ModalButtons[modalType].text
              : customError
          }
          buttons={ModalButtons[modalType].buttons.map((button) => {
            return {
              text: button.text,
              variant: button.variant,
              onClick: button.onClick,
              loading:
                button["loading"] !== undefined ? button.loading : loader,
              disabled:
                button["loading"] !== undefined ? button.disabled : loader,
            };
          })}
          onClose={closeModal}
          variant={modalVariant}
          functionsList={{ closeModal, deactivateUnit }}
        />
      )}
      {allowImport && (
        <ImportData setAllowImport={setAllowImport} type={"unit"} />
      )}
    </>
  );
}

export default Units;
