import { CallAPI } from "actions/General";
import ErrorComponent from "components/ErrorComponent";
import ListingHeaderComponent from "components/ListingHeaderComponent";
import LoaderComponent from "components/LoaderComponent";
import PaginationComponent from "components/PaginationComponent";
import TableComponent from "components/TableComponent";
import { DefaultPaginationSize, exportCSV } from "config/config";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CourseTableHeader,
  CourseGridHeader,
  ModalButtons,
  ExportCourseHeaders,
} from "./utils";
import GridView from "components/GridView";
import CustomModal from "components/CustomModal";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";
import ImportData from "views/ImportData";
import Filter from "views/filters/Filter";

function Course() {
  const navigate = useNavigate();
  const userRole = useRecoilValue(getUserRole);

 
  const [allowImport, setAllowImport] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [courses, setCourses] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [customError, setCustomError] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(DefaultPaginationSize);
  const [displayType, setDisplayType] = useState("list");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVariant, setModalVariant] = useState("");
  const [modalType, setModalType] = useState("");
  const [deactivatedId, setDeactivatedId] = useState("");
  const [deactivatedType, setDeactivatedType] = useState("");

  const [optionValues, setOptionValues] = useState({
    category: [],
    tag: [],
  });

  const [filterValues, setFilterValues] = useState({
  });


  const getCourseList = async () => {
    setLoaderType("get_listing");
    let result = await CallAPI(
      "FETCH_COURSES_LIST",
      {
        relations: ["tags", "subscription"],
        page,
        pageSize: limit,
        ...filterValues
      },
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setCourses(result.data.courses);
      setTotalRecords(result.data.total);
    } else if (result.error === 403) {
      setIsModalOpen(true);
      setModalVariant("error");
      setModalType("listing403");
    }
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        getCourseList();
      },
      filterValues.search ? 500 : 0
    );
    return () => clearTimeout(delayDebounceFn);
  }, [page, limit, filterValues]);

 const getOptionValues = async () => {
    const categoriesList = await CallAPI(
      "FETCH_ALL_CATEGORIES",
      {},
      null,
      null,
      null,
      null
    );
    if (categoriesList.status) {
      const allCategories = categoriesList.data.categories.map(
        (singleCategory) => {
          return {
            value: singleCategory.id,
            displayValue: singleCategory.title,
          };
        }
      );
      setOptionValues((prevValues) => ({
        ...prevValues,
        category: [{ value: "", displayValue: "Category" }, ...allCategories],
      }));
    }

    const tagsList = await CallAPI(
      "FETCH_ALL_TAGS",
      {},
      null,
      null,
      null,
      null
    );
    if (tagsList.status) {
      const allTags = tagsList.data.tags.map((singleTag) => {
        return {
          value: singleTag.id,
          displayValue: singleTag.title,
        };
      });
      setOptionValues((prevValues) => ({
        ...prevValues,
        tag: [{ value: "", displayValue: "Tags" }, ...allTags],
      }));
    }

    const subsList = await CallAPI(
          "FETCH_SUBSCRIPTIONS_LIST",
          null,
          null,
          setLoader,
          setApiError,
          null
        );
        if (subsList.status) {
          const allSubs = subsList.data.map((singleSub) => {
            return {
              value: singleSub.id,
              displayValue: singleSub.title,
            };
          });
          setOptionValues((prevValues) => ({
            ...prevValues,
            subscription: [{ value: "", displayValue: "Subscriptions" }, ...allSubs],
          }));
        }
  };

  useEffect(() => {
    getOptionValues();
  }, []);

  const addCourse = () => {
    navigate("/add_course");
  };

  const updateValues = (name, value) => {
    setFilterValues({ ...filterValues, [name]: value });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const deactivateCourseConfirmation = async (id, is_active) => {
    setDeactivatedId(id);
    setDeactivatedType(!is_active);
    setIsModalOpen(true);
    setModalVariant("confirm");
    setModalType(is_active ? "confirmDelete" : "confirmActive");
  };

  const deactivateCourse = async () => {
    setLoaderType("delete");
    let result = await CallAPI(
      "DE_ACTIVATE_COURSE",
      { is_active: deactivatedType },
      deactivatedId,
      setLoader,
      setApiError,
      null
    );
    setLoaderType("");
    if (result.status) {
      getCourseList();
      setModalVariant("success");
      setModalType(!deactivatedType ? "recordDelete" : "recordActivated");
    } else {
      setModalVariant("error");
      setModalType("deletionError");
    }
  };
  const actionOnDeactivatedCourse = () => {
    setIsModalOpen(true);
    setModalVariant("error");
    setModalType("deactivateActionError");
  };
  const importCourses = () => {
    setAllowImport(true);
  };
  const exportCourses = async () => {
    setLoaderType("export");
    setLoader(true);
    const result = await exportCSV(
      "courses",
      ExportCourseHeaders,
      "Courses_List.csv"
    );
    setLoader(false);
    if (result.status) {
      setIsModalOpen(true);
      setModalVariant("success");
      setModalType("exportSuccess");
    } else {
      setIsModalOpen(true);
      setModalVariant("error");
      setModalType("exportError");
      setCustomError(result.message);
    }
  };
  return (
    <>
      {loader && loaderType === "get_listing" && (
        <LoaderComponent classes={"mx-4"} />
      )}
      <div className="card mb-4">
        {apiError && <ErrorComponent text={apiError} variant="danger" />}
        <ListingHeaderComponent
          type={"course_listing"}
          setDisplayType={setDisplayType}
          displayType={displayType}
          addItem={userRole === "BUSINESS_ADMIN" ? addCourse : ""}
          importExportDetail={{
            allow: true,
            importFunc: importCourses,
            exportFunc: exportCourses,
            loaderType: loaderType,
            loader: loader,
          }}
        />

        <Filter
          type={"course"}
          values={filterValues}
          setValues={setFilterValues}
          updateValues={updateValues}
          props={optionValues}
        />
        <div className={"card-body py-4"}>
          {displayType === "list" && (
            <TableComponent
              data={courses}
              header={CourseTableHeader}
              type="course"
              props={{
                deactivateCourseConfirmation,
                actionOnDeactivatedCourse,
              }}
            />
          )}
        </div>
      </div>
      {displayType === "grid" && (
        <GridView
          data={courses}
          columns={CourseGridHeader}
          type="course"
          props={{
            deactivateCourseConfirmation,
            actionOnDeactivatedCourse,
          }}
        />
      )}
      <PaginationComponent
        totalPages={
          totalRecords ? Math.ceil(totalRecords / limit) : totalRecords
        }
        pageLimit={limit}
        setPageLimit={setLimit}
        setPage={setPage}
        currentPage={page}
      />
      {isModalOpen && (
        <CustomModal
          header={""}
          text={
            ModalButtons[modalType].text !== ""
              ? ModalButtons[modalType].text
              : customError
          }
          buttons={ModalButtons[modalType].buttons.map((button) => {
            return {
              text: button.text,
              variant: button.variant,
              onClick: button.onClick,
              loading:
                button["loading"] !== undefined ? button.loading : loader,
              disabled:
                button["loading"] !== undefined ? button.disabled : loader,
            };
          })}
          onClose={closeModal}
          variant={modalVariant}
          functionsList={{ closeModal, deactivateCourse }}
        />
      )}
      {allowImport && (
        <ImportData setAllowImport={setAllowImport} type={"courses"} />
      )}
    </>
  );
}

export default Course;
