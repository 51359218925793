import React, { useState } from "react";
import { ViewAttendanceHeader } from "./utils";
import TableComponent from "components/TableComponent";
import ListingHeaderComponent from "components/ListingHeaderComponent";

function ViewAttendance() {
  const [attendance, setAttendence] = useState([
    {
      student_name: "User 1",
      student_email: "user1@emil.com",
      is_active: "Active",
    },
    {
      student_name: "User 2",
      student_email: "user2@emil.com",
      is_active: "Active",
    },
    {
      student_name: "User 3",
      student_email: "user3@emil.com",
      is_active: "inActive",
    },
  ]);
  return (
    <div className="card mb-4">
      <ListingHeaderComponent
        type={"view_attendance"}
        setDisplayType={()=>{}}
        displayType={''}
      />
      <div className={"card-body py-4"}>
        <TableComponent
          data={attendance}
          header={ViewAttendanceHeader}
          type="view_attendance"
        />
      </div>
    </div>
  );
}

export default ViewAttendance;
