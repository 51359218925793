export const LogTypes = [
  {
    key: "all",
    value: "all",
    validFor: ["STUDENT", "BUSINESS_ADMIN", "TEACHER", "SUPER_ADMIN"],
  },
  // {
  //   key: "rewards",
  //   value: "reward_points",
  // },
  // {
  //   key: "gift",
  //   value: "gift_card",
  // },
  {
    key: "enrollment",
    value: "enrollments",
    validFor: ["STUDENT", "BUSINESS_ADMIN", "TEACHER", "SUPER_ADMIN"],
  },
  {
    key: "subscription",
    value: "subscriptions",
    validFor: ["STUDENT", "BUSINESS_ADMIN", "SUPER_ADMIN"],
  },
  {
    key: "program",
    value: "programs",
    validFor: ["BUSINESS_ADMIN", "SUPER_ADMIN"],
  },
  {
    key: "course",
    value: "courses",
    validFor: ["BUSINESS_ADMIN", "SUPER_ADMIN"],
  },
  {
    key: "module",
    value: "modules",
    validFor: ["BUSINESS_ADMIN", "SUPER_ADMIN"],
  },
  {
    key: "unit",
    value: "units",
    validFor: ["BUSINESS_ADMIN", "SUPER_ADMIN"],
  },
];
export const LogActions = {
  create_program:
    '<span class="user_name">{{name}}</span> created a program <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Program)',
  update_program:
    '<span class="user_name">{{name}}</span> update a program <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Program)',
  published_program:
    '<span class="user_name">{{name}}</span> published a program <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Program)',
  activate_program:
    '<span class="user_name">{{name}}</span> activated a program <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Program)',
  deactivate_program:
    '<span class="user_name">{{name}}</span> deactivated a program <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Program)',
  create_course:
    '<span class="user_name">{{name}}</span> created a course <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Course)',
  update_course:
    '<span class="user_name">{{name}}</span> update a course <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Course)',
  published_course:
    '<span class="user_name">{{name}}</span> published a course <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Course)',
  activate_course:
    '<span class="user_name">{{name}}</span> activated a course <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Course)',
  deactivate_course:
    '<span class="user_name">{{name}}</span> deactivated a course <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Course)',
  create_module:
    '<span class="user_name">{{name}}</span> created a module <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Module)',
  update_module:
    '<span class="user_name">{{name}}</span> update a module <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Module)',
  published_module:
    '<span class="user_name">{{name}}</span> published a module <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Module)',
  activate_module:
    '<span class="user_name">{{name}}</span> activated a module <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Module)',
  deactivate_module:
    '<span class="user_name">{{name}}</span> deactivated a module <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Module)',
  create_unit:
    '<span class="user_name">{{name}}</span> created a unit <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Unit)',
  update_unit:
    '<span class="user_name">{{name}}</span> update a unit <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Unit)',
  published_unit:
    '<span class="user_name">{{name}}</span> published a unit <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Unit)',
  activate_unit:
    '<span class="user_name">{{name}}</span> activated a unit <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Unit)',
  deactivate_unit:
    '<span class="user_name">{{name}}</span> deactivated a unit <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Unit)',
  create_tag:
    '<span class="user_name">{{name}}</span> created a tag <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Tag)',
  update_tag:
    '<span class="user_name">{{name}}</span> update a tag <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Tag)',
  delete_tag:
    '<span class="user_name">{{name}}</span> deleted a tag <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Tag)',
  create_category:
    '<span class="user_name">{{name}}</span> created a category <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Category)',
  update_category:
    '<span class="user_name">{{name}}</span> update a category <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Category)',
  delete_category:
    '<span class="user_name">{{name}}</span> deleted a category <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Category)',
  create_enrollment:
    '<span class="user_name">{{name}}</span> enrolled in a {{enrollmentType}} <span class="user_name">{{title}}</span> at <span class="user_name">{{time}}</span> (Enrollment)',
  create_enrollment_in_program:
    '<span class="user_name">{{name}}</span> enrolled in a {{enrollmentType}} <span class="user_name">{{title}}</span> inside a program <span class="user_name">{{programTitle}}</span> at <span class="user_name">{{time}}</span> (Enrollment)',
  earn_points:
    '<span class="user_name">{{name}}</span> purchased <span className="price">100</span> point from stripe at 10:30AM(Reward Points)',
};
