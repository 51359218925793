export const CategoriesTableHeader = [
  { key: "title", displayValue: "categories_list", sort: true },
];
export const ExportCategoryHeaders = [
  { key: "id", label: "Id" },
  { key: "title", label: "Title" },
];
export const ExportCategoryTableHeaders = [
  { key: "no", displayValue: "#" },
  { key: "id", displayValue: "id" },
  { key: "title", displayValue: "title" },
];
export const ModalButtons = {
  confirmDelete: {
    text: "delete_record",
    buttons: [
      {
        text: "delete",
        variant: "red-1",
        onClick: "deleteCategory",
        loading: false,
        disabled: false,
      },
      {
        text: "cancel",
        variant: "white-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordDelete: {
    text: "record_deleted",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  listing403: {
    text: "403_error_msg",
    buttons: [
      {
        text: "ok",
        variant: "red-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
};
