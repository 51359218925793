import React from "react";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";
import BusinessSettings from "./BusinessSettings";
import SuperAdminSettings from "./SuperAdminSettings";

function Settings() {
  const userRole = useRecoilValue(getUserRole);
  return (
    <>
      {userRole === "SUPER_ADMIN" ? (
        <SuperAdminSettings />
      ) : (
        <BusinessSettings />
      )}
    </>
  );
}

export default Settings;
