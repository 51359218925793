import React, { useState } from "react";
import PropTypes from "prop-types";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // for event interaction features (optional)
import { CallAPI } from "actions/General";
import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";
import moment from "moment";
import BackImage from "assets/images/general/back.svg";

function CalendarView({ setDisplayType }) {
  const [schedules, setSchedules] = useState([]);
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState("");
  const getSchedulesList = async (start, end) => {
    const result = await CallAPI(
      "FETCH_CALENDAR_SCHEDULE_LIST",
      { start_date: start, end_date: end },
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      const schedulesData = result.data.schedule.map((schedule) => ({
        title: schedule.title,
        start: new Date(schedule.schedule_date * 1000),
      }));
      setSchedules(schedulesData);
    }
  };
  const handleViewChange = (view) => {
    const date = view.view.getCurrentData().currentDate;
    let startUnix = 0;
    let endUnix = 0;
    if (view.view.type === "dayGridMonth") {
      startUnix = moment(date).startOf("month").unix();
      endUnix = moment(date).endOf("month").unix();
    } else if (view.view.type === "dayGridWeek ") {
      startUnix = moment(date).startOf("week").unix();
      endUnix = moment(date).endOf("week").unix();
    } else {
      startUnix = moment(date).startOf("day").unix();
      endUnix = moment(date).endOf("day").unix();
    }
    getSchedulesList(startUnix, endUnix);
  };
  return (
    <div className="calendar-view">
      <div className="top-header">
        <span onClick={() => setDisplayType("list")}>
          <img src={BackImage} alt="back" />
          Calendar
        </span>
      </div>
      <div className="calendar">
        {loader && <LoaderComponent classes={"mx-4"} />}
        {apiError && <ErrorComponent text={apiError} variant="danger" />}
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          weekends={true}
          events={schedules}
          eventContent={renderEventContent}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,dayGridWeek,dayGridDay",
          }}
          datesSet={handleViewChange}
        />
      </div>
    </div>
  );
}
function renderEventContent(eventInfo) {
  function generateHslColor(username, s, l) {
    let hash = 0;
    for (let i = 0; i < username.length; i++) {
      hash = username.charCodeAt(i) + ((hash << 5) - hash);
    }
    let h = hash % 360;
    return "hsl(" + h + ", " + s + "%, " + l + "%)";
  }
  const hslVal = generateHslColor(eventInfo.event.title, 50, 50);
  return (
    <div className="event-content" style={{ "--hslVal": hslVal }}>
      <b>{eventInfo.event.title}</b>
      <span>{eventInfo.timeText}</span>
    </div>
  );
}
CalendarView.propTypes = {
  setDisplayType: PropTypes.func.isRequired,
};
export default CalendarView;
