import React, { useEffect, useState } from "react";
import { ExportTagHeaders, ModalButtons, TagsTableHeader } from "./utils";
import { CallAPI } from "actions/General";
import ErrorComponent from "components/ErrorComponent";
import LoaderComponent from "components/LoaderComponent";
import TableComponent from "components/TableComponent";
import { useTranslation } from "react-i18next";
import AddTag from "./AddTag";
import { DefaultPaginationSize, exportCSV } from "config/config";
import CustomModal from "components/CustomModal";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";
import ImportData from "views/ImportData";

function Tags() {
  const { t } = useTranslation();
  const userRole = useRecoilValue(getUserRole);

  const [allowImport, setAllowImport] = useState(false);
  const [hasMoreTags, setHasMoreTags] = useState(true);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [lastRecordId, setLastRecordId] = useState("");
  const [tags, setTags] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(DefaultPaginationSize);
  const [editObj, setEditObj] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVariant, setModalVariant] = useState("");
  const [modalType, setModalType] = useState("");
  const [deletedId, setDeletedId] = useState("");

  const getAllTags = async (type = "forward", recordId = null) => {
    setLoaderType("list_all");
    let result = await CallAPI(
      "FETCH_ALL_TAGS",
      {
        page: page,
        pageSize: limit,
        type,
        lastRecordId: recordId !== null ? recordId : lastRecordId,
      },
      null,
      setLoader,
      setApiError,
      null
    );
    setLoaderType("");
    if (result.status) {
      let tagsList = result.data.tags;
      if (recordId === "" || type === "backward") {
        setTags(tagsList);
        setLastRecordId(
          tagsList.length > 0 ? tagsList[tagsList.length - 1].id : ""
        );
      } else if (tagsList.length > 0) {
        setTags([...tags, ...tagsList]);
        setLastRecordId(tagsList[tagsList.length - 1].id);
        if (tagsList.length < limit && type !== "backward") {
          setHasMoreTags(false);
        }
      } else {
        setHasMoreTags(false);
      }
    } else if (result.error === 403) {
      setIsModalOpen(true);
      setModalVariant("error");
      setModalType("listing403");
      setHasMoreTags(false);
    }
  };
  useEffect(() => {
    getAllTags();
  }, [page]);

  const tagDeleted = (id) => {
    let recordId = null;
    if (id === lastRecordId && tags.length > 1) {
      recordId = tags[tags.length - 2].id;
    }
    if (tags.length > 1) getAllTags("backward", recordId);
    else getAllTags("forward", "");
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const deleteTagConfirmation = async (id) => {
    setDeletedId(id);
    setIsModalOpen(true);
    setModalVariant("confirm");
    setModalType("confirmDelete");
  };

  const deleteTag = async () => {
    setLoaderType("delete");
    let result = await CallAPI(
      "DELETE_TAG",
      null,
      deletedId,
      setLoader,
      setApiError,
      null
    );
    setLoaderType("");
    if (result.status) {
      if (editObj && deletedId === editObj.id) setEditObj(null);
      tagDeleted(deletedId);
      setModalVariant("success");
      setModalType("recordDelete");
    } else {
      setModalVariant("error");
      setModalType("deletionError");
    }
  };
  const importTags = () => {
    setAllowImport(true);
  };
  const exportTags = async () => {
    setLoaderType("export");
    setLoader(true);
    await exportCSV("tags", ExportTagHeaders, "Tags_List.csv");
    setLoader(false);
  };
  return (
    <>
      {loader && loaderType === "list_all" && (
        <LoaderComponent classes={"mx-4"} />
      )}
      {userRole === "BUSINESS_ADMIN" && (
        <div className="card mb-4">
          <div className={"card-body py-3"}>
            <div className="mb-3 pt-4">
              <AddTag
                getAllTags={getAllTags}
                editObj={editObj}
                setEditObj={setEditObj}
                importExportDetail={{
                  importFunc: importTags,
                  exportFunc: exportTags,
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div className="card mb-4">
        {apiError && <ErrorComponent text={apiError} variant="danger" />}
        <div className={"card-body py-4"}>
          <TableComponent
            data={tags}
            header={TagsTableHeader}
            type="tags"
            props={{ setEditObj, deleteTagConfirmation }}
          />
          {hasMoreTags && (
            <span
              onClick={() => {
                setPage(page + 1);
              }}
              className="load-more"
            >
              {t("general.load_more")}
            </span>
          )}
        </div>
      </div>
      {isModalOpen && (
        <CustomModal
          header={""}
          text={ModalButtons[modalType].text}
          buttons={ModalButtons[modalType].buttons}
          onClose={closeModal}
          variant={modalVariant}
          functionsList={{ closeModal, deleteTag }}
        />
      )}
      {allowImport && (
        <ImportData setAllowImport={setAllowImport} type={"tag"} />
      )}
    </>
  );
}

export default Tags;
