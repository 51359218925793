import React from "react";
import PropTypes from "prop-types";
import ButtonComponent from "./ButtonComponent";
import CloseImage from "assets/images/general_old/close.svg";
import ErrorImage from "assets/images/general_old/errorIcon.png";
import { useTranslation } from "react-i18next";

function CustomModal({
  variant,
  text,
  header,
  onClose,
  buttons,
  functionsList,
}) {
  const { t, i18n } = useTranslation();
  return (
    <div className={`custom-modal ${variant}`}>
      <div className="detail">
        <div className={`variant ${variant}`}>
          {variant === "confirm" ? (
            <div>!</div>
          ) : variant === "error" ? (
            <>
              <div>
                <img src={ErrorImage} alt="Error Image" />
              </div>
            </>
          ) : (
            <>
              <div></div>
              <div></div>
            </>
          )}
        </div>
        <img className="close" src={CloseImage} onClick={onClose} alt="close" />
        {header && <span className="header">{t("modal." + header)}</span>}
        {text && (
          <span className="body">
            {i18n.exists("modal." + text) ? t("modal." + text) : text}
          </span>
        )}
        <div className="d-flex gap-3 justify-content-center">
          {buttons.map((singleButton, index) => (
            <div key={index} className="w-auto">
              <ButtonComponent
                text={singleButton.text}
                variant={singleButton.variant}
                onClick={() => functionsList[singleButton.onClick]()}
                loading={singleButton.loading}
                disabled={singleButton.loading}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
CustomModal.propTypes = {
  variant: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  functionsList: PropTypes.object.isRequired,
  buttons: PropTypes.array.isRequired,
};
export default CustomModal;
