import React, { useState } from "react";
import PropTypes from "prop-types";
import GridViewActionImage from "assets/images/general/grid-view-action.svg";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";
import OutsideClickHandler from "react-outside-click-handler";
import ButtonComponent from "components/ButtonComponent";

function TrackStudentActions({ data, props }) {
  const userRole = useRecoilValue(getUserRole);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const award_credits = () => {};

  return (
    <div className="d-flex gap-3 table-actions">
      <div className="position-relative">
        {userRole !== "STUDENT" && (
          <ButtonComponent
            text={"award_credits"}
            variant={"orange-1"}
            onClick={() => {}}
          />
        )}
      </div>
    </div>
  );
}
TrackStudentActions.propTypes = {
  data: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};
export default TrackStudentActions;
