import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";

function SelectComponentWithImage({
  name,
  label,
  classes,
  optionsList,
  value,
  onChange,
  error,
  required,
}) {
  const [showList, setShowList] = useState(false);
  const onSelect = (value) => {
    onChange(name, value);
    setShowList(false);
  };
  const { t } = useTranslation();
  return (
    <div className={`fv-row mb-5 ${classes} custom-select`}>
      {label && (
        <label className={"form-label"}>
          <span className={`${required ? "required" : ""}`}>
            {t("fields." + label)}
          </span>
        </label>
      )}
      <OutsideClickHandler
        onOutsideClick={() => {
          setShowList(false);
        }}
      >
        <>
          <div className={"form-select form-select-lg form-select-solid cursor-pointer"} onClick={() => setShowList(!showList)}>
            <img src={optionsList.find((x) => x.value === value).img} alt="" />
            <span>
              {optionsList.find((x) => x.value === value).displayValue}
            </span>
          </div>
          {showList && (
            <div className="options-list">
              {optionsList.map((singleOption, index) => {
                return (
                  <span
                    key={index}
                    onClick={() => onSelect(singleOption.value)}
                  >
                    <img src={singleOption.img} alt="" />
                    {singleOption.displayValue}
                  </span>
                );
              })}
            </div>
          )}
          {error && <div className="text-danger mt-2">{error}</div>}
        </>
      </OutsideClickHandler>
    </div>
  );
}
SelectComponentWithImage.propTypes = {
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
  optionsList: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};
export default SelectComponentWithImage;
