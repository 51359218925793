import React from "react";
import DEFAULT_IMG from "assets/images/general/wallet.svg";

function Wallet() {
  return (
    <div className="wallet">
      <div className="top">
        <img src={DEFAULT_IMG} alt="Unit Image" />
        <div className="detail">
          <div className="wallet-header">
            <span>Digital Wallet</span>
          </div>
          <h1>Wallet</h1>
        </div>
      </div>
    </div>
  );
}

export default Wallet;
