import React, { useEffect, useState } from "react";
import ButtonComponent from "components/ButtonComponent";
import TextComponent from "components/TextComponent";
import TextRadioComponent from "components/TextRadioComponent";
import { CallAPI } from "actions/General";
import CustomModal from "components/CustomModal";
import LoaderComponent from "components/LoaderComponent";

function RewardsConversion() {
  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState("");
  const [apiError, setApiError] = useState("");
  const [recordId, setRecordId] = useState("");
  const [values, setValues] = useState({});

  const updateSettings = async () => {
    setLoadingType("save");
    await CallAPI(
      "SAVE_DEFAULT_SYSTEM_SETTINGS",
      {
        points_per_usd: parseInt(values.points_to_usd),
        points_per_ngn: parseInt(values.points_to_ngn),
        points_per_cad: parseInt(values.points_to_cad),
      },
      recordId,
      null,
      null,
      null
    );
  };
  const updateFormValues = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  const getRewardConversionSettings = async () => {
    setLoadingType("get_details");
    const result = await CallAPI(
      "GET_DEFAULT_SYSTEM_SETTINGS",
      null,
      "points",
      setLoading,
      null,
      null
    );
    if (result.status) {
      setRecordId(result.data.id);
      setValues({
        points_to_usd: result.data.points_per_usd,
        points_to_ngn: result.data.points_per_ngn,
        points_to_cad: result.data.points_per_cad,
      });
    }
  };
  useEffect(() => {
    getRewardConversionSettings();
  }, []);
  const closeModel = () => {
    setApiError("");
  };
  return (
    <div className="mb-4 box-width-flex">
      {loading && loadingType === "get_details" ? (
        <LoaderComponent classes="mx-3" />
      ) : (
        <></>
      )}
      {Object.keys(values).length > 0 && (
        <div>
          <TextComponent
            name={"points_to_usd"}
            label={"point_to_usd"}
            placeholder={"point_to_usd"}
            value={values.points_to_usd}
            setValue={updateFormValues}
            classes={""}
            type="number"
            required={false}
          />
          <TextComponent
            name={"points_to_cad"}
            label={"point_to_cad"}
            placeholder={"point_to_cad"}
            value={values.points_to_cad}
            setValue={updateFormValues}
            classes={""}
            type="number"
            required={false}
          />
          <TextComponent
            name={"points_to_ngn"}
            label={"point_to_ngn"}
            placeholder={"point_to_ngn"}
            value={values.points_to_ngn}
            setValue={updateFormValues}
            classes={""}
            type="number"
            required={false}
          />
        </div>
      )}
      <div>
        <ButtonComponent
          text="update_settings"
          onClick={updateSettings}
          variant="blue-1 ms-auto"
          loading={loading && loadingType === "save"}
          disabled={loading && loadingType === "save"}
        />
      </div>
      {apiError && (
        <CustomModal
          header={""}
          text={apiError}
          buttons={[
            {
              text: "ok",
              variant: "blue-1",
              onClick: "closeModel",
              loading: false,
              disabled: false,
            },
          ]}
          onClose={closeModel}
          variant={"error"}
          functionsList={{ closeModel }}
        />
      )}
    </div>
  );
}

export default RewardsConversion;
