import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CloseImage from "assets/images/general_old/close.svg";
import { DefaultPaginationSize } from "config/config";
import { CallAPI } from "actions/General";
import TableComponent from "components/TableComponent";
import { CancellationRequestTableHeader } from "./utils";
import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";

function AutoChargeCancellationRequest({ setShowCancellationRequests }) {
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState("");
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(DefaultPaginationSize);
  const [requestsData, setRequestsData] = useState([]);

  const getAllPendingRequests = async () => {
    const result = await CallAPI(
      "GET_AUTO_CHARGE_CANCELLATION_REQUESTS",
      { page: page, pageSize: pageLimit },
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setRequestsData(result.data.requests);
    }
  };
  useEffect(() => {
    getAllPendingRequests();
  }, [page, pageLimit]);
  return (
    <>
      {loader && <LoaderComponent classes={"mx-4"} />}
      <div className={`custom-modal`}>
        <div className="auto_charge_cancel_requests">
          <img
            className="close"
            src={CloseImage}
            onClick={() => setShowCancellationRequests(false)}
            alt="close"
          />
          <h2>Auto Charge Cancellation Requests</h2>
          {apiError && <ErrorComponent text={apiError} variant="danger" />}
          <div className={"card-body py-4"}>
            <TableComponent
              data={requestsData}
              header={CancellationRequestTableHeader}
              type="cancellation_requests"
              props={{}}
            />
          </div>
        </div>
      </div>
    </>
  );
}

AutoChargeCancellationRequest.propTypes = {
  setShowCancellationRequests: PropTypes.func.isRequired,
};
export default AutoChargeCancellationRequest;
