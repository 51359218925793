import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoaderComponent from "components/LoaderComponent";
import { getImagePreviewUrl } from "config/config";
import { CallAPI } from "actions/General";
import DEFAULT_IMG from "assets/images/general/default-image.svg";
import COURSE_TICK from "assets/images/general/course-tick.svg";
import DisplayHtml from "views/DisplayHtml";
import ButtonComponent from "components/ButtonComponent";
import allFunction, { convertSeconds } from "actions/TableUtils";
import { ViewProgramDetailTabs } from "./utils";
import TabsComponent from "components/TabsComponent";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";

function ProgramDetailView() {
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = useRecoilValue(getUserRole);

  const [values, setValues] = useState({});
  const [loader, setLoader] = useState(true);
  const [loaderType, setLoaderType] = useState("get_list");
  const [selectedTab, setSelectedTab] = useState(1);
  const [imageUrl, setImageUrl] = useState("");
  const [enrolledObj, setEnrolledObj] = useState({});

  const getCourseDetails = async (record_id) => {
    if (record_id) {
      setLoaderType("get_list");
      let result = await CallAPI(
        "GET_PROGRAM_DETAIL",
        null,
        record_id,
        setLoader,
        null,
        null
      );
      if (result.status) {
        let programObj = result.data;
        let prevValues = {
          title: programObj.title,
          version: programObj.version,
          subscription: programObj.subscription
            ? programObj.subscription.title
            : "",
          banner: programObj.banner_image ? programObj.banner_image.id : null,
          reward_credits: programObj.reward_credits,
          enroll_credits: programObj.enroll_credits,
          category_name: programObj.category.title,
          language: programObj.language,
          slug: programObj.slug,
          status: programObj.status,
          detail: programObj.description,
          is_free: programObj.is_free ? "1" : "0",
          short_description: programObj.short_description,
          completion_duration: programObj.completion_duration,
          pre_req_courses: programObj.pre_req_courses
            ? programObj.pre_req_courses
            : [],
          compulsory_courses: programObj.compulsory_courses
            ? programObj.compulsory_courses
            : [],
          elective_courses: programObj.elective_courses
            ? programObj.elective_courses
            : [],
          quiz_url: programObj.quiz_url,
          completed_percentage:
            programObj.certificate_criteria.completed_percentage,
          quiz_percentage: programObj.certificate_criteria.quiz_percentage,
          validity_period: programObj.certificate_criteria.validity_period,
          validity_type: programObj.certificate_criteria.validity_type,
          cert_description: programObj.certificate_criteria.description,
          payment_type: programObj.payment_type,
          author_name: programObj.author_name,
          tags: programObj.tags.map((singleTag) => {
            return singleTag.title;
          }),
          learning_points: programObj.learning_points.map(
            (singleLearningPoint) => {
              return singleLearningPoint.title;
            }
          ),
          enrollmentDetails: programObj.enrollmentDetails ?? null,
        };
        setValues({
          ...prevValues,
        });
        if (prevValues.banner) {
          getPreviewImg(prevValues.banner);
        }
        setEnrolledObj({
          type: "program",
          program_id: programObj.program_id,
          version_id: record_id,
          title: programObj.title,
          payment_type: programObj.is_free
            ? { type: "free" }
            : programObj.subscription
            ? { type: "subscription", slug: programObj.subscription.slug }
            : { type: "pay", method: programObj.payment_type },
        });
      }
    }
  };
  const getPreviewImg = async (id) => {
    const url = await getImagePreviewUrl(id, null);
    setImageUrl(url);
  };
  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.record_id &&
      location.state.record_id !== ""
    )
      getCourseDetails(location.state.record_id);
  }, [location]);

  const publishProgram = async () => {
    setLoaderType("publish");
    const result = await CallAPI(
      "PUBLISH_PROGRAM",
      {
        status: "PUBLISHED",
      },
      location.state.record_id,
      setLoader,
      null,
      null
    );
    if (result.status) getCourseDetails(location.state.record_id);
  };

  const moveToPage = (page) => {
    navigate(page, { state: enrolledObj });
  };
  const moveToCoursesPage = (page) => {
    navigate(page, {
      state: {
        course_id: values.enrollmentDetails.version_id,
        enrollment_id: values.enrollmentDetails.enrollment_id,
      },
    });
  };
  return (
    <>
      {loader && loaderType === "get_list" ? (
        <LoaderComponent classes="mx-4" />
      ) : (
        <div className="module-detail-view preview">
          <div className="p-5 pt-18 position-relative">
            <div className="item basic p-5">
              <img src={values.banner ? imageUrl : DEFAULT_IMG} alt="Unit" />
              <div className="top">
                <div>
                  <span className="text-gray-800 fw-bold">Title:</span>
                  <span className="text-gray-800">{values.title}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Category:</span>
                  <span className="text-gray-800">{values.category_name}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">language:</span>
                  <span className="text-gray-800">{values.language}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Author Name:</span>
                  <span className="text-gray-800">{values.author_name}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Subscription:</span>
                  <span className="text-gray-800">{values.subscription}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Tags:</span>
                  <span className="text-gray-800">
                    {values.tags.join(", ")}
                  </span>
                </div>
              </div>
              <div className="top">
                <div>
                  <span className="text-gray-800 fw-bold">Status:</span>
                  <span className="text-gray-800">
                    {allFunction.displayUnitStatus(values.status)}
                  </span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Enroll Credits:</span>
                  <span className="text-gray-800">{values.enroll_credits}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Reward Credits:</span>
                  <span className="text-gray-800">{values.reward_credits}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Payment Type:</span>
                  <span className="text-gray-800">
                    {allFunction.getSubscriptionType(values.payment_type)}
                  </span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">is Free:</span>
                  <span className="text-gray-800">
                    {values.is_free ? "True" : "False"}
                  </span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Slug:</span>
                  <span className="text-gray-800">{values.slug}</span>
                </div>
              </div>
            </div>
            {/*<div className="actions-buttons">
              <div>
                <ButtonComponent
                  text="publish_program"
                  onClick={publishProgram}
                  variant="blue-1"
                  loading={loader && loaderType === "publish"}
                  disabled={
                    allFunction.isItemPublished(values.status) ||
                    (loader && loaderType === "publish")
                  }
                />
              </div>
            </div>*/}
            <div className="actions-buttons">
              {userRole === "BUSINESS_ADMIN" ? (
                <div>
                  <ButtonComponent
                    text="publish_program"
                    onClick={publishProgram}
                    variant="blue-1"
                    loading={loader && loaderType === "publish"}
                    disabled={
                      allFunction.isItemPublished(values.status) ||
                      (loader && loaderType === "publish")
                    }
                  />
                </div>
              ) : userRole === "TEACHER" ? (
                values.enrollmentDetails &&
                (values.enrollmentDetails.timeToStart > 30 * 60 ? (
                  <span className="theme-font-inter-medium px-4 starts-in-size">
                    {"Starts In " +
                      convertSeconds(values.enrollmentDetails.timeToStart)}
                  </span>
                ) : values.enrollmentDetails.timeToStart > -1 * 30 * 60 ? (
                  <ButtonComponent
                    text="start_class"
                    onClick={() => console.log("Start Class")}
                    variant="blue-1 type-2"
                    loading={false}
                    disabled={false}
                  />
                ) : (
                  <></>
                ))
              ) : (
                userRole === "STUDENT" && (
                  <>
                    {/* {values.enrollmentDetails &&
                      values.enrollmentDetails.allowApplyForQuiz && (
                        <div>
                          <ButtonComponent
                            text="apply_for_quiz"
                            onClick={publishCourse}
                            variant="orange-1 type-2"
                            loading={false}
                            disabled={false}
                          />
                        </div>
                      )} */}
                    {values.enrollmentDetails &&
                      (values.enrollmentDetails.timeToStart > 0 ? (
                        values.enrollmentDetails.timeToStart > 30 * 60 ? (
                          <span className="theme-font-inter-medium px-4 starts-in-size">
                            {"Starts In " +
                              convertSeconds(
                                values.enrollmentDetails.timeToStart
                              )}
                          </span>
                        ) : (
                          <ButtonComponent
                            text="join_class"
                            onClick={() =>
                              // moveToModulesPage("/enrollment_modules")
                              console.log("Join Class")
                            }
                            variant="blue-1 type-2"
                            loading={false}
                            disabled={false}
                          />
                        )
                      ) : (
                        <>
                          {values.enrollmentDetails.timeToStart >
                            -1 * (30 * 60) && (
                            <div>
                              <ButtonComponent
                                text="join_class"
                                onClick={() =>
                                  // moveToModulesPage("/enrollment_modules")
                                  console.log("Join Class")
                                }
                                variant="blue-1 type-2"
                                loading={false}
                                disabled={false}
                              />
                            </div>
                          )}
                          <div>
                            <ButtonComponent
                              text="view_notes"
                              onClick={() =>
                                moveToCoursesPage("/enrollment_courses")
                              }
                              variant="blue-1 type-2"
                              loading={false}
                              disabled={false}
                            />
                          </div>
                          {/* <div>
                            <ButtonComponent
                              text="view_progress"
                              onClick={() => moveToPage("/enrollment_progress")}
                              variant="blue-9 type-2"
                              loading={false}
                              disabled={false}
                            />
                          </div> */}
                        </>
                      ))}
                    {/* <div>
                      <ButtonComponent
                        text="qa"
                        onClick={publishCourse}
                        variant="blue-10 type-2"
                        loading={false}
                        disabled={false}
                      />
                    </div> */}

                    {!values.enrollmentDetails && (
                      <div>
                        <ButtonComponent
                          text="enroll"
                          onClick={() => moveToPage("/enroll_schedules")}
                          variant="blue-9 type-2"
                          loading={false}
                          disabled={false}
                        />
                      </div>
                    )}
                  </>
                )
              )}
            </div>
          </div>
          <div className="p-5">
            <TabsComponent
              tabs={ViewProgramDetailTabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              type={"start"}
            >
              <div className="p-5 tab-item-detail item">
                {selectedTab === 1 ? (
                  <>
                    <span className="text-gray-800 fw-bold">
                      Short Description
                    </span>
                    <span className="text-gray-800">
                      {values.short_description}
                    </span>
                    <hr />
                    <span className="text-gray-800 fw-bold">Description</span>
                    <span className="text-gray-800">
                      {<DisplayHtml html={values.detail} />}
                    </span>
                  </>
                ) : selectedTab === 2 ? (
                  <ul>
                    {values.learning_points.map(
                      (singleLearningPoint, index) => {
                        return (
                          <li className="text-gray-800" key={index}>
                            {singleLearningPoint}
                          </li>
                        );
                      }
                    )}
                  </ul>
                ) : selectedTab === 3 ? (
                  <div className="item courses">
                    <div>
                      <span className="text-gray-800 fw-bold">
                        Compulsory Courses
                      </span>
                      <ul className="d-flex flex-column gap-3">
                        {values.compulsory_courses.map(
                          (singleCourse, index) => {
                            return (
                              <li className="text-gray-800" key={index}>
                                <img src={COURSE_TICK} alt="tick" />
                                {singleCourse.title}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                    <div>
                      <span className="text-gray-800 fw-bold">
                        Elective Courses
                      </span>
                      <ul className="d-flex flex-column gap-3">
                        {values.elective_courses.map((singleCourse, index) => {
                          return (
                            <li className="text-gray-800" key={index}>
                              <img src={COURSE_TICK} alt="tick" />
                              {singleCourse.title}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div>
                      <span className="text-gray-800 fw-bold">
                        Pre Requisite Courses
                      </span>
                      <ul className="d-flex flex-column gap-3">
                        {values.pre_req_courses.map((singleCourse, index) => {
                          return (
                            <li className="text-gray-800" key={index}>
                              <img src={COURSE_TICK} alt="tick" />
                              {singleCourse.title}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <>Enrollment details</>
                )}
              </div>
            </TabsComponent>
          </div>
        </div>
      )}
    </>
  );
}

export default ProgramDetailView;
