import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import ButtonComponent from "./ButtonComponent";
import AddIcon from "assets/images/general/plus-icon.svg";
import ActiveList from "assets/images/general/active-list.svg";
import ActiveGrid from "assets/images/general/active-grid.svg";
import InActiveList from "assets/images/general/inactive-list.svg";
import InActiveGrid from "assets/images/general/inactive-grid.svg";
import CalendarView from "assets/images/general/calendar.svg";
import { ListingHeaderItems } from "config/ListingHeaderItem";
import TextComponent from "./TextComponent";
import SelectComponent from "./SelectComponent";
import { CompetencyLevels } from "config/config";
import AddFilterImage from "assets/images/general_old/add-filter.svg";
import DeleteFilterImage from "assets/images/general_old/delete-filter.svg";
import DisplaySVG from "./DisplaySVG";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";

function ListingHeaderComponent({
  type,
  title,
  displayType,
  setDisplayType,
  addItem,
  children,
  importExportDetail = null,
}) {
  const userRole = useRecoilValue(getUserRole);
  const defaultAdvancedFilters = [
    { displayValue: "Select Filter", value: "" },
    { displayValue: "Published Between", value: "published" },
    { displayValue: "Competency Level", value: "competency" },
  ];

  const [addNewAdvancedFilter, setAddNewAdvancedFilter] = useState(true);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [values, setValues] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const [advancedFilterList, setAdvancedFilterList] = useState(
    defaultAdvancedFilters
  );
  const handleValueChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  const handleFilterValueChange = (name, value) => {
    setFilterValues({ ...filterValues, [name]: value });
  };
  const handleFilterSelection = (name, value) => {
    let valuesObj = { ...filterValues };
    valuesObj[value] = "";
    setFilterValues(valuesObj);
    const advancedFilters = advancedFilterList.filter(
      (singleAdvancedFilter) =>
        !valuesObj.hasOwnProperty(singleAdvancedFilter.value)
    );
    setAdvancedFilterList(advancedFilters);
    setAddNewAdvancedFilter(false);
  };
  const removeFilter = (removedKey) => {
    const { [removedKey]: _, ...rest } = filterValues;
    setFilterValues(rest);
    let advancedFilters = [
      ...advancedFilterList,
      ...defaultAdvancedFilters.filter(
        (singleDefaultFilter) => singleDefaultFilter.value === removedKey
      ),
    ];
    setAdvancedFilterList(advancedFilters);
  };
  return (
    <Fragment>
      <div>
        <div className="card-header border-0 pt-6 listing-component">
          <h1 className="d-flex align-items-center my-1 theme-font-inter-medium">
            {ListingHeaderItems[type].text} {title && title}
          </h1>
          <div className="card-toolbar">
            <div
              className="d-flex justify-content-end"
              data-kt-user-table-toolbar="base"
            >
              {importExportDetail &&
                importExportDetail.allow &&
                userRole === "BUSINESS_ADMIN" && (
                  <>
                    <ButtonComponent
                      text={`import_${type}`}
                      onClick={importExportDetail.importFunc}
                      variant="blue-1"
                      loading={
                        importExportDetail.loading &&
                        importExportDetail.loaderType === "import"
                          ? true
                          : false
                      }
                      disabled={
                        importExportDetail.loading &&
                        importExportDetail.loaderType === "import"
                          ? true
                          : false
                      }
                    />
                    <ButtonComponent
                      text={`export_${type}`}
                      onClick={importExportDetail.exportFunc}
                      variant="blue-1 mx-3"
                      loading={
                        importExportDetail.loading &&
                        importExportDetail.loaderType === "export"
                          ? true
                          : false
                      }
                      disabled={
                        importExportDetail.loading &&
                        importExportDetail.loaderType === "export"
                          ? true
                          : false
                      }
                    />
                  </>
                )}
              {addItem && (
                <ButtonComponent
                  text={ListingHeaderItems[type].button_text}
                  onClick={addItem}
                  variant="blue-1"
                  loading={false}
                  disabled={false}
                />
              )}
              {displayType && (
                <div className="list-grid-views">
                  <img
                    className="cursor-pointer"
                    onClick={() => setDisplayType("list")}
                    src={displayType === "list" ? ActiveList : InActiveList}
                    alt="Listing"
                  />
                  <img
                    className="cursor-pointer"
                    onClick={() => setDisplayType("grid")}
                    src={displayType === "grid" ? ActiveGrid : InActiveGrid}
                    alt="Grid"
                  />
                  {type === "schedule_listing" && (
                    <img
                      className="cursor-pointer"
                      onClick={() => setDisplayType("calendar")}
                      src={CalendarView}
                      alt="calendar"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* 
        <div className="p-4 d-flex justify-content-between align-items-center flex-column gap-3">
          <div className="gap-3 d-flex flex-wrap w-100">
            <div>
              <TextComponent
                name="search"
                placeholder="search"
                label=""
                value={values.search ?? ""}
                setValue={handleValueChange}
                classes={{}}
              />
            </div>
            <div>
              <SelectComponent
                name="status"
                value={values.status ?? ""}
                label=""
                onChange={handleValueChange}
                classes={{}}
                optionsList={[
                  { displayValue: "Status", value: "" },
                  { displayValue: "Draft", value: "draft" },
                  { displayValue: "Published", value: "published" },
                ]}
              />
            </div>
            <div>
              <SelectComponent
                name="category"
                value={values.category ?? ""}
                onChange={handleValueChange}
                label=""
                classes={{}}
                optionsList={[
                  { displayValue: "Category", value: "" },
                  { displayValue: "Category 1", value: "cat1" },
                  { displayValue: "Category 2", value: "cat2" },
                ]}
              />
            </div>
            <div className="advanced-filters flex-1">
              <span
                onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
              >
                {showAdvancedFilters && "Close "}Advanced Filters
              </span>
            </div>
          </div>
          <div className="w-100 d-flex flex-column advanced-filters-list">
            {Object.keys(filterValues).map((singleFilterKey, index) => (
              <div className="d-flex gap-5 align-items-center" key={index}>
                <span>
                  {
                    defaultAdvancedFilters.filter(
                      (singleFilter) => singleFilter.value === singleFilterKey
                    )[0].displayValue
                  }
                </span>
                <div>
                  {singleFilterKey === "competency" && (
                    <SelectComponent
                      name={singleFilterKey}
                      label=""
                      value={filterValues[singleFilterKey] ?? ""}
                      onChange={handleFilterValueChange}
                      classes={{}}
                      optionsList={CompetencyLevels}
                    />
                  )}
                </div>
                <div className="d-flex gap-2">
                  {!addNewAdvancedFilter &&
                    advancedFilterList.length > 1 &&
                    index + 1 === Object.keys(filterValues).length && (
                      <img
                        className="cursor-pointer"
                        onClick={() => setAddNewAdvancedFilter(true)}
                        src={AddFilterImage}
                        alt="add new filter"
                      />
                    )}
                  <img
                    onClick={() => removeFilter(singleFilterKey)}
                    className="cursor-pointer"
                    src={DeleteFilterImage}
                    alt="delete this filter"
                  />
                </div>
              </div>
            ))}
          </div>
          {showAdvancedFilters && (
            <div className="gap-3 d-flex flex-wrap w-100">
              {addNewAdvancedFilter && advancedFilterList.length > 1 && (
                <div>
                  <SelectComponent
                    name="advancedFilter"
                    label=""
                    value={values.category ?? ""}
                    onChange={handleFilterSelection}
                    classes={{}}
                    optionsList={advancedFilterList}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      */}
        {children}
      </div>
    </Fragment>
  );
}
ListingHeaderComponent.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  displayType: PropTypes.string.isRequired,
  setDisplayType: PropTypes.func.isRequired,
  addItem: PropTypes.oneOfType([PropTypes.func, () => null]).isRequired,
  children: PropTypes.any,
  importExportDetail: PropTypes.object,
};
export default ListingHeaderComponent;
