import React from "react";
import PropTypes from "prop-types";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";
import EditIcon from "assets/images/form/edit-icon.svg";
import ButtonComponent from "components/ButtonComponent";

function SubscriptionActions({ props, data }) {
  const userRole = useRecoilValue(getUserRole);
  const editSubscription = () => {
    props.setEditId(data.id);
  };
  const cancelSubscription = () => {
    props.cancelSubscriptionAlert(data.id);
  };
  const cancelAuoRenewal = () => {
    props.cancelAutoRenewalAlert(data.id);
  };

  return (
    <>
      {userRole === "SUPER_ADMIN" ? (
        <div className="d-flex gap-3 table-actions">
          <img onClick={editSubscription} src={EditIcon} alt="edit-icon" />
        </div>
      ) : userRole === "STUDENT" ? (
        <div className="d-flex gap-3 table-actions">
          {data.is_auto_renewal && (
            <ButtonComponent
              text="cancel_auto_renewal"
              variant="orange-1"
              onClick={cancelAuoRenewal}
              loading={false}
              disabled={data.request_status === "PENDING"}
            />
          )}
          <ButtonComponent
            text="cancel_subscription"
            variant="blue-1"
            onClick={cancelSubscription}
            loading={false}
            disabled={false}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
SubscriptionActions.propTypes = {
  data: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};
export default SubscriptionActions;
