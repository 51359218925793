import React from "react";
import PropTypes from "prop-types";
import TableUtils from "actions/TableUtils";
import { useTranslation } from "react-i18next";

function TableComponent({ data, header, type, props }) {
  const { t } = useTranslation();
  return (
    <div className="table-responsive">
      <table
        // id="kt_table_users"
        className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
      >
        <thead>
          <tr className="fw-bolder text-uppercase">
            {header.map((singleHeaderField, headerIndex) => {
              return (
                <td key={headerIndex}>
                  {t("tableHeaders." + singleHeaderField.displayValue)}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody className="">
          {data.map((singleRecordDetail, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {header.map((singleHeaderField, columnIndex) => {
                  let value = singleHeaderField.key
                    .split(".")
                    .reduce((acc, curr) => acc[curr], singleRecordDetail);
                  return (
                    <td key={columnIndex}>
                      {singleHeaderField.callbackFunction
                        ? singleHeaderField.key !== ""
                          ? TableUtils[singleHeaderField.callbackFunction](
                              value
                            )
                          : TableUtils[singleHeaderField.callbackFunction](
                              singleHeaderField.functionKey,
                              singleRecordDetail
                            )
                        : value}
                    </td>
                  );
                })}
                <td>
                  {TableUtils.displayActions(type, singleRecordDetail, props)}
                </td>
              </tr>
            );
          })}
          {data.length === 0 && (
            <tr>
              <td colSpan={header.length}>
                <div className="d-flex text-center w-100 align-content-center justify-content-center">
                  No matching records found
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
TableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  header: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  props: PropTypes.object.isRequired,
};
export default TableComponent;
