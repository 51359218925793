import React, { useState } from "react";
import TabsComponent from "components/TabsComponent";
import { SuperAdminSettingTabs } from "./utils";
import AuthSettings from "./AuthSettings";
import RewardsConversion from "./RewardsConversion";

function SuperAdminSettings() {
  const [selectedTab, setSelectedTab] = useState(1);
  return (
    <div className="card">
      <div
        className="card-body custom-form"
        id="custom-form"
        style={{ "--noOfColumn": 3 }}
      >
        <TabsComponent
          tabs={SuperAdminSettingTabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        >
          <div className="mx-auto w-100 pt-15 pb-10 form-details">
            {selectedTab === 1 ? (
              <AuthSettings />
            ) : selectedTab === 2 ? (
              <RewardsConversion />
            ) : (
              <></>
            )}
          </div>
        </TabsComponent>
      </div>
    </div>
  );
}

export default SuperAdminSettings;
