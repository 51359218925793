import React, { useState } from "react";
import PropTypes from "prop-types";
import TextComponent from "./TextComponent";
import ButtonComponent from "./ButtonComponent";
import CloseIcon from "assets/images/general_old/close.svg";
import MultipleFileDropZoneComponent from "./MultipleFileDropZoneComponent";
import { CallAPI } from "actions/General";
import { useTranslation } from "react-i18next";

const objectKeyName = { tags: "title", learning_points: "text", urls: "text" };

function ItemListingComponent({
  type,
  items,
  updateItems,
  error,
  extensions = [],
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [tags, setTags] = useState([]);
  const updateFormValues = async (name, itemValue) => {
    setValue(itemValue);
    // if (type === "tags" && itemValue.length === 3) {
    //   let tagsList = await CallAPI(
    //     "FETCH_ALL_TAGS",
    //     { search: itemValue },
    //     null,
    //     null,
    //     null,
    //     null
    //   );
    //   if (tagsList.status) {
    //     setTags(tagsList.data.tags);
    //   }
    // }
  };
  const addItemToList = () => {
    let addedValue = { [objectKeyName[type]]: value, id: null };
    updateItems(type, [addedValue, ...items]);
    setValue("");
    if (type === "tags") setTags([]);
  };
  const removeItemFromList = (index) => {
    items.splice(index, 1);
    updateItems(type, items);
  };
  const tagValueSelected = (id, title) => {
    let addedValue = { [objectKeyName[type]]: title, id: id };
    updateItems(type, [addedValue, ...items]);
    setTags([]);
    setValue("");
  };
  return (
    <section className={`item-detail-section`}>
      {type !== "upload_csv" && (
        <header>{type !== "" ? t("itemListing.header_" + type) : ""}</header>
      )}
      <div className={`detail `}>
        {type !== "upload_csv" && (
          <div className="form-label mb-3">
            <span>{type !== "" ? t("itemListing." + type) : ""}</span>
          </div>
        )}
        <div className="form mb-4 d-flex flex-column gap-1">
          <div className="d-flex gap-3 w-100 box-width position-relative">
            {type === "documents" || type === "upload_csv" ? (
              <MultipleFileDropZoneComponent
                name={"documents"}
                label={""}
                value={items}
                setValue={updateItems}
                classes={""}
                type={"document"}
                required={false}
                extensions={extensions}
              />
            ) : (
              <>
                <TextComponent
                  name={"title"}
                  label={""}
                  placeholder={
                    type === "learning_points"
                      ? t("add_learning_point")
                      : type === "tags"
                      ? t("add_tag")
                      : type === "urls"
                      ? t("add_url")
                      : ""
                  }
                  value={value}
                  setValue={updateFormValues}
                  classes={""}
                  required={false}
                />
                {type === "tags" && tags.length > 0 && (
                  <div className="tag-list">
                    {tags &&
                      tags.map((singleTag, index) => (
                        <span
                          key={index}
                          onClick={() =>
                            tagValueSelected(singleTag.id, singleTag.title)
                          }
                        >
                          {singleTag.title}
                        </span>
                      ))}
                  </div>
                )}
                <div>
                  <ButtonComponent
                    text="add"
                    onClick={addItemToList}
                    variant="blue-1"
                    loading={false}
                    disabled={value.length === 0}
                  />
                </div>
              </>
            )}
          </div>
          {error && <span className="error">{error}</span>}{" "}
        </div>
        {type !== "documents" && (
          <div className={`listing-item d-flex flex-wrap ${type}`}>
            {items.map((singleItem, index) => {
              return (
                <span className={type} key={index}>
                  {type === "documents"
                    ? singleItem.name
                    : singleItem[objectKeyName[type]]}
                  <img
                    src={CloseIcon}
                    alt="close icon"
                    className="close"
                    onClick={() => removeItemFromList(index)}
                  />
                </span>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
}
ItemListingComponent.propTypes = {
  error: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  updateItems: PropTypes.func.isRequired,
  extensions: PropTypes.array,
};
export default ItemListingComponent;
