import { CallAPI } from "actions/General";
import ErrorComponent from "components/ErrorComponent";
import LoaderComponent from "components/LoaderComponent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DisplayHtml from "views/DisplayHtml";
import AmericaImage from "assets/images/flags/america_flag.png";
import CanadaImage from "assets/images/flags/canada_flag.png";
import NigeriaImage from "assets/images/flags/nigerian_flag.png";
import SelectComponentWithImage from "components/SelectComponentWithImage";
import Payment from "views/payment/Payment";

function AddSubscription() {
  const { t } = useTranslation();
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [subscriptionsViewType, setSubscriptionsViewType] = useState("monthly");
  const [selectedCurrency, setSelectedCurrency] = useState("usd");
  const [apiError, setApiError] = useState("");
  const [loader, setLoader] = useState(false);
  const getAllSubscriptionsList = async () => {
    const result = await CallAPI(
      "FETCH_SUBSCRIPTIONS_LIST",
      null,
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setSubscriptionsList(result.data);
    }
  };
  useEffect(() => {
    getAllSubscriptionsList();
  }, []);
  return (
    <>
      <div className="card subscriptions">
        {loader && <LoaderComponent classes={"mx-4"} />}
        {apiError && <ErrorComponent text={apiError} variant="danger" />}
        <h1 className="m-5 mb-0">Add Subscription</h1>
        <hr />
        <div className="d-flex justify-content-center gap-5 align-items-center mt-5">
          <SelectComponentWithImage
            optionsList={[
              {
                value: "usd",
                displayValue: " | US Dollar (USD)",
                img: AmericaImage,
              },
              {
                value: "cad",
                displayValue: " | Canadian Dollar (CAD)",
                img: CanadaImage,
              },
              {
                value: "ngn",
                displayValue: " | Nigerian Naira (NGN)",
                img: NigeriaImage,
              },
            ]}
            name="currency"
            label=""
            value={selectedCurrency}
            onChange={(name, value) => setSelectedCurrency(value)}
            required
            classes=""
          />

          <div className="select-type mb-5">
            <span
              onClick={() => setSubscriptionsViewType("monthly")}
              className={`${
                subscriptionsViewType === "monthly" ? "active" : ""
              }`}
            >
              Monthly
            </span>
            <span
              onClick={() => setSubscriptionsViewType("yearly")}
              className={`${
                subscriptionsViewType === "yearly" ? "active" : ""
              }`}
            >
              Yearly
            </span>
          </div>
        </div>
        <div className="subscription-items">
          {subscriptionsList &&
            subscriptionsList.map((singleSubscription, index) => {
              return (
                <div className="item" key={index}>
                  <div className="item_details">
                    <span className="title">{singleSubscription.title}</span>
                    <span className="credits">
                      {singleSubscription[subscriptionsViewType + "_credits"]}
                      <span>{t("texts.credits_earned")}</span>
                    </span>
                    <span className="subscription_detail">
                      <DisplayHtml html={singleSubscription.detail} />
                    </span>
                  </div>
                  <Payment
                    recordId={singleSubscription.id}
                    price={
                      singleSubscription[
                        subscriptionsViewType +
                          "_" +
                          selectedCurrency +
                          "_price"
                      ]
                    }
                    type="subscription"
                    currency={selectedCurrency}
                    period={subscriptionsViewType}
                    otherDetails={{}}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default AddSubscription;
