import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SelectComponent from "components/SelectComponent";
import ButtonComponent from "components/ButtonComponent";
import scheduleCalenderIcon from "../../assets/images/general/schedule-calender.png";
import cancelIcon from "../../assets/images/general/cancel.png";
import { useNavigate } from "react-router-dom";
import CustomModal from "components/CustomModal";
import { ModalButtons } from "./utils";
import { CallAPI } from "actions/General";

function ChooseProgSchedule({
  values,
  errors,
  updateFormValues,
  otherDetails,
}) {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVariant, setModalVariant] = useState("");
  const [modalType, setModalType] = useState("");
  const [loaderType, setLoaderType] = useState("");
  const [ApiError, setApiError] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedCourse) {
      const course = otherDetails.program_courses_schedules.find(
        (item) => item.course_id === selectedCourse
      );

      if (course && course.course_schedules.length === 0) {
        setSelectedSchedule("not_found");
      }
    }
  }, [selectedCourse]);

  useEffect(() => {
    console.log(selectedSchedule);
  }, [selectedSchedule]);

  const handleCourseChange = (name, value) => {
    setSelectedCourse(value);
    setSelectedSchedule(null);
  };
  const handleScheduleChange = (name, value) => setSelectedSchedule(value);
  const addSchedule = () => {
    if (selectedCourse && selectedSchedule) {
      const selectedCourseData = otherDetails.program_courses_schedules.find(
        (course) => course.course_id === selectedCourse
      );

      const newEntry = {
        course_id: selectedCourse,
        course_title: selectedCourseData.course_title,
        schedule_id: selectedSchedule,
        schedule_title: `${
          selectedCourseData.course_schedules.find(
            (schedule) => schedule.schedule_id === selectedSchedule
          ).season_name
        } ${
          selectedCourseData.course_schedules.find(
            (schedule) => schedule.schedule_id === selectedSchedule
          ).year
        }`,
      };

      if (
        !values.courses_schedules.some(
          (item) =>
            item.course_id === newEntry.course_id &&
            item.schedule_id === newEntry.schedule_id
        )
      ) {
        updateFormValues("courses_schedules", [
          ...values.courses_schedules,
          newEntry,
        ]);
        resetSelection();
      }
    }
  };

  const handleSaveDraft = () => {
    setIsModalOpen(true);
    setModalVariant("confirm");
    setModalType("confirmDraft");
  };

  const saveDraft = async () => {
    setLoaderType("Saving Draft");

    const scheduleDetail = {
      title: values.title,
      teacher_id: values.teacher,
      auto_generate_link: values.auto_generate_link.toString() === "1",
      link: values.link,
      auto_upload_session: values.auto_upload_session.toString() === "1",
      type: values.type,
      program_id: values.type === "PROGRAM" ? values.program_id : null,
      course_id: values.type === "COURSE" ? values.course_id : null,
      description: values.description,
      department: parseInt(values.department),
      profile: parseInt(values.profile),
      role: parseInt(values.role),
      year: parseInt(values.year),
      season_id: values.season_id,
      duration: parseInt(values.duration),
      duration_type: values.duration_type,
      schedule_date: parseInt(values.schedule_date / 1000),
      cancellation_date: parseInt(values.cancellation_date / 1000),
      enrollment_end_date: parseInt(values.enrollment_end_date / 1000),
      courses_schedules: values.courses_schedules.map((singleItem) => {
        return {
          course_id: singleItem.course_id,
          schedule_id: singleItem.schedule_id,
        };
      }),
    };
    const APIName = otherDetails.updatedRecordId?"EDIT_DRAFT_SCHEDULE":"ADD_DRAFT_SCHEDULE";
    let result = await CallAPI(
      APIName,
      scheduleDetail,
      otherDetails.updatedRecordId ?otherDetails.updatedRecordId :null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setIsModalOpen(true);
      setModalVariant("success");
      setModalType("draftSaved");
    }else{
      setApiError(result.error)
    }
  };

  const draftSaved = () => {
    setIsModalOpen(false);
    navigate("/add_missing_schedule", { state: { courseId: selectedCourse } });
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const resetSelection = () => {
    setSelectedCourse(null);
    setSelectedSchedule(null);
  };

  const handleRemoveItem = (course_id, schedule_id) => {
    const updatedSchedules = values.courses_schedules.filter(
      (item) =>
        !(item.course_id === course_id && item.schedule_id === schedule_id)
    );
    updateFormValues("courses_schedules", updatedSchedules);
  };

  const nullItem = { value: "", displayValue: "Select" };

  const courseOptions = [
    nullItem,
    ...otherDetails.program_courses_schedules
      .filter(
        (course) =>
          !values.courses_schedules.some(
            (scheduledCourse) => scheduledCourse.course_id === course.course_id
          )
      )
      .map((course) => ({
        value: course.course_id,
        displayValue: course.course_title,
      })),
  ];

  const courseSchedules =
    otherDetails.program_courses_schedules.find(
      (course) => course.course_id === selectedCourse
    )?.course_schedules || [];

  const scheduleOptions =
    selectedCourse && courseSchedules.length > 0
      ? [
          nullItem,
          ...courseSchedules.map((schedule) => ({
            value: schedule.schedule_id,
            displayValue: `${schedule.season_name} ${schedule.year}`,
          })),
        ]
      : [];

  return (
    <div className="schedule-container">
      <div className="heading">
        <img src={scheduleCalenderIcon} alt="schedule-icon" />
        <h4>Select Schedule for Program Course</h4>
      </div>

      <div className="selected-courses-list">
        <table className="table ">
          {values.courses_schedules.map((entry, index) => (
            <tr key={index} className="selected-course-item border">
              <td className="border-0 ">{entry.course_title}</td>
              <td className="border-0">{entry.schedule_title}</td>
              <td
                className="border-0 text-end"
                onClick={() =>
                  handleRemoveItem(entry.course_id, entry.schedule_id)
                }
              >
                <img
                  className="cursor-pointer"
                  src={cancelIcon}
                  alt="cancel-icon"
                />
              </td>
            </tr>
          ))}
        </table>
      </div>

      <div className="select-row">
        <div className="select-label">
          <SelectComponent
            name="course_id"
            label="select_course"
            value={selectedCourse}
            classes="select-component w-100"
            onChange={handleCourseChange}
            optionsList={courseOptions}
            required
          />
        </div>
        <div className="select-label">
          <SelectComponent
            name="schedule_id"
            label="select_schedule"
            value={selectedSchedule}
            classes="select-component w-100"
            onChange={handleScheduleChange}
            optionsList={
              scheduleOptions.length > 0
                ? scheduleOptions
                : [{ value: "", displayValue: "No Schedule Found" }]
            }
            required
          />
        </div>

        {selectedSchedule == "not_found" && selectedCourse ? (
          <ButtonComponent
            text="add_schedule"
            onClick={handleSaveDraft}
            variant="red-2"
            className="add-schedule-button"
          />
        ) : (
          <ButtonComponent
            text="add_schedule_to_list"
            onClick={addSchedule}
            variant="red-2"
            disabled={!selectedCourse || !selectedSchedule}
            className="add-schedule-button "
          />
        )}
      </div>
      {isModalOpen && (
        <CustomModal
          header={""}
          text={ModalButtons[modalType].text}
          buttons={ModalButtons[modalType].buttons.map((button) => {
            return {
              text: button.text,
              variant: button.variant,
              onClick: button.onClick,
              loading:
                button["loading"] !== undefined ? button.loading : loader,
              disabled:
                button["loading"] !== undefined ? button.disabled : loader,
            };
          })}
          onClose={closeModal}
          variant={modalVariant}
          functionsList={{ closeModal, saveDraft, draftSaved }}
        />
      )}
    </div>
  );
}

ChooseProgSchedule.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  otherDetails: PropTypes.object.isRequired,
  updateFormValues: PropTypes.func.isRequired
};

export default ChooseProgSchedule;
