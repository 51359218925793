import React, { useState } from "react";
import PropTypes from "prop-types";
import RadioComponent from "components/RadioComponent";
import TextComponent from "components/TextComponent";
import ButtonComponent from "components/ButtonComponent";
import CloseImage from "assets/images/general_old/close.svg";
import { useTranslation } from "react-i18next";
import { CallAPI } from "actions/General";

function DiscountModal({
  setDiscountType,
  setCouponCode,
  setGiftCard,
  discountType,
  couponCode,
  giftCard,
  price,
  onClose,
  getPaymentIntent,
  setApiError,
  loader,
  type,
}) {
  const [discountPrice, setDiscountPrice] = useState(0);
  const [discountLoader, setDiscountLoader] = useState(false);
  const [discountLoaderType, setDiscountLoaderType] = useState("");
  const { t } = useTranslation();
  const onChange = (name, value) => {
    setDiscountType(name);
    setCouponCode("");
    setGiftCard("");
    setDiscountPrice(0);
  };
  const onChangeCoupon = (name, value) => {
    setCouponCode(value);
  };
  const onChangeGiftCard = (name, value) => {
    setGiftCard(value);
  };

  const applyDiscount = async () => {
    setDiscountLoaderType("apply_coupon");
    const result = await CallAPI(
      "GET_COUPON_DETAILS",
      {},
      couponCode,
      setDiscountLoader,
      setApiError,
      null
    );
    if (result.status) {
      if (result.data.type === "percentage") {
        const percentVal = (price / 100) * result.data.value;
        setDiscountPrice(percentVal);
      } else {
        setDiscountPrice(result.data.value);
      }
      setDiscountLoaderType("");
    } else {
      setDiscountPrice(0);
    }
  };
  return (
    <div className={`custom-modal subscription`}>
      <div className="detail">
        <img
          className="close"
          src={CloseImage}
          onClick={() => onClose("")}
          alt="close"
        />
        <span className="header">{t("modal.purchase_subscription")}</span>
        <div className="items">
          <div className="item">
            <RadioComponent
              name={"coupon_code"}
              label={"apply_coupon_code"}
              value={discountType === "coupon_code"}
              onChange={onChange}
              classes={{ labelClass: "ps-3 text-black" }}
              error=""
            />
            {discountType === "coupon_code" && (
              <div className="code">
                <TextComponent
                  name="couponCode"
                  value={couponCode}
                  setValue={onChangeCoupon}
                  label=""
                  placeholder="coupon_code"
                  classes={"small flex-1"}
                  error={""}
                  required={false}
                />
                <div className="">
                  <ButtonComponent
                    text="apply"
                    variant="blue-1 "
                    onClick={applyDiscount}
                    loading={
                      discountLoaderType === "apply_coupon" && discountLoader
                    }
                    disabled={
                      discountLoaderType === "apply_coupon" && discountLoader
                    }
                    classes={{}}
                  />
                </div>
              </div>
            )}
          </div>
          {/* <div className="item">
            <RadioComponent
              name={"gift_code"}
              label={"apply_gift_card"}
              value={discountType === "gift_code"}
              onChange={onChange}
              classes={{ labelClass: "ps-3 text-black" }}
              error=""
            />
            {discountType === "gift_code" && (
              <div className="code">
                <TextComponent
                  name="giftCard"
                  value={giftCard}
                  setValue={onChangeGiftCard}
                  label=""
                  placeholder="gift_card"
                  classes={"small w-100"}
                  error=""
                  required={false}
                />
                <div>
                  <ButtonComponent
                    text="apply"
                    variant="blue-1 w-auto"
                    onClick={applyDiscount}
                    loading={false}
                    disabled={giftCard === ""}
                    classes={{}}
                  />
                </div>
              </div>
            )}
          </div> */}
          <div className="item">
            <RadioComponent
              name={"none"}
              label={"apply_none"}
              value={discountType === "none"}
              onChange={onChange}
              classes={{ labelClass: "ps-3 text-black" }}
              error=""
            />
          </div>
        </div>

        <div className="d-flex gap-3 flex-column text-black">
          <span>
            <strong>Total Price:</strong> {price}
          </span>
          <span>
            <strong>Discount Price:</strong> {discountPrice}
          </span>
        </div>
        <div className="d-flex gap-3 justify-content-center">
          <div>
            <ButtonComponent
              text={
                type === "subscription"
                  ? "stripe_purchase"
                  : "stripe_purchase_enroll"
              }
              variant={"orange-1"}
              onClick={getPaymentIntent}
              langKey="price"
              langVal={parseFloat(parseFloat(price - discountPrice).toFixed(2))}
              loading={loader}
              disabled={loader}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
DiscountModal.propTypes = {
  setDiscountType: PropTypes.func.isRequired,
  setCouponCode: PropTypes.func.isRequired,
  setGiftCard: PropTypes.func.isRequired,
  discountType: PropTypes.string.isRequired,
  couponCode: PropTypes.string.isRequired,
  giftCard: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  getPaymentIntent: PropTypes.func.isRequired,
  setApiError: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};
export default DiscountModal;
