import { type } from "@testing-library/user-event/dist/type"

export const status = [
    { value: '', displayValue: "Status" },
    { value: false, displayValue: "non active" },
    { value: true , displayValue: "active" },
]

export const isFree = [
    { value:'', displayValue:"Is Free"},
    { value: false, displayValue: "no" },
    { value: true , displayValue: "yes" },
]

export const filterSelectionOptions = [
    { value: "", displayValue: "Select Filter", type:['all'] },
    { value: "published_between", displayValue: "Published Between", type:['program','course', 'module','unit']},
    { value: "subscription", displayValue: "Subscription", type:['program','course']},
    { value: "paymentType", displayValue: "Payment Type", type:['program','course']},
    { value: "category", displayValue: "Category", type:['program','course']},
    { value: "tag", displayValue: "Tags", type:['program','course','module','unit']  }
]

export const paymentType = [
    { value: "", displayValue: "Select Payment Type" },
    { value: "PAY_AS_STRIPE", displayValue: "Stripe" },
    { value: "PAY_AS_CREDITS", displayValue: "Credits" },
    { value: "BOTH", displayValue: "Both" }
]