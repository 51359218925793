import ButtonComponent from "components/ButtonComponent";
import TabsComponent from "components/TabsComponent";
import React, { useEffect, useState } from "react";
import { AddProgramTabs, ModalButtons } from "./utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getArrayDifference,
  handlePageResize,
  UploadMedia,
} from "config/config";
import { CallAPI } from "actions/General";
import {
  BasicDetailsSchema,
  CertificationSchema,
  CoursesListSchema,
  NotesModulesSchema,
  TagsLearnHighlightsSchema,
} from "./FieldValidationRoles";
import LoaderComponent from "components/LoaderComponent";
import BasicDetails from "./BasicDetails";
import TagsLearnHighlights from "./TagsLearnHighlights";
import Preview from "./Preview";
import Certification from "./Certification";
import ErrorComponent from "components/ErrorComponent";
import CustomModal from "components/CustomModal";
import ProgramCourses from "./ProgramCourses";

function AddProgram() {
  const location = useLocation();
  const navigate = useNavigate();

  const [listingDetails, setListingDetails] = useState({
    subscriptions: [],
    categories: [],
    programs: [],
    isFree: [],
    paymentType: [],
  });
  const [selectedTab, setSelectedTab] = useState(1);
  const [updatedRecordId, setUpdatedRecordId] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [ApiError, setApiError] = useState("");
  const [values, setValues] = useState({
    title: "",
    category_id: "",
    subscription: "",
    language: "",
    is_free: "",
    reward_credits: "",
    enroll_credits: "",
    author_name: "",
    pre_req_courses: [],
    compulsory_courses: [],
    elective_courses: [],
    payment_type: "",
    completion_duration: "",
    duration_type: "",
    short_description: "",
    detail: "",
    banner: null,
    tags: [],
    learning_points: [],
    completed_percentage: "",
    quiz_percentage: "",
    validity_period: "",
    validity_type: "",
    cert_description: "",
  });
  const [errors, setErrors] = useState({});
  const [rowItems, setRowItems] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVariant, setModalVariant] = useState("");
  const [modalType, setModalType] = useState("");

  const getProgramDetails = async () => {
    if (updatedRecordId) {
      setLoaderType("get_details");
      let result = await CallAPI(
        "GET_PROGRAM_DETAIL",
        null,
        updatedRecordId,
        setLoader,
        null,
        null
      );
      if (result.status) {
        let programObj = result.data;
        let prevValues = {
          title: programObj.title,
          subscription: programObj.subscription
            ? programObj.subscription.id
            : "",
          reward_credits: programObj.reward_credits,
          enroll_credits: programObj.enroll_credits,
          category_id: programObj.category.id,
          language: programObj.language,
          detail: programObj.description,
          is_free: programObj.is_free ? "1" : "0",
          short_description: programObj.short_description,
          completion_duration: programObj.completion_duration,
          duration_type: programObj.duration_type,
          pre_req_courses: programObj.course_pre_requisites
            ? programObj.course_pre_requisites.map((singlePreReq) => {
                return { id: singlePreReq.id, title: singlePreReq.title };
              })
            : [],
          compulsory_courses: programObj.compulsory_courses
            ? programObj.compulsory_courses.map((singlePreReq) => {
                return { id: singlePreReq.id, title: singlePreReq.title };
              })
            : [],
          elective_courses: programObj.elective_courses
            ? programObj.elective_courses.map((singlePreReq) => {
                return { id: singlePreReq.id, title: singlePreReq.title };
              })
            : [],
          completed_percentage:
            programObj.certificate_criteria.completed_percentage,
          quiz_percentage: programObj.certificate_criteria.quiz_percentage,
          validity_period: programObj.certificate_criteria.validity_period,
          validity_type: programObj.certificate_criteria.validity_type,
          cert_description: programObj.certificate_criteria.description,
          payment_type: programObj.payment_type,
          author_name: programObj.author_name,
          banner: programObj.banner_image
            ? { id: programObj.banner_image.id }
            : null,
          tags: programObj.tags.map((singleTag) => {
            return { id: singleTag.id, title: singleTag.title };
          }),
          learning_points: programObj.learning_points.map(
            (singleLearningPoint) => {
              return {
                id: singleLearningPoint.id,
                text: singleLearningPoint.title,
              };
            }
          ),
          program: programObj,
        };
        setValues({
          ...prevValues,
        });
      }
    }
  };

  const getRequiredDataForForm = async () => {
    const details = {
      categories: [
        {
          value: "",
          displayValue: "Select",
        },
      ],
      programs: [
        {
          value: "",
          displayValue: "Select",
        },
      ],
      subscriptions: [
        {
          value: "",
          displayValue: "Select",
        },
      ],
      isFree: [
        {
          value: "",
          displayValue: "Select",
        },
        {
          value: "1",
          displayValue: "True",
        },
        {
          value: "0",
          displayValue: "False",
        },
      ],
      paymentType: [
        {
          value: "",
          displayValue: "Select",
        },
        {
          value: "PAY_AS_STRIPE",
          displayValue: "Pay via Stripe/PayStack",
        },
        {
          value: "PAY_AS_CREDITS",
          displayValue: "Pay Credits",
        },
        {
          value: "BOTH",
          displayValue: "BOTH",
        },
      ],
    };
    const categoriesList = await CallAPI(
      "FETCH_ALL_CATEGORIES",
      {},
      null,
      null,
      null,
      null
    );
    if (categoriesList.status) {
      const allCategories = categoriesList.data.categories.map(
        (singleCategory) => {
          return {
            value: singleCategory.id,
            displayValue: singleCategory.title,
          };
        }
      );
      details.categories = [...details.categories, ...allCategories];
    }
    const subscriptionPlanList = await CallAPI(
      "GET_ALL_SUBSCRIPTION_PLANS",
      {},
      null,
      null,
      null,
      null
    );
    if (subscriptionPlanList.status) {
      let allSubscriptionPlans = subscriptionPlanList.data.subscriptions.map(
        (singleSubscriptionPlan) => {
            return {
              value: singleSubscriptionPlan.id,
              displayValue: singleSubscriptionPlan.title,
            };
        }
      );
      allSubscriptionPlans = allSubscriptionPlans.filter(Boolean);
      details.subscriptions = [
        ...details.subscriptions,
        ...allSubscriptionPlans,
      ];
    }

    setListingDetails(details);
  };

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.update_id &&
      location.state.update_id !== ""
    )
      setUpdatedRecordId(location.state.update_id);

    getRequiredDataForForm();
  }, [location]);
  useEffect(() => {
    if (updatedRecordId !== "");
    getProgramDetails();
  }, [updatedRecordId]);

  const updateFormValues = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  const previousClick = () => {
    if (selectedTab === 1) {
      navigate("/program");
    } else {
      setSelectedTab(selectedTab - 1);
    }
  };

  const selectOtherTab = async (tabToSelect) => {
    // let startTab = 1;
    // try {
    //   while (startTab < tabToSelect) {
    //     const errorValidatorSchema =
    //       startTab === 1
    //         ? BasicDetailsSchema
    //         : startTab === 2
    //         ? TagsLearnHighlightsSchema
    //         : startTab === 4
    //         ? CertificationSchema
    //         : null;
    //     if (errorValidatorSchema)
    //       await errorValidatorSchema.validate(values, { abortEarly: false });
    //     startTab++;
    //   }
    //   setSelectedTab(startTab);
    // } catch (error) {
    //   const schemaErrors = {};
    //   error.inner?.forEach((err) => {
    //     schemaErrors[err.path] = err.message;
    //   });
    //   setErrors(schemaErrors);
    //   setSelectedTab(startTab);
    // }
  };

  function findCommonElement(array1, array2, key = "id") {
    for (let i = 0; i < array1.length; i++) {
      for (let j = 0; j < array2.length; j++) {
        if (array1[i][key] === array2[j].id) {
          return true;
        }
      }
    }
    return false;
  }

  const nextClick = async () => {
    if (selectedTab === AddProgramTabs.length) {
      submitForm();
    } else {
      try {
        setErrors({});
        const errorValidatorSchema =
          selectedTab === 1
            ? BasicDetailsSchema
            : selectedTab === 2
            ? TagsLearnHighlightsSchema
            : selectedTab === 3
            ? CoursesListSchema
            : selectedTab === 4
            ? CertificationSchema
            : null;

        if (errorValidatorSchema)
          await errorValidatorSchema.validate(values, { abortEarly: false });

        if (selectedTab === 3) {
          let isAnyCourseConflicts = findCommonElement(
            values.pre_req_courses,
            values.compulsory_courses,
            "version_id"
          );
          if (!isAnyCourseConflicts)
            isAnyCourseConflicts = findCommonElement(
              values.pre_req_courses,
              values.elective_courses,
              "version_id"
            );
          if (!isAnyCourseConflicts)
            isAnyCourseConflicts = findCommonElement(
              values.compulsory_courses,
              values.elective_courses
            );
          if (!isAnyCourseConflicts) setSelectedTab(selectedTab + 1);
          else {
            setIsModalOpen(true);
            setModalType("coursesConflict");
            setModalVariant("error");
          }
        } else setSelectedTab(selectedTab + 1);
      } catch (error) {
        console.log("error", error);
        const schemaErrors = {};
        error.inner?.forEach((err) => {
          schemaErrors[err.path] = err.message;
        });
        setErrors(schemaErrors);
      }
    }
  };
  const submitForm = async () => {
    setLoaderType("submit_form");
    let bannerImage = null;
    if (values.banner && values.banner.id === undefined) {
      bannerImage = await UploadMedia(
        [values.banner],
        [
          {
            name: values.banner.path,
            size: values.banner.size.toString(),
            extension: values.banner.type,
          },
        ]
      );
      bannerImage = bannerImage[0];
    } else if (values.banner && values.banner.id) {
      bannerImage = values.banner.id;
    }

    const programDetail = {
      title: values.title,
      category_id: values.category_id,
      subscription_id: values.subscription ? values.subscription : null,
      language: values.language,
      author_name: values.author_name,
      pre_req_courses: values.pre_req_courses.map((singleProgram, index) => {
        return {
          sequence: index + 1,
          id: singleProgram.version_id,
        };
      }),
      compulsory_courses: values.compulsory_courses.map(
        (singleProgram, index) => {
          return {
            sequence: index + 1,
            id: singleProgram.id,
          };
        }
      ),
      elective_courses: values.elective_courses.map((singleProgram, index) => {
        return {
          sequence: index + 1,
          id: singleProgram.id,
        };
      }),
      payment_type: values.payment_type,
      quiz_url: values.quiz_url,
      short_description: values.short_description,
      reward_credits: parseInt(values.reward_credits),
      enroll_credits: parseInt(values.enroll_credits),
      completion_duration: parseInt(values.completion_duration),
      duration_type: values.duration_type,
      completed_percentage: parseInt(values.completed_percentage),
      quiz_percentage: parseInt(values.quiz_percentage),
      validity_period: parseInt(values.validity_period),
      validity_type: values.validity_type,
      cert_description: values.cert_description,
      banner_image_id: bannerImage,
      description: values.detail,
      is_free: values.is_free === "1",
      tags: {
        added: values?.program?.tags
          ? getArrayDifference(values.tags, values.program.tags)
          : values.tags,
        deleted: values?.program?.tags
          ? getArrayDifference(values.program.tags, values.tags, "string", "id")
          : [],
      },
      learning_points: {
        added: getArrayDifference(
          values.learning_points,
          values?.program?.learning_points
            ? values.program.learning_points
            : [],
          "string",
          "text"
        ),
        deleted: values?.program?.learning_points
          ? getArrayDifference(
              values.program.learning_points,
              values.learning_points,
              "string",
              "id"
            )
          : [],
      },
    };
    console.log("programDetail", programDetail);
    const APIName =
      updatedRecordId && updatedRecordId !== ""
        ? "UPDATE_PROGRAM"
        : "ADD_PROGRAM";
    console.log("programDetail", programDetail);
    let result = await CallAPI(
      APIName,
      programDetail,
      updatedRecordId && updatedRecordId !== "" ? updatedRecordId : null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setIsModalOpen(true);
      setModalVariant("success");
      setModalType(
        updatedRecordId && updatedRecordId !== ""
          ? "recordUpdate"
          : "recordCreated"
      );
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/program");
  };
  const coursesErrorModal = () => {
    setIsModalOpen(false);
  };

  function handleResize() {
    const itemsInSingleRow = handlePageResize();
    setRowItems(itemsInSingleRow);
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {loader && loaderType === "get_details" && <LoaderComponent classes="" />}
      <div className="card">
        <div
          className="card-body custom-form"
          id="custom-form"
          style={{ "--noOfColumn": rowItems }}
        >
          {rowItems && (
            <>
              {ApiError && <ErrorComponent variant="error" text={ApiError} />}
              <TabsComponent
                tabs={AddProgramTabs}
                selectedTab={selectedTab}
                setSelectedTab={selectOtherTab}
                // type={"start"}
              >
                <div className="mx-auto w-100 pt-15 pb-10 form-details">
                  {selectedTab === 1 ? (
                    <BasicDetails
                      errors={errors}
                      values={values}
                      updateFormValues={updateFormValues}
                      otherDetails={{ ...listingDetails }}
                    />
                  ) : selectedTab === 2 ? (
                    <TagsLearnHighlights
                      errors={errors}
                      values={values}
                      updateFormValues={updateFormValues}
                    />
                  ) : selectedTab === 3 ? (
                    <ProgramCourses
                      errors={errors}
                      values={values}
                      updateFormValues={updateFormValues}
                      updatedRecordId={updatedRecordId}
                    />
                  ) : selectedTab === 4 ? (
                    <Certification
                      errors={errors}
                      values={values}
                      updateFormValues={updateFormValues}
                    />
                  ) : selectedTab === 5 ? (
                    <Preview values={values} />
                  ) : (
                    <></>
                  )}
                </div>

                <div className="w-auto d-flex justify-content-end gap-3 px-3">
                  <ButtonComponent
                    text={selectedTab === 1 ? "cancel" : "previous"}
                    onClick={previousClick}
                    variant="grey-1"
                    loading={false}
                    disabled={false}
                  />
                  <ButtonComponent
                    text={
                      selectedTab === AddProgramTabs.length &&
                      updatedRecordId === ""
                        ? "submit"
                        : selectedTab === AddProgramTabs.length &&
                          updatedRecordId !== ""
                        ? "update"
                        : "next"
                    }
                    onClick={nextClick}
                    variant="blue-1"
                    loading={loader && loaderType === "submit_form"}
                    disabled={loader && loaderType === "submit_form"}
                  />
                </div>
              </TabsComponent>
            </>
          )}
        </div>
      </div>
      {isModalOpen && (
        <CustomModal
          header={""}
          text={ModalButtons[modalType].text}
          buttons={ModalButtons[modalType].buttons}
          onClose={
            modalType === "coursesConflict" ? coursesErrorModal : closeModal
          }
          variant={modalVariant}
          functionsList={{ closeModal, coursesErrorModal }}
        />
      )}
    </>
  );
}

export default AddProgram;
