import React from "react";
import PropTypes from "prop-types";
import ButtonComponent from "components/ButtonComponent";
function AutoChargeCancellationRequestActions({ props, data }) {
  const updateRequestStatus = () => {
    // props.updateRequestStatusAlert(data.id);
  };

  return (
    <>
      <div className="d-flex gap-3 table-actions">
        <ButtonComponent
          text="accept"
          variant="green-1"
          onClick={updateRequestStatus}
          loading={false}
          disabled={false}
        />
        <ButtonComponent
          text="reject"
          variant="red-1"
          onClick={updateRequestStatus}
          loading={false}
          disabled={false}
        />
      </div>
    </>
  );
}
AutoChargeCancellationRequestActions.propTypes = {
  data: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};
export default AutoChargeCancellationRequestActions;
