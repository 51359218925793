export const AddCourseTabs = [
  {
    displayValue: "basic_details",
  },
  {
    displayValue: "tags_highlights",
  },
  {
    displayValue: "courses",
  },
  {
    displayValue: "notes_modules",
  },
  {
    displayValue: "certification",
  },
  {
    displayValue: "preview",
  },
];
export const ViewCourseDetailTabs = [
  {
    displayValue: "detail_view_description",
  },
  {
    displayValue: "detail_view_learn_section",
  },
  {
    displayValue: "detail_view_other_detail",
  },
];
export const CourseTableHeader = [
  { key: "slug", displayValue: "code", sort: true },
  { key: "title", displayValue: "title", sort: true },
  { key: "enroll_credits", displayValue: "enroll_credits", sort: true },
  { key: "reward_credits", displayValue: "reward_credits", sort: true },
  { key: "language", displayValue: "language", sort: true },
  {
    key: "subscription",
    displayValue: "subscription",
    sort: true,
    callbackFunction: "subscriptionTitle",
  },
  {
    key: "",
    displayValue: "is_free",
    sort: true,
    callbackFunction: "displayTableIsFreeField",
  },
  {
    key: "payment_type",
    displayValue: "payment",
    sort: true,
  },
  {
    key: "tags",
    displayValue: "tags",
    sort: true,
    callbackFunction: "displayTags",
  },
  {
    key: "status",
    displayValue: "status",
    sort: true,
    callbackFunction: "displayUnitStatus",
  },
];
export const CourseGridHeader = {
  header: [
    { callbackFunction: "displayTitleWithSlug" },
    { callbackFunction: "displayIsFreeField" },
  ],
  body: [
    {
      key: "enroll_credits",
      displayValue: "enroll_credits",
    },
    {
      key: "reward_credits",
      displayValue: "reward_credits",
    },
    {
      key: "language",
      displayValue: "language",
    },
    {
      key: "tags",
      displayValue: "tags",
      callbackFunction: "displayTags",
    },
    {
      key: "status",
      displayValue: "status",
      callbackFunction: "displayUnitStatus",
    },
  ],
};
export const ExportCourseHeaders = [
  { key: "course_id", label: "Id" },
  { key: "title", label: "Title" },
  { key: "category_id", label: "Category" },
  { key: "subscription", label: "Subscription" },
  { key: "language", label: "Language" },
  { key: "enroll_credits", label: "Enrolled Credits" },
  { key: "reward_credits", label: "Reward Credits" },
  { key: "is_free", label: "Is Free" },
  { key: "author_name", label: "Author Name" },
  { key: "payment_type", label: "Payment Type" },
  { key: "completion_duration", label: "Completion Duration" },
  { key: "duration_type", label: "Duration Type" },
  { key: "quiz_url", label: "Quiz URL" },
  { key: "description", label: "Detail" },
  { key: "short_description", label: "Short Description" },
  { key: "tags", label: "Tags List" },
  {
    key: "learning_points",
    label: "Learning Points",
  },
  {
    key: "pre_req_courses",
    label: "Pre Requisite Courses",
  },
  { key: "urls", label: "Urls" },
  { key: "course_modules", label: "Modules" },
  { key: "completed_percentage", label: "Completion Percentage" },
  { key: "quiz_percentage", label: "Quiz Percentage" },
  { key: "validity_period", label: "Validity Period" },
  { key: "validity_type", label: "Validity Type" },
  { key: "certificate_description", label: "Certificate Description" },
];
export const ExportCourseTableHeaders = [
  { key: "no", displayValue: "#" },
  { key: "course_id", displayValue: "id" },
  { key: "title", displayValue: "title" },
  { key: "category_id", displayValue: "category" },
  { key: "subscription", displayValue: "subscription" },
  { key: "language", displayValue: "language" },
  { key: "enroll_credits", displayValue: "enroll_credits" },
  { key: "reward_credits", displayValue: "reward_credits" },
  { key: "is_free", displayValue: "is_free" },
  { key: "author_name", displayValue: "author_name" },
  { key: "payment_type", displayValue: "payment_type" },
  { key: "completion_duration", displayValue: "completion_duration" },
  { key: "duration_type", displayValue: "duration_type" },
  { key: "quiz_url", displayValue: "quiz_url" },
  { key: "description", displayValue: "description" },
  { key: "short_description", displayValue: "short_description" },
  { key: "tags", displayValue: "tags_list" },
  {
    key: "learning_points",
    displayValue: "learning_points",
  },
  {
    key: "pre_req_courses",
    displayValue: "pre_req_courses",
  },
  { key: "urls", displayValue: "urls" },
  { key: "course_modules", displayValue: "modules" },
  { key: "completed_percentage", displayValue: "completion_percentage" },
  { key: "quiz_percentage", displayValue: "quiz_percentage" },
  { key: "validity_period", displayValue: "validity_period" },
  { key: "validity_type", displayValue: "validity_type" },
  { key: "certificate_description", displayValue: "certificate_description" },
];
export const ModalButtons = {
  exportSuccess: {
    text: "export_success",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  exportError: {
    text: "",
    buttons: [
      {
        text: "ok",
        variant: "red-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  confirmDelete: {
    text: "delete_record",
    buttons: [
      {
        text: "deactivate",
        variant: "red-1",
        onClick: "deactivateCourse",
      },
      {
        text: "cancel",
        variant: "white-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  confirmActive: {
    text: "active_record",
    buttons: [
      {
        text: "activate",
        variant: "blue-1",
        onClick: "deactivateCourse"
      },
      {
        text: "cancel",
        variant: "white-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordDelete: {
    text: "record_deleted",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordUpdate: {
    text: "record_update",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordCreated: {
    text: "record_created",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordActivated: {
    text: "record_activate",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  deactivateActionError: {
    text: "action_on_deactivated_error",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  listing403: {
    text: "403_error_msg",
    buttons: [
      {
        text: "ok",
        variant: "red-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
};
