import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CallAPI } from "actions/General";
import ErrorComponent from "components/ErrorComponent";
import ListingHeaderComponent from "components/ListingHeaderComponent";
import LoaderComponent from "components/LoaderComponent";
import TableComponent from "components/TableComponent";
import { DefaultPaginationSize } from "config/config";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";
import {
  ModalButtons,
  StudentSubscriptionTableHeader,
  SuperAdminSubscriptionTableHeader,
} from "./utils";
import EditSubscription from "./EditSubscription";
import CustomModal from "components/CustomModal";
import AutoChargeCancellationRequest from "./AutoChargeCancellationRequest";
import PaginationComponent from "components/PaginationComponent";

function Subscriptions() {
  const navigate = useNavigate();
  const userRole = useRecoilValue(getUserRole);
  const [editId, setEditId] = useState("");
  // const [displayType, setDisplayType] = useState("list");
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(DefaultPaginationSize);
  const [totalRecords, setTotalRecords] = useState(0);

  const [subscriptionId, setSubscriptionId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVariant, setModalVariant] = useState("");
  const [modalType, setModalType] = useState("");

  const [showCancellationRequests, setShowCancellationRequests] =
    useState(false);

  const addSubscription = () => {
    if (subscriptionsData.length === 0) navigate("/add_subscription");
    else {
      setIsModalOpen(true);
      setModalVariant("error");
      setModalType("alreadyHaveActiveSubscription");
    }
  };
  const autoChargeCancelRequest = () => {
    setShowCancellationRequests(true);
  };
  const getSubscriptionsList = async () => {
    const APIName =
      userRole === "SUPER_ADMIN"
        ? "GET_ALL_SUBSCRIPTION_PLANS"
        : "GET_MY_SUBSCRIPTIONS";
    const APIBody =
      userRole === "SUPER_ADMIN" ? null : { page, pageSize: pageLimit };
    setLoaderType("get_listing");
    const result = await CallAPI(
      APIName,
      APIBody,
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setSubscriptionsData(result.data.subscriptions);
      setTotalRecords(result.data.total);
    }
  };
  useEffect(() => {
    getSubscriptionsList();
  }, [page, pageLimit]);

  const cancelSubscriptionAlert = async (id) => {
    setSubscriptionId(id);
    setIsModalOpen(true);
    setModalVariant("confirmation");
    setModalType("confirmSubscriptionCancel");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setApiError("");
  };
  const cancelSubscription = async () => {
    const result = await CallAPI(
      "CANCEL_SUBSCRIPTION",
      { id: subscriptionId },
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      getSubscriptionsList();
      setModalVariant("success");
      setModalType("subscriptionCancelled");
    } else {
      setModalVariant("error");
      setModalType("subscriptionCancelledError");
    }
  };
  const cancelAutoRenewalAlert = async (id) => {
    setSubscriptionId(id);
    setIsModalOpen(true);
    setModalVariant("confirmation");
    setModalType("confirmAutoRenewalCancel");
  };

  const cancelAutoRenewal = async () => {
    const result = await CallAPI(
      "CANCEL_AUTO_RENEWAL_REQUEST",
      { id: subscriptionId },
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      getSubscriptionsList();
      setModalVariant("success");
      setModalType("autoRenewalCancelled");
    } else {
      setModalVariant("error");
      setModalType("autoRenewalCancelledError");
    }
  };

  return (
    <>
      {loader && loaderType === "get_listing" && (
        <LoaderComponent classes={"mx-4"} />
      )}
      <div className="card mb-4">
        {apiError && <ErrorComponent text={apiError} variant="danger" />}
        <ListingHeaderComponent
          type={
            userRole === "SUPER_ADMIN"
              ? "subscriptions_listing"
              : "my_subscriptions_listing"
          }
          setDisplayType={() => {}}
          displayType={""}
          addItem={
            userRole === "SUPER_ADMIN"
              ? autoChargeCancelRequest
              : addSubscription
          }
        />
        <div className={"card-body py-4"}>
          <TableComponent
            data={subscriptionsData}
            header={
              userRole === "SUPER_ADMIN"
                ? SuperAdminSubscriptionTableHeader
                : StudentSubscriptionTableHeader
            }
            type="subscription"
            props={{
              setEditId,
              cancelSubscriptionAlert,
              cancelAutoRenewalAlert,
            }}
          />
        </div>
      </div>
      <PaginationComponent
        totalPages={
          totalRecords ? Math.ceil(totalRecords / pageLimit) : totalRecords
        }
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        setPage={setPage}
        currentPage={page}
      />
      {editId && (
        <EditSubscription
          editId={editId}
          setEditId={setEditId}
          getSubscriptionsList={getSubscriptionsList}
        />
      )}
      {isModalOpen && (
        <CustomModal
          header={""}
          text={
            ModalButtons[modalType].text
              ? ModalButtons[modalType].text
              : apiError
          }
          buttons={ModalButtons[modalType].buttons}
          onClose={closeModal}
          variant={modalVariant}
          functionsList={{
            closeModal,
            cancelSubscription,
            cancelAutoRenewal,
          }}
        />
      )}
      {showCancellationRequests ? (
        <AutoChargeCancellationRequest
          setShowCancellationRequests={setShowCancellationRequests}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default Subscriptions;
