export const ListingHeaderItems = {
  notes_documents: {
    text: "",
    button_text: "mark_as_complete",
  },
  enrollments_listing: {
    text: "Enrollments List",
    button_text: "",
  },
  seasons_listing: {
    text: "Seasons List",
    button_text: "",
  },
  schedule_listing: {
    text: "Schedule List",
    button_text: "add_schedule",
  },
  course_schedule_listing: {
    text: "Select Schedule to Enroll in",
    button_text: "",
  },
  program_listing: {
    text: "Program List",
    button_text: "add_program",
  },
  units_listing: {
    text: "Unit List",
    button_text: "add_unit",
  },
  modules_listing: {
    text: "Modules List",
    button_text: "add_module",
  },
  subscriptions_listing: {
    text: "Subscriptions List",
    button_text: "subscription_cancel_request",
  },
  my_subscriptions_listing: {
    text: "Subscriptions List",
    button_text: "add_subscription",
  },
  course_listing: {
    text: "Courses List",
    button_text: "add_course",
  },
  view_attendance: {
    text: "View Attendance",
    button_text: "",
  },
  track_students: {
    text: 'Track Students',
    button_text: '',
  }
};
