import BUSINESS_SETTINGS_ICON from "assets/images/tabs/business-settings.svg";
import PAYMENT_ICON from "assets/images/tabs/payments.svg";
import CERTIFICATE_ICON from "assets/images/tabs/certificate.svg";
import EVENT_ICON from "assets/images/tabs/event.svg";
import SETTING_ICON from "assets/images/tabs/setting.svg";
export const SettingTabs = [
  {
    displayValue: "business_settings",
    img: BUSINESS_SETTINGS_ICON,
  },
  {
    displayValue: "payment_settings",
    img: PAYMENT_ICON,
  },
  {
    displayValue: "event_certificate",
    img: EVENT_ICON,
  },
  {
    displayValue: "completion_certificate_criteria",
    img: CERTIFICATE_ICON,
  },
  {
    displayValue: "company_settings",
    img: SETTING_ICON,
  },
];
export const SuperAdminSettingTabs = [
  {
    displayValue: "auth_settings",
    img: BUSINESS_SETTINGS_ICON,
  },
  {
    displayValue: "rewards_settings",
    img: BUSINESS_SETTINGS_ICON,
  },
];
