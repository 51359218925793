import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextComponent from "components/TextComponent";
import ButtonComponent from "components/ButtonComponent";
import { CallAPI } from "actions/General";

function AddCategory({
  getAllCategories,
  editObj,
  setEditObj,
  importExportDetail,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const updateValues = (name, value) => {
    setTitle(value);
  };
  useEffect(() => {
    if (editObj && editObj.id !== "") {
      setTitle(editObj.title);
    } else {
      setTitle("");
    }
  }, [editObj]);
  const addCategory = async () => {
    if (!title) setError("Category name is required");
    else {
      let APIPath =
        editObj && editObj.id ? "UPDATE_CATEGORY" : "CREATE_CATEGORY";
      let recordIdToUpdate = editObj && editObj.id ? editObj.id : null;
      setError("");
      let result = await CallAPI(
        APIPath,
        { title },
        recordIdToUpdate,
        setLoading,
        setApiError,
        null
      );
      if (result.status) {
        getAllCategories("backward");
        setTitle("");
        if (editObj && editObj.id) setEditObj(null);
      }
    }
  };
  return (
    <div className="d-flex justify-content-between">
      <div>
        <div className="create-title mb-3">
          {t("categories.create_categories_title")}
        </div>
        <div className="d-flex gap-3 align-items-start justify-content-start">
          <TextComponent
            name="add_category"
            label=""
            error={error}
            placeholder="category_name"
            value={title}
            setValue={updateValues}
            classes={""}
            required={true}
          />
          <ButtonComponent
            text={editObj && editObj.id ? "update" : "create"}
            variant="blue-1 type-1 mt-1"
            onClick={addCategory}
            loading={loading}
            disabled={loading}
          />
        </div>
      </div>
      <div>
        <>
          <ButtonComponent
            text={`import_categories`}
            onClick={importExportDetail.importFunc}
            variant="blue-1"
            loading={
              importExportDetail.loading &&
              importExportDetail.loaderType === "import"
                ? true
                : false
            }
            disabled={
              importExportDetail.loading &&
              importExportDetail.loaderType === "import"
                ? true
                : false
            }
          />
          <ButtonComponent
            text={`export_categories`}
            onClick={importExportDetail.exportFunc}
            variant="blue-1 mx-3"
            loading={
              importExportDetail.loading &&
              importExportDetail.loaderType === "export"
                ? true
                : false
            }
            disabled={
              importExportDetail.loading &&
              importExportDetail.loaderType === "export"
                ? true
                : false
            }
          />
        </>
      </div>
    </div>
  );
}

AddCategory.propTypes = {
  getAllCategories: PropTypes.func.isRequired,
  setEditObj: PropTypes.func.isRequired,
  editObj: PropTypes.object,
  importExportDetail: PropTypes.object,
};
export default AddCategory;
