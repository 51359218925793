import ButtonComponent from "components/ButtonComponent";
import TabsComponent from "components/TabsComponent";
import React, { useEffect, useState } from "react";
import { AddModulesTabs, ModalButtons } from "./utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getArrayDifference,
  handlePageResize,
  UploadMedia,
} from "config/config";
import { CallAPI } from "actions/General";
import {
  BasicDetailsSchema,
  NotesUnitsSchema,
  TagsLearnHighlightsSchema,
} from "./FieldValidationRoles";
import LoaderComponent from "components/LoaderComponent";
import BasicDetails from "./BasicDetails";
import TagsLearnHighlights from "./TagsLearnHighlights";
import NotesUnits from "./NotesUnits";
import Preview from "./Preview";
import CustomModal from "components/CustomModal";

function AddModule() {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(1);
  const [updatedRecordId, setUpdatedRecordId] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [values, setValues] = useState({
    title: "",
    reward_credits: "",
    competency_level: "",
    detail: "",
    banner: null,
    tags: [],
    learning_points: [],
    urls: [],
    documents: [],
    units: [],
  });
  const [errors, setErrors] = useState({});
  const [rowItems, setRowItems] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVariant, setModalVariant] = useState("");
  const [modalType, setModalType] = useState("");

  const getModuleDetails = async () => {
    if (updatedRecordId) {
      setLoaderType("get_details");
      let result = await CallAPI(
        "GET_MODULE_DETAIL",
        null,
        updatedRecordId,
        setLoader,
        null,
        null
      );
      if (result.status) {
        let moduleObj = result.data;
        let prevValues = {
          title: moduleObj.title,
          reward_credits: moduleObj.credits,
          competency_level: moduleObj.competency_level,
          detail: moduleObj.description,
          banner: moduleObj.banner_image
            ? { id: moduleObj.banner_image.id }
            : null,
          tags: moduleObj.tags.map((singleTag) => {
            return { id: singleTag.id, title: singleTag.title };
          }),
          learning_points: moduleObj.learning_points.map(
            (singleLearningPoint) => {
              return {
                id: singleLearningPoint.id,
                text: singleLearningPoint.title,
              };
            }
          ),
          urls: moduleObj.urls.map((singleUrl) => {
            return {
              id: singleUrl.id,
              text: singleUrl.title,
            };
          }),
          documents: moduleObj.documents
            ? moduleObj.documents.map((singleDocument) => {
                return {
                  id: singleDocument.id,
                  media_id: singleDocument.id,
                  name: singleDocument.name,
                  size: singleDocument.size,
                };
              })
            : [],
          units: moduleObj.module_units.map((singleUnit) => {
            return {
              id: singleUnit.id,
              sequence: singleUnit.sequence,
              title: singleUnit.title,
            };
          }),
          module: moduleObj,
        };
        setValues({
          ...prevValues,
        });
      }
    }
  };
  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.update_id &&
      location.state.update_id !== ""
    )
      setUpdatedRecordId(location.state.update_id);
  }, [location]);
  useEffect(() => {
    if (updatedRecordId !== "");
    getModuleDetails();
  }, [updatedRecordId]);

  const updateFormValues = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  const previousClick = () => {
    if (selectedTab === 1) {
      navigate("/modules");
    } else {
      setSelectedTab(selectedTab - 1);
    }
  };

  const nextClick = async () => {
    if (selectedTab === AddModulesTabs.length) {
      submitForm();
    } else {
      try {
        setErrors({});
        const errorValidatorSchema =
          selectedTab === 1
            ? BasicDetailsSchema
            : selectedTab === 2
            ? TagsLearnHighlightsSchema
            : NotesUnitsSchema;
        await errorValidatorSchema.validate(values, { abortEarly: false });
        setSelectedTab(selectedTab + 1);
      } catch (error) {
        console.log("error", error);
        const schemaErrors = {};
        error.inner?.forEach((err) => {
          schemaErrors[err.path] = err.message;
        });
        setErrors(schemaErrors);
      }
    }
  };
  const submitForm = async () => {
    setLoaderType("submit");

    let bannerImage = null;
    if (values.banner && values.banner.id === undefined) {
      bannerImage = await UploadMedia(
        [values.banner],
        [
          {
            name: values.banner.path,
            size: values.banner.size.toString(),
            extension: values.banner.type,
          },
        ]
      );
      bannerImage = bannerImage[0];
    } else if (values.banner && values.banner.id) {
      bannerImage = values.banner.id;
    }

    let addedDocuments = [];
    if (values.documents.length > 0) {
      let files = [];
      let filesDetail = [];
      for (let index = 0; index < values.documents.length; index++) {
        if (
          values.documents[index] &&
          values.documents[index].id === undefined
        ) {
          files.push(values.documents[index]);
          filesDetail.push({
            name: values.documents[index].path,
            size: values.documents[index].size.toString(),
            extension: values.documents[index].type,
          });
        }
      }
      if (files.length > 0)
        addedDocuments = await UploadMedia(files, filesDetail);
    }

    const addedUnits = getArrayDifference(
      values.units,
      values?.module?.units ? values.module.units : []
    );

    const addedUnitsList = addedUnits.map((singleSelectedUnit, index) => {
      return { id: singleSelectedUnit.id, sequence: index + 1 };
    });
    const moduleDetail = {
      title: values.title,
      credits: parseInt(values.reward_credits),
      competency_level: values.competency_level,
      banner_image_id: bannerImage,
      description: values.detail,
      tags: {
        added: values?.module?.tags
          ? getArrayDifference(values.tags, values.module.tags)
          : values.tags,
        deleted: values?.module?.tags
          ? getArrayDifference(values.module.tags, values.tags, "string", "id")
          : [],
      },
      learning_points: {
        added: getArrayDifference(
          values.learning_points,
          values?.module?.learning_points ? values.module.learning_points : [],
          "string",
          "text"
        ),
        deleted: values?.module?.learning_points
          ? getArrayDifference(
              values.module.learning_points,
              values.learning_points,
              "string",
              "id"
            )
          : [],
      },
      documents: {
        added: addedDocuments,
        deleted: values?.module?.documents
          ? getArrayDifference(
              values.module.documents,
              values.documents,
              "string",
              "id"
            )
          : [],
      },
      urls: {
        added: getArrayDifference(
          values.urls,
          values?.module?.urls ? values.module.urls : [],
          "string",
          "text"
        ),
        deleted: values?.module?.urls
          ? getArrayDifference(values.module.urls, values.urls, "string", "id")
          : [],
      },
      units: addedUnitsList,
    };
    const APIName =
      updatedRecordId && updatedRecordId !== ""
        ? "UPDATE_MODULE"
        : "ADD_MODULE";
    let result = await CallAPI(
      APIName,
      moduleDetail,
      updatedRecordId && updatedRecordId !== "" ? updatedRecordId : null,
      setLoader,
      null,
      null
    );
    if (result.status) {
      setIsModalOpen(true);
      setModalVariant("success");
      setModalType(
        updatedRecordId && updatedRecordId !== ""
          ? "recordUpdate"
          : "recordCreated"
      );
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/modules");
  };
  const selectOtherTab = async (tabToSelect) => {
    // let startTab = 1;
    // try {
    //   while (startTab < tabToSelect) {
    //     const errorValidatorSchema =
    //       selectedTab === 1
    //         ? BasicDetailsSchema
    //         : selectedTab === 2
    //         ? TagsLearnHighlightsSchema
    //         : NotesUnitsSchema;
    //     if (errorValidatorSchema)
    //       await errorValidatorSchema.validate(values, { abortEarly: false });
    //     startTab++;
    //   }
    //   setSelectedTab(startTab);
    // } catch (error) {
    //   const schemaErrors = {};
    //   error.inner?.forEach((err) => {
    //     schemaErrors[err.path] = err.message;
    //   });
    //   setErrors(schemaErrors);
    //   setSelectedTab(startTab);
    // }
  };
  function handleResize() {
    const itemsInSingleRow = handlePageResize();
    setRowItems(itemsInSingleRow);
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {loader && loaderType === "get_details" && <LoaderComponent classes="" />}
      <div className="card">
        <div
          className="card-body custom-form"
          id="custom-form"
          style={{ "--noOfColumn": rowItems }}
        >
          {rowItems && (
            <>
              <TabsComponent
                tabs={AddModulesTabs}
                selectedTab={selectedTab}
                setSelectedTab={selectOtherTab}
              >
                <div className="mx-auto w-100 pt-15 pb-10 form-details">
                  {selectedTab === 1 ? (
                    <BasicDetails
                      errors={errors}
                      values={values}
                      updateFormValues={updateFormValues}
                    />
                  ) : selectedTab === 2 ? (
                    <TagsLearnHighlights
                      errors={errors}
                      values={values}
                      updateFormValues={updateFormValues}
                    />
                  ) : selectedTab === 3 ? (
                    <NotesUnits
                      errors={errors}
                      values={values}
                      updateFormValues={updateFormValues}
                    />
                  ) : selectedTab === 4 ? (
                    <Preview values={values} />
                  ) : (
                    <></>
                  )}
                </div>

                <div className="w-auto d-flex justify-content-end gap-3 px-3">
                  <ButtonComponent
                    text={selectedTab === 1 ? "cancel" : "previous"}
                    onClick={previousClick}
                    variant="grey-1 w-auto"
                    loading={false}
                    disabled={false}
                  />
                  <ButtonComponent
                    text={
                      selectedTab === AddModulesTabs.length &&
                      updatedRecordId === ""
                        ? "submit"
                        : selectedTab === AddModulesTabs.length &&
                          updatedRecordId !== ""
                        ? "update"
                        : "next"
                    }
                    onClick={nextClick}
                    variant="blue-1 w-auto"
                    loading={loader && loaderType === "submit"}
                    disabled={loader && loaderType === "submit"}
                  />
                </div>
              </TabsComponent>
            </>
          )}
        </div>
      </div>
      {isModalOpen && (
        <CustomModal
          header={""}
          text={ModalButtons[modalType].text}
          buttons={ModalButtons[modalType].buttons}
          onClose={closeModal}
          variant={modalVariant}
          functionsList={{ closeModal }}
        />
      )}
    </>
  );
}

export default AddModule;
