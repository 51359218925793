import React, { useState } from "react";
import PropTypes from "prop-types";
import Papa from "papaparse";
import { ExportUnitHeaders, ExportUnitTableHeaders } from "./units/utils";
import {
  CompetencyLevels,
  CSVSeparator,
  DefaultExportPaginationSize,
} from "config/config";
import ItemListingComponent from "components/ItemListingComponent";
import CloseImage from "assets/images/general_old/close.svg";
import TableComponent from "components/TableComponent";
import PaginationComponent from "components/PaginationComponent";
import ButtonComponent from "components/ButtonComponent";
import { CallAPI } from "actions/General";
import CustomModal from "components/CustomModal";
import { ExportModuleHeaders, ExportModuleTableHeaders } from "./modules/utils";
import { ExportTagHeaders, ExportTagTableHeaders } from "./tags/utils";
import {
  ExportCategoryHeaders,
  ExportCategoryTableHeaders,
} from "./categories/utils";
import { ExportCourseHeaders, ExportCourseTableHeaders } from "./course/utils";

function ImportData({ setAllowImport, type }) {
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [successCount, setSuccessCount] = useState(10);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [viewErrors, setViewErrors] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const ImportData = (e, files) => {
    Papa.parse(files[0], {
      complete: (result) => {
        console.log(result.data); // Save parsed data to state
        createBulkRecords(result.data);
      },
      error: (err) => {
        console.error(err);
      },
      header: true, // If you want the first row as headers
    });
  };
  const createBulkRecords = (data) => {
    const apiDataArray = [];
    const errorsArray = [];
    const header =
      type === "unit"
        ? ExportUnitHeaders
        : type === "module"
        ? ExportModuleHeaders
        : type === "tag"
        ? ExportTagHeaders
        : type === "category"
        ? ExportCategoryHeaders
        : type === "courses"
        ? ExportCourseHeaders
        : "";
    const competencyLevels = CompetencyLevels.map((item) => item.value).filter(
      Boolean
    );
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
    data.map((item, i) => {
      console.log("item", item);
      let SingleObj = { no: i + 1 };
      if (Object.keys(item).length >= header.length) {
        header.map((singleHeader) => {
          if (singleHeader.key === "id") {
            if (!uuidRegex.test(item[singleHeader.label]))
              errorsArray.push(`invalid UUID for column id of row#  ${i + 1}`);
            SingleObj[singleHeader.key] = item[singleHeader.label];
          } else if (
            singleHeader.key === "tags" ||
            singleHeader.key === "course_module" ||
            singleHeader.key === "module_units"
          ) {
            const value = item[singleHeader.label]
              ? item[singleHeader.label].split(CSVSeparator)
              : [];
            let allIdsAreValid = true;
            value.map((tagId) => {
              if (!uuidRegex.test(tagId)) allIdsAreValid = false;
            });
            if (value.length === 0)
              errorsArray.push(`Tag list is mandatory for row#  ${i + 1}`);
            if (!allIdsAreValid)
              errorsArray.push(
                `invalid UUID for column Tags List of row#  ${i + 1}`
              );
            SingleObj[singleHeader.key] = item[singleHeader.label];
          } else if (singleHeader.key === "competency_level") {
            if (competencyLevels.indexOf(item[singleHeader.label]) === -1)
              errorsArray.push(`invalid competency level for row#  ${i + 1}`);
            SingleObj[singleHeader.key] = item[singleHeader.label];
          } else {
            SingleObj[singleHeader.key] = item[singleHeader.label];
          }
        });
        apiDataArray.push(SingleObj);
      }
    });
    setData(apiDataArray);
    setErrors(errorsArray);
    if (errorsArray.length === 0) {
      setViewErrors(false);
    } else {
      setShowErrorAlert(true);
    }
  };
  const uploadData = async () => {
    setLoader(true);
    const apiDataArray = [];
    let singleCallApiDataArray = [];

    data.map((item) => {
      let { no, ...updatedItem } = item;
      if (updatedItem.credits)
        updatedItem.credits = parseInt(updatedItem.credits);
      if (updatedItem.reward_credits)
        updatedItem.reward_credits = parseInt(updatedItem.reward_credits);
      if (updatedItem.enroll_credits)
        updatedItem.enroll_credits = parseInt(updatedItem.enroll_credits);
      if (updatedItem.completion_duration)
        updatedItem.completion_duration = parseInt(
          updatedItem.completion_duration
        );
      if (updatedItem.completed_percentage)
        updatedItem.completed_percentage = parseInt(
          updatedItem.completed_percentage
        );
      if (updatedItem.quiz_percentage)
        updatedItem.quiz_percentage = parseInt(updatedItem.quiz_percentage);
      if (updatedItem.validity_period)
        updatedItem.validity_period = parseInt(updatedItem.validity_period);
      if (updatedItem.is_free)
        updatedItem.is_free =
          updatedItem.is_free.toLowerCase() === "true" ? true : false;
      if (updatedItem.tags)
        updatedItem.tags = updatedItem.tags.split(CSVSeparator);
      if (updatedItem.pre_req_courses)
        updatedItem.pre_req_courses =
          updatedItem.pre_req_courses.split(CSVSeparator);
      if (updatedItem.pre_req_courses === "") updatedItem.pre_req_courses = [];
      if (updatedItem.course_modules)
        updatedItem.course_modules =
          updatedItem.course_modules.split(CSVSeparator);
      if (updatedItem.module_units)
        updatedItem.module_units = updatedItem.module_units.split(CSVSeparator);
      if (updatedItem.learning_points)
        updatedItem.learning_points =
          updatedItem.learning_points.split(CSVSeparator);
      if (updatedItem.urls)
        updatedItem.urls = updatedItem.urls.split(CSVSeparator);
      singleCallApiDataArray.push(updatedItem);
      if (singleCallApiDataArray.length === DefaultExportPaginationSize) {
        apiDataArray.push(singleCallApiDataArray);
        singleCallApiDataArray = [];
      }
    });
    if (singleCallApiDataArray.length > 0)
      apiDataArray.push(singleCallApiDataArray);

    let errorsList = [];
    let successCounter = [];
    for (let index = 0; index < apiDataArray.length; index++) {
      const item = apiDataArray[index];
      const result = await CallAPI(
        type === "unit"
          ? "IMPORT_UNITS_LIST"
          : type === "module"
          ? "IMPORT_MODULES_LIST"
          : type === "tag"
          ? "IMPORT_TAGS_LIST"
          : type === "category"
          ? "IMPORT_CATEGORIES_LIST"
          : type === "courses"
          ? "IMPORT_COURSES_LIST"
          : "",
        item,
        null,
        null,
        null,
        item
      );
      if (result.status) {
        errorsList = [...errorsList, ...result.data.errors];
        successCounter += result.data.new_records;
      }
    }
    setErrors(errorsList);
    setShowErrorAlert(true);
    setSuccessCount(successCounter);
    setLoader(false);
  };
  return (
    <div className={`custom-modal`}>
      <div className="import">
        <img
          className="close"
          src={CloseImage}
          onClick={() => setAllowImport(false)}
          alt="close"
        />
        <ItemListingComponent
          type={"upload_csv"}
          items={[]}
          updateItems={ImportData}
          error={""}
          extensions={[".csv"]}
        />
        <div className="d-flex justify-content-end gap-2 me-5">
          <ButtonComponent
            text={viewErrors ? "hide_errors" : "view_errors"}
            onClick={() => {
              setViewErrors(!viewErrors);
            }}
            variant="red-1"
            loading={false}
            disabled={errors.length === 0 || data.length === 0 ? true : false}
          />
          <ButtonComponent
            text="upload_data"
            onClick={uploadData}
            variant="blue-1"
            loading={loader}
            disabled={errors.length > 0 || data.length === 0 ? true : loader}
          />
        </div>
        {viewErrors ? (
          errors.length > 0 ? (
            <div className="d-flex flex-column gap-2 mx-5 overflow-auto pb-4">
              {errors.map((error, i) => (
                <span key={i} className="single-error">
                  {`${i + 1} => ${error}`}
                </span>
              ))}
            </div>
          ) : (
            <></>
          )
        ) : (
          <>
            <TableComponent
              data={data.slice((page - 1) * limit, page * limit)}
              header={
                type === "unit"
                  ? ExportUnitTableHeaders
                  : type === "module"
                  ? ExportModuleTableHeaders
                  : type === "tag"
                  ? ExportTagTableHeaders
                  : type === "category"
                  ? ExportCategoryTableHeaders
                  : type === "courses"
                  ? ExportCourseTableHeaders
                  : []
              }
              type={type}
            />
            <PaginationComponent
              totalPages={data.length > 0 ? Math.ceil(data.length / limit) : 0}
              pageLimit={limit}
              setPageLimit={setLimit}
              setPage={setPage}
              currentPage={page}
            />
          </>
        )}
      </div>
      {showErrorAlert && (
        <CustomModal
          header={""}
          text={errors.length > 0 ? "import_errors" : "import_success"}
          buttons={[
            {
              text: "ok",
              variant: "blue-1",
              onClick: "setShowErrorAlert",
              loading: false,
              disabled: false,
            },
          ]}
          onClose={setShowErrorAlert}
          variant={errors.length > 0 ? "error" : "success"}
          functionsList={{ setShowErrorAlert }}
        />
      )}
    </div>
  );
}
ImportData.propTypes = {
  setAllowImport: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};
export default ImportData;
