import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoaderComponent from "components/LoaderComponent";
import { getImagePreviewUrl } from "config/config";
import { CallAPI } from "actions/General";
import DEFAULT_IMG from "assets/images/general_old/default-image.svg";
import DocumentImage from "assets/images/general_old/document-solid.svg";
import DisplayHtml from "views/DisplayHtml";
import ButtonComponent from "components/ButtonComponent";
import allFunction from "actions/TableUtils";
import { ViewUnitDetailTabs } from "./utils";
import TabsComponent from "components/TabsComponent";

function UnitDetailView() {
  const location = useLocation();

  const [values, setValues] = useState({});
  const [loader, setLoader] = useState(true);
  const [loaderType, setLoaderType] = useState("get_list");
  const [selectedTab, setSelectedTab] = useState(1);
  const [imageUrl, setImageUrl] = useState("");

  const getUnitDetails = async (record_id) => {
    setLoaderType("get_list");
    if (record_id) {
      let result = await CallAPI(
        "GET_UNIT_DETAIL",
        null,
        record_id,
        setLoader,
        null,
        null
      );
      if (result.status) {
        let courseObj = result.data;
        let prevValues = {
          title: courseObj.title,
          reward_credits: courseObj.credits,
          competency_level: courseObj.competency_level,
          banner: courseObj.banner_image ? courseObj.banner_image.id : null,
          slug: courseObj.slug,
          status: courseObj.status,
          detail: courseObj.description,
          tags: courseObj.tags.map((singleTag) => {
            return singleTag.title;
          }),
          learning_points: courseObj.learning_points.map(
            (singleLearningPoint) => {
              return singleLearningPoint.title;
            }
          ),
          urls: courseObj.urls.map((singleUrl) => {
            return singleUrl.title;
          }),
          documents: courseObj.documents
            ? courseObj.documents.map((singleDocument) => {
                return {
                  id: singleDocument.id,
                  media_id: singleDocument.id,
                  name: singleDocument.name,
                  size: singleDocument.size,
                };
              })
            : [],
        };
        setValues({
          ...prevValues,
        });
        if (prevValues.banner) {
          getPreviewImg(prevValues.banner);
        }
      }
    }
  };

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.record_id &&
      location.state.record_id !== ""
    )
      getUnitDetails(location.state.record_id);
  }, [location]);

  const getPreviewImg = async (id) => {
    const url = await getImagePreviewUrl(id, null);
    setImageUrl(url);
  };
  const publishUnit = async () => {
    setLoaderType("publish");
    const result = await CallAPI(
      "PUBLISH_UNIT",
      {
        status: "PUBLISHED",
      },
      location.state.record_id,
      setLoader,
      null,
      null
    );
    if (result.status) getUnitDetails(location.state.record_id);
  };

  return (
    <div className="module-detail-view preview">
      {loader && loaderType === "get_list" ? (
        <LoaderComponent classes="mx-4" />
      ) : (
        <>
          <div className="p-5 pt-18 position-relative">
            <div className="item basic pt-5">
              <img
                src={values.banner ? imageUrl : DEFAULT_IMG}
                alt="Unit Image"
              />

              <div className="top">
                <div>
                  <span className="text-gray-800 fw-bold">Title:</span>
                  <span className="text-gray-800">{values.title}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Tags:</span>
                  <span className="text-gray-800">
                    {values.tags.map((singleTag) => singleTag).join(", ")}
                  </span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">
                    Competency Level:
                  </span>
                  <span className="text-gray-800">
                    {values.competency_level}
                  </span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Reward Credits:</span>
                  <span className="text-gray-800">{values.reward_credits}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Slug:</span>
                  <span className="text-gray-800">{values.slug}</span>
                </div>
              </div>
              <div className="top">
                <div>
                  <span className="text-gray-800 fw-bold">Status:</span>
                  <span className="text-gray-800">
                    {allFunction.displayUnitStatus(values.status)}
                  </span>
                </div>
              </div>
            </div>
            <div className="actions-buttons">
              <div>
                <ButtonComponent
                  text="publish_unit"
                  onClick={publishUnit}
                  variant="blue-1"
                  loading={loader && loaderType === "publish"}
                  disabled={
                    allFunction.isItemPublished(values.status) ||
                    (loader && loaderType === "publish")
                  }
                />
              </div>
            </div>
          </div>
          <div className="py-5">
            <TabsComponent
              tabs={ViewUnitDetailTabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              type={"start"}
            >
              <div className="p-5 tab-item-detail item">
                {selectedTab === 1 ? (
                  <>
                    <span className="text-gray-800 fw-bold">Description</span>
                    <span className="text-gray-800">
                      {<DisplayHtml html={values.detail} />}
                    </span>
                  </>
                ) : selectedTab === 2 ? (
                  <ul>
                    {values.learning_points.map(
                      (singleLearningPoint, index) => {
                        return (
                          <li className="text-gray-800" key={index}>
                            {singleLearningPoint}
                          </li>
                        );
                      }
                    )}
                  </ul>
                ) : (
                  <>
                    <span className="text-gray-800 fw-bold">Tags:</span>
                    <span className="text-gray-800">
                      {values.tags.join(", ")}
                    </span>
                    <hr className="light" />
                    <span className="text-gray-800 fw-bold">
                      Documents & Notes
                    </span>
                    {values.documents.map((singleDocument, index) => {
                      return (
                        <div className="detail document" key={index}>
                          <img src={DocumentImage} alt="detail icon" />
                          <div>
                            <span className="title">{singleDocument.name}</span>
                            <span className="size">{singleDocument.size}</span>
                          </div>
                        </div>
                      );
                    })}
                    <hr className="light" />
                    <div className="item">
                      <span className="text-gray-800 fw-bold">
                        Reference Urls:
                      </span>
                      <div className="listing-item urls">
                        {values.urls.map((singleUrl, index) => {
                          return (
                            <span className="detail urls" key={index}>
                              {singleUrl}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </TabsComponent>
          </div>
        </>
      )}
    </div>
  );
}

export default UnitDetailView;
