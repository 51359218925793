import { CallAPI } from "actions/General";
import { recoilPersist } from "recoil-persist";
import jsonToCsvExport from "json-to-csv-export";

export const DefaultPaginationSize = 10;
export const DefaultExportPaginationSize = 20;
export const CSVSeparator = "#";

export const { persistAtom } = recoilPersist({
  key: "LMS-" + process.env.REACT_APP_ENV, // this key is using to store data in local storage
  storage: localStorage, // configure which storage will be used to store the data
  converter: JSON,
});

export const CompetencyLevels = [
  { value: "", displayValue: "Competency Level" },
  { value: "beginner", displayValue: "Beginner" },
  { value: "intermediate", displayValue: "Intermediate" },
  { value: "hard", displayValue: "Hard" },
];
export const paginationLimits = [
  { displayValue: 10, value: 10 },
  { displayValue: 20, value: 20 },
  { displayValue: 50, value: 50 },
  { displayValue: 100, value: 100 },
];

export const getArrayDifference = (
  firstArray,
  secondArray,
  type = "array",
  typeKey = ""
) => {
  const keySet = new Set(secondArray.map((obj) => obj.id));
  let differenceArray = firstArray.filter((obj) => !keySet.has(obj.id));
  if (type === "array") return differenceArray;
  else {
    console.log("in", type, typeKey);
    let returnedArray = differenceArray.map(
      (singleDifferentElement) => singleDifferentElement[typeKey]
    );
    console.log(returnedArray);
    return returnedArray;
  }
};
export const getArrayMatched = (
  firstArray,
  secondArray,
  type = "array",
  typeKey = ""
) => {
  const keySet = new Set(secondArray.map((obj) => obj.id));
  let differenceArray = firstArray.filter((obj) => keySet.has(obj.id));
  if (type === "array") return differenceArray;
  else {
    console.log("in", type, typeKey);
    let returnedArray = differenceArray.map(
      (singleDifferentElement) => singleDifferentElement[typeKey]
    );
    console.log(returnedArray);
    return returnedArray;
  }
};

export const UploadMedia = async (files, dataToSend) => {
  const response = await CallAPI(
    "GET_PRESIGNED_URL",
    dataToSend,
    null,
    null,
    null,
    null
  );
  console.log("response", response, files);

  let returnedIds = [];
  response.data.map(async (singleObj, index) => {
    returnedIds.push(singleObj.id);
    var myHeaders = new Headers();
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: files[index],
      redirect: "follow",
    };
    await fetch(singleObj.url, requestOptions)
      .then(() => {
        console.log("uploaded");
      })
      .catch((error) => console.log("error", error));
  });
  return returnedIds;
};
export const getImagePreviewUrl = async (id, setLoader) => {
  let result = await CallAPI(
    "GET_SINGLE_IMAGE_PREVIEW_URL",
    null,
    id,
    setLoader,
    null,
    null
  );
  if (result.status) {
    return result.data.url;
  } else return "";
};

export const handlePageResize = () => {
  const html = document.documentElement; // Get the root element
  const baseFontSize = parseInt(window.getComputedStyle(html).fontSize);
  const parentWidth = Math.floor(
    document.getElementById("custom-form").offsetWidth -
      Math.ceil(baseFontSize * 4.5) -
      4
  );

  let maxItemWidth = 0;

  var style = getComputedStyle(document.body);
  const singleItemWidth = parseInt(style.getPropertyValue("--max-field-width"));
  maxItemWidth = singleItemWidth * baseFontSize;
  const gap = baseFontSize * 2;
  if (maxItemWidth > 0) {
    let coveredWidth = 0;
    let itemsInSingleRow = 0;
    do {
      coveredWidth +=
        itemsInSingleRow === 0 ? maxItemWidth : maxItemWidth + gap;
      if (coveredWidth < parentWidth) itemsInSingleRow++;
    } while (coveredWidth < parentWidth);
    if (itemsInSingleRow > 3) itemsInSingleRow = 3;
    if (itemsInSingleRow < 1) itemsInSingleRow = 1;
    return itemsInSingleRow;
  }
};
export const exportCSV = async (type, headers, fileName) => {
  const promises = [];
  const allData = [];
  const page1Result = await CallAPI(
    type === "units"
      ? "EXPORT_UNITS_LIST"
      : type === "modules"
      ? "EXPORT_MODULES_LIST"
      : type === "courses"
      ? "EXPORT_COURSES_LIST"
      : type === "tags"
      ? "EXPORT_TAGS_LIST"
      : type === "categories"
      ? "EXPORT_CATEGORIES_LIST"
      : "",
    {
      page: 1,
      pageSize: DefaultExportPaginationSize,
    },
    null,
    null,
    null,
    null
  );
  const totalRecords = page1Result.data.total;
  // await new Promise((resolve) => setTimeout(resolve, 5000));
  for (
    let i = 2;
    i <= Math.ceil(totalRecords / DefaultExportPaginationSize);
    i++
  ) {
    promises.push(
      CallAPI(
        type === "units"
          ? "EXPORT_UNITS_LIST"
          : type === "modules"
          ? "EXPORT_MODULES_LIST"
          : type === "courses"
          ? "EXPORT_COURSES_LIST"
          : type === "tags"
          ? "EXPORT_TAGS_LIST"
          : type === "categories"
          ? "EXPORT_CATEGORIES_LIST"
          : "",
        {
          page: i,
          pageSize: DefaultExportPaginationSize,
        },
        null,
        null,
        null,
        null
      )
    );
  }
  let results = await Promise.all(promises);
  results.unshift(page1Result);
  results.forEach((result) => {
    if (result.status) {
      result.data[type].map((item) => {
        let obj = {};
        headers.map((header) => {
          if (header.key === "tags") {
            const value = item[header.key]
              .map((option) => option.id)
              .join(CSVSeparator);
            obj[header.key] = value;
          } else if (header.key === "course_modules") {
            const value = item[header.key]
              .map((option) => option.module_id)
              .join(CSVSeparator);
            obj[header.key] = value;
          } else if (header.key === "module_units") {
            const value = item[header.key]
              .map((option) => option.unit_id)
              .join(CSVSeparator);
            obj[header.key] = value;
          } else if (header.key === "category_id") {
            const value = item.category.id;
            obj[header.key] = value;
          } else if (header.key === "subscription") {
            const value = item[header.key] ? item[header.key].slug : "";
            obj[header.key] = value;
          } else if (
            header.key === "learning_points" ||
            header.key === "urls"
          ) {
            const value = item[header.key]
              .map((option) => option.title)
              .join(CSVSeparator);
            obj[header.key] = value;
          } else if (
            header.key === "completed_percentage" ||
            header.key === "quiz_percentage" ||
            header.key === "validity_period" ||
            header.key === "validity_type" ||
            header.key === "certificate_description"
          ) {
            const criteria_obj = item.certificate_criteria;
            obj[header.key] =
              header.key !== "certificate_description"
                ? criteria_obj[header.key]
                : criteria_obj.description;
          } else obj[header.key] = item[header.key];
        });
        allData.push(obj);
      });
    }
  });
  if (allData.length > 0) {
    jsonToCsvExport({
      data: allData,
      headers: headers,
      filename: fileName,
    });
    return { status: 1, message: "data Exported Successfully" };
  } else {
    return {
      status: 0,
      message: `No data found ${
        type === "units" || type === "modules" || type === "courses"
          ? "with Published status"
          : ""
      }`,
    };
  }
};
