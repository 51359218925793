import React, { useState } from "react";
import PropTypes from "prop-types";
import ButtonComponent from "components/ButtonComponent";
import { CallAPI } from "actions/General";
import { useNavigate } from "react-router-dom";
import CustomModal from "components/CustomModal";
import Payment from "views/payment/Payment";

function EnrollmentScheduleAction({ data, otherDetails }) {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const enrollCourse = async () => {
    const { payment_method, currency, pointsConversion, ...details } =
      otherDetails;
    const result = await CallAPI(
      "CREATE_ENROLLMENT",
      {
        schedule_id: data.id,
        ...details,
      },
      null,
      setLoading,
      setApiError,
      null
    );

    if (result.status) {
      navigate("/enrollments");
    } else {
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="d-flex flex-column align-items-center my-8 gap-3">
      {((otherDetails.payment_type === "pay" &&
        (otherDetails.payment_method === "BOTH" ||
          otherDetails.payment_method === "PAY_AS_CREDITS")) ||
        otherDetails.payment_type === "free" ||
        otherDetails.payment_type === "subscription") && (
        <ButtonComponent
          text="enroll_btn"
          loading={loading}
          disabled={loading}
          onClick={enrollCourse}
          variant="orange-1"
          langKey="credits"
          langVal={data.course.enroll_credits}
        />
      )}
      {otherDetails.payment_type === "pay" &&
        (otherDetails.payment_method === "BOTH" ||
          otherDetails.payment_method === "PAY_AS_STRIPE") && (
          <Payment
            type="enrollment"
            recordId={data.id}
            price={parseFloat(
              data.course.enroll_credits /
                otherDetails.pointsConversion[
                  "points_per_" + otherDetails.currency
                ]
            ).toPrecision(2)}
            period="one_time"
            currency={otherDetails.currency}
            otherDetails={{ ...otherDetails, schedule_id: data.id }}
          />
        )}
      {isModalOpen && (
        <CustomModal
          header={""}
          text={apiError}
          buttons={[
            {
              text: "ok",
              variant: "blue-1",
              onClick: "closeModal",
              loading: false,
              disabled: false,
            },
          ]}
          onClose={closeModal}
          variant={"error"}
          functionsList={{ closeModal }}
        />
      )}
    </div>
  );
}
EnrollmentScheduleAction.propTypes = {
  data: PropTypes.object.isRequired,
  otherDetails: PropTypes.object.isRequired,
};
export default EnrollmentScheduleAction;
