import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function RadioComponent({ classes, label, value, name, onChange, error }) {
  const { t } = useTranslation();

  return (
    <div
      className={`radioField ${classes.fieldClass ? classes.fieldClass : ""}`}
      onClick={() => onChange(name, !value)}
    >
      <input
        type={"radio"}
        className={classes.inputClass ? classes.inputClass : ""}
        checked={value}
        name={name}
        onChange={null}
        readOnly
      />
      <span className={classes.labelClass ? classes.labelClass : ""}>
        {label ? t("fields." + label) : ""}
      </span>
      {error && <span className="error">{error}</span>}
    </div>
  );
}
RadioComponent.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  value: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
export default RadioComponent;
