export const SuperAdminSubscriptionTableHeader = [
  { key: "", displayValue: "title", sort: true, callbackFunction:'displayTitle' },
  {
    key: "",
    displayValue: "price",
    sort: true,
    callbackFunction: "displayPrice",
  },

  { key: "", displayValue: "credits", sort: true, callbackFunction:'displayCredits'  },
  {
    key: "",
    displayValue: "credits_expires",
    sort: true,
    callbackFunction: "displayCreditsExpired",
  },
  {
    key: "auto_charged",
    displayValue: "auto_charged",
    sort: true,
    callbackFunction: "displayAutoCharged",
  },
];
export const StudentSubscriptionTableHeader = [
  { key: "title", displayValue: "title", sort: true },
  {
    key: "duration",
    displayValue: "subscription_type",
    sort: true,
    callbackFunction: "displaySubscriptionType",
  },
  {
    key: "",
    functionKey: "total_amount",
    displayValue: "price",
    sort: true,
    callbackFunction: "displayPriceWithType",
  },
  { key: "coupon_code", displayValue: "coupon_code", sort: true },
  {
    key: "",
    functionKey: "discounted_amount",
    displayValue: "discounted_amount",
    sort: true,
    callbackFunction: "displayPriceWithType",
  },
  {
    key: "purchased_date",
    displayValue: "purchased_date",
    sort: true,
    callbackFunction: "populateDateTime",
  },
  {
    key: "end_date",
    displayValue: "end_date",
    sort: true,
    callbackFunction: "populateDateTime",
  },
  {
    key: "is_auto_renewal",
    displayValue: "is_auto_renewal",
    sort: true,
    callbackFunction: "displayAutoCharged",
  },
];
export const CancellationRequestTableHeader = [
  { key: "user", displayValue: "user_name", sort: true },
  {
    key: "email",
    displayValue: "email",
    sort: true,
  },
  {
    key: "subscription",
    displayValue: "subscription",
    sort: true,
  },
];
export const ModalButtons = {
  confirmAutoRenewalCancel: {
    text: "cancel_auto_renewal",
    buttons: [
      {
        text: "confirm",
        variant: "orange-1",
        onClick: "cancelAutoRenewal",
        loading: false,
        disabled: false,
      },
      {
        text: "cancel",
        variant: "white-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  confirmSubscriptionCancel: {
    text: "cancel_subscription",
    buttons: [
      {
        text: "confirm",
        variant: "blue-1",
        onClick: "cancelSubscription",
        loading: false,
        disabled: false,
      },
      {
        text: "cancel",
        variant: "white-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  subscriptionCancelled: {
    text: "subscription_cancelled_successfully",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  autoRenewalCancelled: {
    text: "auto_renew_request_sent",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  alreadyHaveActiveSubscription: {
    text: "already_have_active_subscription",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  autoRenewalCancelledError: {
    text: "",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  subscriptionCancelledError: {
    text: "",
    buttons: [
      {
        text: "ok",
        variant: "red-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
};
