import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function TextComponent({
  name,
  value,
  label,
  classes,
  placeholder,
  setValue,
  error,
  required,
  readOnly,
  type = "string",
  disabled,
  overLayText,
}) {
  const { t } = useTranslation();
  return (
    <div className={`fv-row ${classes ? classes : "mb-5"}`}>
      {label && (
        <label className={`form-label`}>
          <span className={`${required ? "required" : ""}`}>
            {t("fields." + label)}
          </span>
        </label>
      )}
      <input
        type={type}
        className={"form-control form-control-lg form-control-solid"}
        placeholder={t("fields." + placeholder)}
        value={value}
        name={name}
        readOnly={readOnly}

        disabled={disabled}
        onChange={(e) => setValue(name, e.target.value)}
      />
      {overLayText && (
        <span className="overlay-text">{t("fields." + overLayText)}</span>
      )}
      {error && <div className="text-danger mt-2">{error}</div>}
    </div>
  );
}
TextComponent.propTypes = {
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  type: PropTypes.string,
  overLayText: PropTypes.string,
  disabled: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
};
export default TextComponent;
