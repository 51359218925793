import { CallAPI } from "actions/General";
import ErrorComponent from "components/ErrorComponent";
import ListingHeaderComponent from "components/ListingHeaderComponent";
import LoaderComponent from "components/LoaderComponent";
import PaginationComponent from "components/PaginationComponent";
import TableComponent from "components/TableComponent";
import { DefaultPaginationSize, exportCSV } from "config/config";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ExportModuleHeaders,
  ModalButtons,
  ModulesGridHeader,
  ModulesTableHeader,
} from "./utils";
import GridView from "components/GridView";
import CustomModal from "components/CustomModal";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";
import ImportData from "views/ImportData";
import Filter from "views/filters/Filter";

function Modules() {
  const navigate = useNavigate();
  const userRole = useRecoilValue(getUserRole);

  const [allowImport, setAllowImport] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modules, setModules] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [customError, setCustomError] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(DefaultPaginationSize);
  const [displayType, setDisplayType] = useState("list");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVariant, setModalVariant] = useState("");
  const [modalType, setModalType] = useState("");
  const [deactivatedId, setDeactivatedId] = useState("");
  const [deactivatedType, setDeactivatedType] = useState("");

  const [optionValues, setOptionValues] = useState({
    tag: [],
  });

  const [filterValues, setFilterValues] = useState({});

  const getModulesList = async () => {
    setLoaderType("get_listing");
    let result = await CallAPI(
      "FETCH_MODULES_LIST",
      {
        relations: ["tags"],
        page,
        pageSize: limit,
        ...filterValues
      },
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setModules(result.data.modules);
      setTotalRecords(result.data.total);
    } else if (result.error === 403) {
      setIsModalOpen(true);
      setModalVariant("error");
      setModalType("listing403");
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        getModulesList();
      },
      filterValues.search ? 500 : 0
    );
    return () => clearTimeout(delayDebounceFn);
  }, [page, limit, filterValues]);

   const getOptionValues = async () => {  
      const tagsList = await CallAPI(
        "FETCH_ALL_TAGS",
        {},
        null,
        null,
        null,
        null
      );
      if (tagsList.status) {
        const allTags = tagsList.data.tags.map((singleTag) => {
          return {
            value: singleTag.id,
            displayValue: singleTag.title,
          };
        });
        setOptionValues((prevValues) => ({
          ...prevValues,
          tag: [{ value: "", displayValue: "Tags" }, ...allTags],
        }));
      }
    };

  const addModule = () => {
    navigate("/add_module");
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const deactivateModuleConfirmation = async (id, is_active) => {
    setDeactivatedId(id);
    setDeactivatedType(!is_active);
    setIsModalOpen(true);
    setModalVariant("confirm");
    setModalType(is_active ? "confirmDelete" : "confirmActive");
  };

  const deactivateModule = async () => {
    setLoaderType("delete");
    let result = await CallAPI(
      "DE_ACTIVATE_MODULE",
      { is_active: deactivatedType },
      deactivatedId,
      setLoader,
      setApiError,
      null
    );
    setLoaderType("");
    if (result.status) {
      getModulesList();
      setModalVariant("success");
      setModalType(!deactivatedType ? "recordDelete" : "recordActivated");
    } else {
      setModalVariant("error");
      setModalType("deletionError");
    }
  };
  const actionOnDeactivatedModule = () => {
    setIsModalOpen(true);
    setModalVariant("error");
    setModalType("deactivateActionError");
  };
  const importModules = () => {
    setAllowImport(true);
  };
  const updateValues = (name, value) => {
    setFilterValues({ ...filterValues, [name]: value });
  };

  
    useEffect(() => {
      getOptionValues();
    }, []);

  const exportModules = async () => {
    setLoaderType("export");
    setLoader(true);
    const result = await exportCSV(
      "modules",
      ExportModuleHeaders,
      "Modules_List.csv"
    );
    setLoader(false);
    if (result.status) {
      setIsModalOpen(true);
      setModalVariant("success");
      setModalType("exportSuccess");
    } else {
      setIsModalOpen(true);
      setModalVariant("error");
      setModalType("exportError");
      setCustomError(result.message);
    }
  };
  return (
    <>
      {loader && loaderType === "get_listing" && (
        <LoaderComponent classes={"mx-4"} />
      )}
      <div className="card mb-4">
        {apiError && <ErrorComponent text={apiError} variant="danger" />}
        <ListingHeaderComponent
          type={"modules_listing"}
          setDisplayType={setDisplayType}
          displayType={displayType}
          addItem={userRole === "BUSINESS_ADMIN" ? addModule : ""}
          importExportDetail={{
            allow: true,
            importFunc: importModules,
            exportFunc: exportModules,
            loaderType: loaderType,
            loader: loader,
          }}
        />
        <Filter
          type={"module"}
          values={filterValues}
          setValues={setFilterValues}
          updateValues={updateValues}
          props={optionValues}
        />

        <div className={"card-body py-4"}>
          {displayType === "list" && (
            <TableComponent
              data={modules}
              header={ModulesTableHeader}
              type="modules"
              props={{
                deactivateModuleConfirmation,
                actionOnDeactivatedModule,
              }}
            />
          )}
        </div>
      </div>
      {displayType === "grid" && (
        <GridView
          data={modules}
          columns={ModulesGridHeader}
          type="modules"
          props={{
            deactivateModuleConfirmation,
            actionOnDeactivatedModule,
          }}
        />
      )}
      <PaginationComponent
        totalPages={
          totalRecords ? Math.ceil(totalRecords / limit) : totalRecords
        }
        pageLimit={limit}
        setPageLimit={setLimit}
        setPage={setPage}
        currentPage={page}
      />
      {isModalOpen && (
        <CustomModal
          header={""}
          text={
            ModalButtons[modalType].text !== ""
              ? ModalButtons[modalType].text
              : customError
          }
          buttons={ModalButtons[modalType].buttons.map((button) => {
            return {
              text: button.text,
              variant: button.variant,
              onClick: button.onClick,
              loading:
                button["loading"] !== undefined ? button.loading : loader,
              disabled:
                button["loading"] !== undefined ? button.disabled : loader,
            };
          })}
          onClose={closeModal}
          variant={modalVariant}
          functionsList={{ closeModal, deactivateModule }}
        />
      )}
      {allowImport && (
        <ImportData setAllowImport={setAllowImport} type={"module"} />
      )}
    </>
  );
}

export default Modules;
