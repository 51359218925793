import React, { useEffect, useState } from "react";
import ErrorComponent from "components/ErrorComponent";
import GridView from "components/GridView";
import ListingHeaderComponent from "components/ListingHeaderComponent";
import LoaderComponent from "components/LoaderComponent";
import PaginationComponent from "components/PaginationComponent";
import { ScheduleGridHeader } from "./utils";
import { DefaultPaginationSize } from "config/config";
import { CallAPI } from "actions/General";
import { useLocation } from "react-router-dom";
import SelectComponentWithImage from "components/SelectComponentWithImage";
import AmericaImage from "assets/images/flags/america_flag.png";
import CanadaImage from "assets/images/flags/canada_flag.png";
import NigeriaImage from "assets/images/flags/nigerian_flag.png";

function EnrollmentSchedule() {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [schedules, setSchedules] = useState([]);
  const [pointsConversion, setPointsConversion] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(DefaultPaginationSize);
  const [title, setTitle] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("usd");

  const getAllSchedules = async (params) => {
    setLoaderType("get_listing");
    const bodyObj = {
      relations: ["season", "teacher", "course", "program"],
      page: page,
      pageSize: limit,
      ...(params.type === "course"
        ? { course_id: params.course_id }
        : params.type === "program"
        ? { program_id: params.program_id }
        : { event_id: params.event_id }),
      ...(params.programEnrollmentDetails
        ? { schedule_id: params.programEnrollmentDetails.schedule_id }
        : {}),
    };
    const result = await CallAPI(
      "FETCH_SCHEDULE_LIST",
      bodyObj,
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setSchedules(result.data.schedule);
      setPointsConversion(result.data.points_conversion);
      setTotalRecords(result.data.total);
    }
  };

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.version_id &&
      location.state.version_id !== ""
    ) {
      console.log(location.state);
      getAllSchedules(location.state);
      setTitle(location.state.title);
    }
  }, [location]);

  return (
    <>
      {loader && loaderType === "get_listing" && (
        <LoaderComponent classes={"mx-4"} />
      )}
      <div className="card">
        {apiError && <ErrorComponent text={apiError} variant="danger" />}
        <ListingHeaderComponent
          type={"course_schedule_listing"}
          title={title}
          setDisplayType={() => {}}
          displayType={""}
          addItem={""}
        />
        <hr />
      </div>
      <div className="bg-white d-flex justify-content-end pe-5">
        {location.state.payment_type.type === "pay" &&
          (location.state.payment_type.method === "BOTH" ||
            location.state.payment_type.method === "PAY_AS_STRIPE") && (
            <SelectComponentWithImage
              optionsList={[
                {
                  value: "usd",
                  displayValue: " | US Dollar (USD)",
                  img: AmericaImage,
                },
                {
                  value: "cad",
                  displayValue: " | Canadian Dollar (CAD)",
                  img: CanadaImage,
                },
                {
                  value: "ngn",
                  displayValue: " | Nigerian Naira (NGN)",
                  img: NigeriaImage,
                },
              ]}
              name="currency"
              label=""
              value={selectedCurrency}
              onChange={(name, value) => setSelectedCurrency(value)}
              required
              classes=""
            />
          )}
      </div>
      <div className="bg-white mb-4">
        <GridView
          data={schedules}
          columns={ScheduleGridHeader}
          options={{
            dataToSend: {
              enrollment_id: location.state.programEnrollmentDetails
                ? location.state.programEnrollmentDetails.enrollment_id
                : null,
              version_id: location.state.version_id,
              payment_type: location.state.payment_type.type,
              payment_method: location.state.payment_type.method,
              subscription_slug:
                location.state.payment_type.type === "subscription"
                  ? location.state.payment_type.slug
                  : "",
              currency: selectedCurrency,
              pointsConversion,
            },
            type: "schedule",
          }}
          classes="mt-0"
          type={"type-1 type-2"}
        />
      </div>
      <PaginationComponent
        totalPages={
          totalRecords ? Math.ceil(totalRecords / limit) : totalRecords
        }
        pageLimit={limit}
        setPageLimit={setLimit}
        setPage={setPage}
        currentPage={page}
      />
    </>
  );
}

export default EnrollmentSchedule;
